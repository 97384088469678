import { Dictionary } from "mummet-core/dist/types";

const empty = <V>(): Dictionary<V> => ({} as Dictionary<V>)

export const filterDictionary = <V>(dictionary: Dictionary<V>, filter: (entry: V) => boolean): Dictionary<V> => {
    return Object.keys(dictionary)
        .reduce((filtered, key) => {
            const entry: V = dictionary[+key];

            if (filter(entry))
                filtered[+key] = entry;

            return filtered;
        }, empty<V>());
}

export const mapDictionary = <TInput, TOutput>(dictionary: Dictionary<TInput>, map: (input: TInput) => TOutput): Dictionary<TOutput> => {
    return Object.keys(dictionary)
        .reduce((mapped, key) => {
            mapped[+key] = map(dictionary[+key]);
            return mapped;
        }, empty<TOutput>())
}

export const dictionaryToArray = <V>(dictionary: Dictionary<V>): V[] =>
    Object.keys(dictionary).map(key => dictionary[+key]);