import { formatDetailedDate, formatFriendlyDate } from "@src/lib/date-util"
import { Tracked } from "mummet-core/dist/types"
import * as React from "react"
import { connect } from "react-redux"
import { DateTimeString, toDateTime } from "../../../lib/date-string"
import { Target } from "../../../lib/targetable"
import {
    getAlarmTypeName,
    selectIsAcknowledgeReasonDirty,
    selectIsAcknowledgeUntilDirty
} from "../../../states/alarm/alarm-selectors"
import { actions } from "../../../states/alarm/alarm-slice"
import { closeAcknowledge, createAcknowledge } from "../../../states/alarm/alarm-thunks"
import { Acknowledge, AlarmType, DEFAULT_ACKNOWLEDGE_LENGTH_IN_HOURS } from "../../../states/alarm/alarm-types"
import { RootState } from "../../../states/state"
import { AppDispatch } from "../../../states/store"
import { selectCurrentUserName } from "../../../states/user"
import { selectUser } from "../../../states/user/user-selectors"
import { AcknowledgeableAlarmInternal, AcknowledgeProp } from "./acknowledgeable-alarm-internal"
import { AlarmColor } from "./alarm"

interface AlarmProp {
    type: AlarmType;
    target: Target;
    createdAt?: DateTimeString;
}

interface AcknowledgeDetails extends AcknowledgeProp {
    id: number
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
    const acknowledge = props.acknowledge;
    
    let ackDetails: AcknowledgeDetails | undefined;

    if (acknowledge?.current) {
        const untilTooltip = acknowledge.current.acknowledgedUntil
            ? formatFriendlyDate(acknowledge.current.acknowledgedUntil)
            : "Until resolved"

        ackDetails = {
            id: acknowledge.current.id,
            at: {
                tooltip: formatDetailedDate(acknowledge.current.acknowledgedAt),
                friendly: formatFriendlyDate(acknowledge.current.acknowledgedAt)
            },
            until: {
                dirty: selectIsAcknowledgeUntilDirty(acknowledge),
                iso: acknowledge.current.acknowledgedUntil,
                clearWhenResolved: acknowledge.current.clearWhenResolved,
                tooltip: untilTooltip,
            },
            by: selectUser(state.user, props.acknowledge?.current?.acknowledgedBy ?? -1).userName,
            comment: {
                dirty: selectIsAcknowledgeReasonDirty(acknowledge),
                text: acknowledge.current.reason
            }
        }
    }

    return {
        acknowledgeDetails: ackDetails,
        userName: selectCurrentUserName(state.user)
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    createAcknowledge: (target: Target, type: AlarmType, user: string, customerId: number) => {
        dispatch(createAcknowledge({
            alarm: { target, type },
            customerId
        }))
    },
    closeAcknowledge: (acknowledgeId: number) => {
        dispatch(closeAcknowledge({ id: acknowledgeId }))
    },
    setAcknowledgeComment: (text: string, acknowledgeId: number) => {
        dispatch(actions.setAcknowledgeComment({ comment: text, id: acknowledgeId }));
    },
    setAcknowledgeUntil: (date: DateTimeString, acknowledgeId: number) => {
        dispatch(actions.setAcknowledgeUntil({ date: date, id: acknowledgeId }));
    },
    setAcknowledgeClearWhenResolved: (clearWhenResolved: boolean, acknowledgeId: number) => {
        dispatch(actions.setAcknowledgeClearWhenResolved({ clearWhenResolved, id: acknowledgeId }));
    }
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnProps = {
    color: AlarmColor;
    customerId: number;
    alarm: AlarmProp;
    acknowledge?: Tracked<Acknowledge>;
    nowSupplier: () => DateTimeString;
}

type Props = StateProps & OwnProps & DispatchProps;

const DisconnectedAcknowledgeableAlarm = (props: Props) => {
    const onAcknowledgeToggle = () => {
        if (props.acknowledgeDetails)
            props.closeAcknowledge(props.acknowledgeDetails.id);
        else
            props.createAcknowledge(props.alarm.target, props.alarm.type, props.userName, props.customerId);
    }

    const onCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.acknowledgeDetails)
            props.setAcknowledgeComment(e.target.value, props.acknowledgeDetails.id);
    }

    const onUntilChange = (date: DateTimeString) => {
        if (props.acknowledgeDetails)
            props.setAcknowledgeUntil(date, props.acknowledgeDetails.id);
    }

    const onClearWhenResolvedChange = (clearWhenResolved: boolean) => {
        if (props.acknowledgeDetails)
            props.setAcknowledgeClearWhenResolved(clearWhenResolved, props.acknowledgeDetails.id);
    }

    const onRequestDefaultUntilValue = () => {
        if (!props.acknowledgeDetails)
            return;

        const date = new Date();
        date.setHours(date.getHours() + DEFAULT_ACKNOWLEDGE_LENGTH_IN_HOURS)
        onUntilChange(toDateTime(date));
    }

    const alarmCreatedAt = props.alarm.createdAt
        ? {
            tooltip: formatDetailedDate(props.alarm.createdAt),
            friendly: formatFriendlyDate(props.alarm.createdAt)
        }
        : null

    return (
        <AcknowledgeableAlarmInternal
            alarm={{ color: props.color, header: getAlarmTypeName(props.alarm.type), at: alarmCreatedAt }}
            acknowledge={props.acknowledgeDetails}

            onAcknowledgeToggle={onAcknowledgeToggle}

            onCommentChange={onCommentChange}
            onUntilChange={onUntilChange}
            onRequestDefaultUntilValue={onRequestDefaultUntilValue}
            onClearWhenResolvedChange={onClearWhenResolvedChange}

            nowSupplier={props.nowSupplier}
        />
    )
}

export const AcknowledgeableAlarm = connect(mapStateToProps, mapDispatchToProps)(DisconnectedAcknowledgeableAlarm);