import { createSelector } from "@reduxjs/toolkit";
import { Target } from "../../lib/targetable";
import { selectUnacknowledgedAlarmCountForTarget, selectAcknowledgedAlarmCountForTarget } from "../../states/alarm/alarm-selectors";
import { RootState } from "../../states/state";

export const makeMemoizedAlarmCount = (customerId: number, target: Target) =>
    createSelector(
        (state: RootState) => state.alarm.overview,
        (state: RootState) => state.alarm.alarms,
        (overview, alarms) => selectUnacknowledgedAlarmCountForTarget(overview, alarms, customerId, target)
    )

export const makeMemoizedAckedAlarmsCount = (customerId: number, target: Target) =>
    createSelector(
        (state: RootState) => state.alarm.overview,
        (state: RootState) => state.alarm.alarms,
        (overview, alarms) => selectAcknowledgedAlarmCountForTarget(overview, alarms, customerId, target)
    )
