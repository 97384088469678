import { LoadableContentDeviceButton } from "@features/device-details/components/buttons/loadable-content-device-button"
import { PLAYLIST_BUTTON_TEXT } from "@features/device-details/components/buttons/shared-device-buttons"
import { showPlaylistPreviewTutorialOrPlay } from "@features/preview-playlist-tutorial/playlist-preview-tutorial-slice"
import { selectLoadedProjector } from "@states/projector/projector-selectors"
import { fetchProjectorPlaylist } from "@states/projector/projector-thunks"
import { Projector } from "@states/projector/projector-types"
import { getActivePlaylist } from "@states/projector/projector-util"
import store from "@states/store"
import { selectCurrentUserName } from "@states/user"
import * as React from "react"

interface Props {
    projectorMac: string,
    customerId: number,
    enabled?: boolean
}

const onClick = (projector: Projector, customerId: number) => {
    const playlist = getActivePlaylist(projector)

    if (playlist) {
        const username = selectCurrentUserName(store.getState().user)
        showPlaylistPreviewTutorialOrPlay(username, playlist.guid)
        return
    }

    store.dispatch(fetchProjectorPlaylist({
        customerId,
        projectorSerialNo: projector.serialNumber
    }))
}

const ProjectorPlaylistButton = (props: Props) =>
    <LoadableContentDeviceButton
        loadedIcon={"film"}
        enabled={props.enabled}

        onSelectDevice={(state) => selectLoadedProjector(state, props.projectorMac)}
        onSelectContentOrStatus={(projector: Projector) => {
            const playlist = projector.playlist

            return playlist === 'loading'
                ? 'loading'
                : getActivePlaylist(projector)
        }}

        onClick={(projector) => onClick(projector, props.customerId)}>
        {PLAYLIST_BUTTON_TEXT}
    </LoadableContentDeviceButton>

export default ProjectorPlaylistButton