import { Role, User, UserState } from "./user-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { selectCurrentUser, selectCurrentUserRoles } from "./user-selectors";
import { fetchUserNames } from "./user-thunks";
import { emptyUser } from "./user-util";

const INITIAL_STATE: UserState = {
    current: -1,
    all: {},
    debug: false
}

const slice = createSlice({
    name: 'user',
    initialState: INITIAL_STATE,
    reducers: {
        setCurrentUser: (state, action: PayloadAction<User>) => {
            const user = action.payload
            state.all[user.id] = user
            state.current = user.id
        },

        addRoleToCurrentUser: (state, action: PayloadAction<Role>) => {
            var role = action.payload;
            var roles = selectCurrentUserRoles(state)

            if (!roles.includes(role)) {
                roles.push(role);
            }

            selectCurrentUser(state).roles = roles
        },

        removeRoleFromCurrentUser: (state, action: PayloadAction<Role>) => {
            var role = action.payload;
            var roles = selectCurrentUserRoles(state);

            var index = roles.indexOf(role);

            if (index > -1) {
                roles.splice(index, 1);
            }

            selectCurrentUser(state).roles = roles
        },

        toggleDebug: (state) => {
            state.debug = !state.debug;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUserNames.fulfilled, (state, action) => {
                const ids: number[] = action.meta.arg
                const names: string[] = action.payload

                if (ids.length !== names.length)
                    throw Error("Received unexpected data")

                for (let i = 0; i < ids.length; i++) {
                    const user = getOrCreateUser(state, ids[i])
                    user.userName = names[i]
                }
            })
    }
})

export const actions = slice.actions;
export const reducer = slice.reducer;

const getOrCreateUser = (state: UserState, id: number): User => {
    if (id in state.all)
        return state.all[id]

    const user = { ...emptyUser(), id }
    state.all[id] = user
    return user
}