import { alertGenericErrorAndReloadPage } from "@src/error-handling"
import SunFlowMain from "./sunflow-main";
import MainPage from "./main-page";
import SetupEditProjectors from "./setup-edit-projectors";

var Setup = {
    loadedCustomerId: -1,
    loadedHasDirector: null as boolean | null,
    checkedProjectors: <string[]>[],
    clickingOnProjector: false,
    requestViewChange: function (newCustomerId: number, newHasDirector: boolean) {
        MainPage.onViewChanging();
        Setup.loadedCustomerId = newCustomerId;
        Setup.loadedHasDirector = newHasDirector;
        if (SetupEditProjectors.loadedProjectorIds.length && SetupEditProjectors.loadedProjectorIds.length > 0) {
            SetupEditProjectors.clear();
        }

        var $target = $("#main-page-view");
        MainPage.fadeInOverlay();

        return $.ajax({
            url: "/Setup/Index",
            type: "POST",
            data: JSON.stringify({ 'customerId': newCustomerId, 'hasDirector': newHasDirector }),
            contentType: "application/json; charset=utf-8",
            success: function (result) {
                $target.html(result);
                MainPage.checkIfLoggedInAfterAjax($target);
                Setup.bindEvents();
            },
            error: function () {
                alert("An error has occured in setup.requestViewChange()");
            },
            complete: function () {
                MainPage.fadeOutOverlay();
            }
        });

    },
    bindEvents: function () {
        if ($('#setup-register-director-dialog').length) {
            Setup.bindNoDirectorEvents();
        }
        else {
            Setup.bindProjectorListEvents();
        }
    },
    bindNoDirectorEvents: function () {
        $('#setup-director-register-button').on('click', function (e) {
            if (e.which === 1) {
                Setup.tryRegisterDirector();
            }
        });
        $('#setup-director-id').on('keyup', function (e) {
            if (e.keyCode === 13) {
                Setup.tryRegisterDirector();
            }
        });
    },
    bindProjectorListEvents: function () {
        $('.setup-row').on('mousedown', function (e) {
            if (e.which === 1) {
                var $tab = $(this);
                if ($tab.hasClass('setup-row-selected')) {
                    return;
                }
                else {
                    Setup.clickingOnProjector = true;
                    $tab.removeClass('setup-row-normal');
                    $tab.addClass('setup-row-clicking');
                }
            }
        });
        $('.setup-row').on('mouseup', function (e) {
            if (Setup.clickingOnProjector) {
                Setup.clickingOnProjector = false;

                Setup.clearSelected();
                var $tab = $(this);
                $tab.addClass('setup-row-selected');
                $tab.removeClass('setup-row-clicking');

                //var projectorToSelect = $tab.attr('data-projector-id');

                Setup.updateUIAfterSelectionChanged();
            }
        });
        $('.setup-row').on('mouseleave', function (e) {
            if (Setup.clickingOnProjector) {
                Setup.clickingOnProjector = false;
                var $tab = $(this);
                $tab.addClass('setup-row-normal');
                $tab.removeClass('setup-row-clicking');
            }
        });
        $('.setup-row').on('dblclick', function (e) {
            var $row = $(this);
            var $checkBox = $row.find("input[type='checkbox']");
            $checkBox.prop('checked', !$checkBox.prop('checked'));
            $checkBox.change();

        });

        $(".setup-row input[type='checkbox']").on('change', function (e) {
            Setup.fillCheckedProjectors();
            Setup.updateUIAfterSelectionChanged();

        });
    },
    unbindProjectorListEvents: function () {
        $('.setup-row').unbind('mousedown');
        $('.setup-row').unbind('mouseup');
        $('.setup-row').unbind('mouseleave');
        $('.setup-row').unbind('dblclick');
        $(".setup-row input[type='checkbox']").unbind('change');
    },
    unbindNoDirectorEvents: function () {
        $('#setup-director-register-button').unbind('click');
        $('#setup-director-id').unbind('keypress');
    },
    unbindEvents: function () {
        if ($('#setup-register-director-dialog').length) {
            Setup.unbindNoDirectorEvents();
        }
        else {
            Setup.unbindProjectorListEvents();
            SetupEditProjectors.unbindEvents();
        }
    },
    fillCheckedProjectors: function () {
        Setup.checkedProjectors = [];
        var $checkedProjectors = $(".setup-row input[type='checkbox']:checked");
        if ($checkedProjectors.length && $checkedProjectors.length > 0) {
            $checkedProjectors.each(function (index) {
                var $rowElem = $($checkedProjectors[index]).closest('.setup-row');
                Setup.checkedProjectors.push($rowElem.attr('data-projector-id') as string);
            });
        }
    },
    updateUIAfterSelectionChanged: function () {
        //var $editProjector = $('#setup-edit-projector');
        //var hasSelection = false;
        if (Setup.checkedProjectors.length && Setup.checkedProjectors.length > 0) {
            //hasSelection = true;
            SetupEditProjectors.loadProjectors(Setup.checkedProjectors);
        }
        else {
            var $selectedRow = $('.setup-row-selected');
            if ($selectedRow.length) {
                //hasSelection = true;
                var projectorsToSelect = [];
                projectorsToSelect.push($selectedRow.attr('data-projector-id')!);
                SetupEditProjectors.loadProjectors(projectorsToSelect);
            }
        }
    },
    reloadProjectorList: function () {
        Setup.unbindProjectorListEvents();
        var $target = $("#setup-projector-view");

        MainPage.fadeInOverlay();
        return $.ajax({
            url: "/Setup/ProjectorList",
            type: "POST",
            data: JSON.stringify({ 'customerId': Setup.loadedCustomerId }),
            contentType: "application/json; charset=utf-8",
            success: function (result) {
                $target.html(result);
                MainPage.checkIfLoggedInAfterAjax($target);
                Setup.bindProjectorListEvents();
            },
            error: function () {
                alert("An error has occured in setup.reloadProjectorList()");
            },
            complete: function () {
                MainPage.fadeOutOverlay();
            }
        });
    },
    clearSelected: function () {
        var $oldTab = $('.setup-row-selected');
        if ($oldTab.length) {
            $oldTab.removeClass('setup-row-selected');
            $oldTab.addClass('setup-row-normal');
        }
    },
    clearChecked: function () {
        $(".setup-row input[type='checkbox']:checked").prop('checked', false);
        Setup.checkedProjectors = [];
    },
    clear: function () {
        Setup.loadedCustomerId = -1;
        Setup.loadedHasDirector = null;
        Setup.checkedProjectors = [];
        SetupEditProjectors.clear();
    },
    tryRegisterDirector: function () {
        var $inputElem = $('#setup-director-id');
        var directorId = $inputElem.val() as string;
        if (directorId.length > 0 && directorId.charAt(0) === '#') {
            directorId = directorId.substring(1);
            $inputElem.val(directorId);
        }

        if (SunFlowMain.isNullOrWhitespace(directorId) || (directorId.length !== SunFlowMain.DIRECTORIDLENGTH)) {
            $('#setup-input-validation').text('Please enter 8 characters.');
            $inputElem.effect('highlight');
            $inputElem.focus();
        }
        else {
            $.ajax({
                url: "/Setup/TryRegisterDirector",
                data: { 'customerId': Setup.loadedCustomerId, 'directorId': directorId },
                type: "POST",
                async: false,
                success: function (result) {
                    if (result === 'True') {
                        const customerIdToReload = Setup.loadedCustomerId;
                        Setup.unbindEvents();
                        Setup.requestViewChange(customerIdToReload, true);
                    }
                    else if (result === 'False') {
                        $('#setup-input-validation').text('Director ID is not available.');
                        $inputElem.effect('highlight');
                        $inputElem.focus();
                    }
                    else {
                        console.error("An error has occured in setup.tryRegisterDirector()")
                        alertGenericErrorAndReloadPage()
                    }
                },
                error: function (error) {
                    console.error("An error has occured in setup.tryRegisterDirector()")
                    alertGenericErrorAndReloadPage()
                }
            });
        }
    }
};

export default Setup;