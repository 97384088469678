import { LoadableContentDeviceButton } from "@features/device-details/components/buttons/loadable-content-device-button"
import { SCREENSHOT_BUTTON_SINGULAR_TEXT } from "@features/device-details/components/buttons/shared-device-buttons"
import deviceDetails from "@features/device-details/device-details-legacy"
import { selectLoadedProjector } from "@states/projector/projector-selectors"
import { Projector } from "@states/projector/projector-types"
import { getActiveScreenshot } from "@states/projector/projector-util"
import * as React from "react"

interface Props {
    projectorMac: string,
    customerId: number,
    enabled?: boolean
}

const onClick = (projector: Projector, customerId: number) => {
    deviceDetails.getScreenshotForProjector(
        projector.serialNumber,
        customerId,
        deviceDetails.SCREENSHOT_POPUP,
        deviceDetails.MAX_SCREENSHOT_WIDTH)
}

const ProjectorScreenshotButton = (props: Props) => {
    return <LoadableContentDeviceButton
        loadedIcon={"image"}
        enabled={props.enabled}

        onSelectDevice={(state) => selectLoadedProjector(state, props.projectorMac)}
        onSelectContentOrStatus={(projector: Projector) => {
            const screenshot = projector.screenshot

            return screenshot === 'loading'
                ? 'loading'
                : getActiveScreenshot(projector)
        }}

        onClick={(projector) => onClick(projector, props.customerId)}>
        {SCREENSHOT_BUTTON_SINGULAR_TEXT}
    </LoadableContentDeviceButton>
}

export default ProjectorScreenshotButton