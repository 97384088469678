import * as React from "react"
import { Tracked } from "mummet-core/dist/types"
import { Acknowledge, Alarm } from "../../../states/alarm/alarm-types"
import { AcknowledgeableAlarm } from "./acknowledgeable-alarm"
import { DateTimeString } from "../../../lib/date-string"

type OwnProps = {
    customerId: number;
    alarm: Alarm;
    acknowledge?: Tracked<Acknowledge>;
    nowSupplier: () => DateTimeString;
}

export const ActiveAlarm = (props: OwnProps) => (
    <AcknowledgeableAlarm
        customerId={props.customerId}
        color='alert'
        alarm={{ type: props.alarm.type, createdAt: props.alarm.createdAt, target: props.alarm.target }}
        acknowledge={props.acknowledge}
        nowSupplier={props.nowSupplier}
    />)