import { DirectorButtons } from "@features/device-details/components/buttons/director-buttons"
import { DisabledButtons } from "@features/device-details/components/buttons/disabled-buttons"
import { ProjectorButtons } from "@features/device-details/components/buttons/projector-buttons"
import { hasPermissionForRepairCenter } from "@src/lib/role-util"
import { Director } from "@states/director/director-types"
import { isDirector } from "@states/director/director-util"
import { Projector } from "@states/projector"
import { selectHasRole } from "@states/user/user-selectors"
import { Role } from "@states/user/user-types"
import * as React from "react"

interface Props {
    device: Director | Projector[] | null,
    customerId: number,
    userRoles: Role[]
}

export const DeviceButtonList = (props: Props) => {
    const device = props.device
    const displayFlowPlanner = selectHasRole(props.userRoles, 'FlowPlanner')
    const displayRepairCenter = hasPermissionForRepairCenter(props.userRoles)

    const createButtons = () => {
        if (device === null || (Array.isArray(device) && device.length === 0)) {
            return <DisabledButtons
                displayFlowPlannerButton={displayFlowPlanner}
                displayRepairCenterButtons={displayRepairCenter}
            />
        }

        if (isDirector(device)) {
            return <DirectorButtons
                director={device}
                customerId={props.customerId}
                displayFlowPlannerButton={displayFlowPlanner}
                displayRepairCenterButtons={displayRepairCenter}
            />
        }

        return <ProjectorButtons
            projectors={device}
            customerId={props.customerId}
            displayFlowPlannerButton={displayFlowPlanner}
            displayRepairCenterButtons={displayRepairCenter}
        />
    }

    return <div className="device-button-container">
        {createButtons()}
    </div>
}