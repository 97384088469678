import { DeviceButton } from "@features/device-details/components/buttons/device-button"
import deviceDetails from "@features/device-details/device-details-legacy"
import DirectorOverview from "@features/director-overview/director-overview"
import FlowPlanner from "@src/lib/flowplanner"
import { setDirectorBroken } from "@states/director/director-thunks"
import { Director } from "@states/director/director-types"
import { isDirector } from "@states/director/director-util"
import { Projector } from "@states/projector"
import { setProjectorBroken } from "@states/projector/projector-thunks"
import store from "@states/store"
import * as React from "react"
import { isPresent } from "ts-is-present"

export const SCREENSHOT_BUTTON_SINGULAR_TEXT = "Collect screendump"
export const SCREENSHOT_BUTTON_PLURAL_TEXT = "Collect screendumps"
export const PLAYLIST_BUTTON_TEXT = "Preview playlist"

export const FlowPlannerButton = (props: {enabled: false} | {customerId: number, playerId: string, enabled?: boolean}) =>
    <DeviceButton
        enabled={props?.enabled}
        onClick={() => {
            if (props.enabled !== false)
                FlowPlanner.openPlayer(props.customerId, props.playerId)
        }}>
        Schedule content
    </DeviceButton>

export const MarkBrokenButton = (props: {enabled: false} | {customerId: number, devices: Director | Projector[], enabled?: boolean}) => {

    const isBroken = props.enabled !== false && areAllBroken(props.devices)

    return <DeviceButton
        enabled={props.enabled}
        onClick={() => {
            if (props.enabled === false)
                return

            if (!isBroken) {
                deviceDetails.displayMarkSelectedDevicesBrokenDialog(props.customerId, props.devices)
                return
            }

            if (isDirector(props.devices)) {
                store.dispatch(setDirectorBroken({
                    director: props.devices,
                    broken: false
                }))
            } else {
                for (const projector of props.devices) {
                    store.dispatch(setProjectorBroken({
                        projector,
                        broken: false
                    }))
                }
            }
        }}>
        {isBroken ? "Mark not broken" : "Mark broken"}
    </DeviceButton>
}

export const CommandsButton = (props?: {enabled?: boolean}) =>
    <DeviceButton
        enabled={props?.enabled}
        spacer={true}
        onClick={() => DirectorOverview.loadRepairCentre(deviceDetails.getSelectedDevices(), 'commands')}>
        Commands
    </DeviceButton>

export const RestartButton = (props?: {enabled?: boolean}) =>
    <DeviceButton
        enabled={props?.enabled}
        onClick={() => deviceDetails.displayRestartSelectedDevicesDialog()}>
        Restart
    </DeviceButton>

export const FactoryResetButton = (props?: {enabled?: boolean}) =>
    <DeviceButton
        enabled={props?.enabled}
        onClick={() => deviceDetails.displayFactoryResetSelectedDevicesDialog()}>
        Factory reset
    </DeviceButton>

export const LogsButton = (props?: {enabled?: boolean}) =>
    <DeviceButton
        enabled={props?.enabled}
        spacer={true}
        onClick={() => DirectorOverview.loadRepairCentre(deviceDetails.getSelectedDevices(), 'logs')}>
        Logs
    </DeviceButton>

export const DeleteButton = (props?: {enabled?: boolean}) =>
    <DeviceButton
        enabled={props?.enabled}
        onClick={() => deviceDetails.displayDeleteSelectedDirectorDialog()}>
        Delete
    </DeviceButton>

const areAllBroken = (devices: Director | Projector[]): boolean => {
    if (isDirector(devices))
        return isPresent(devices.broken.current)

    return devices.every(d => d.broken.current)
}