import { selectCustomerId } from "@features/customer-tree/customer-tree-selectors"
import { createSelector, unwrapResult } from "@reduxjs/toolkit"
import { memoizedActiveDevices, selectIsFetchingAnyDevice } from "@states/device-overview/device-overview-selectors"
import { isFetchingDirector } from "@states/director/director-selectors"
import { fetchDirectorForCustomer } from "@states/director/director-thunks"
import { actions as messageActions } from "@states/message/message-slice"
import { isFetchingProjector, selectLoadedProjector } from "@states/projector/projector-selectors"
import { fetchProjector } from "@states/projector/projector-thunks"
import { RootState } from "@states/state"
import store from "@states/store"

export function initializeDeviceFetching() {
    store.subscribe(() => {
        const state = store.getState()
        updateLoadingOverlay(state)
        fetchDevices(state)
    })
}

const updateLoadingOverlay = createSelector(
    [selectIsFetchingAnyDevice],
    (isFetching) => {
        document
            .getElementById("details-box")
            ?.classList
            ?.toggle("loading", isFetching)
    }
)

const fetchDevices = createSelector(
    [memoizedActiveDevices, selectCustomerId],
    (devices, customerId) => {
        if (devices === null || customerId < 0)
            return

        const state = store.getState()

        if (devices === 'director') {
            internalFetchDirector(state, customerId)
            return
        }

        // Only fetch unloaded projectors when doing multi-select
        if (devices.length > 1) {
            devices = devices.filter(device =>
                selectLoadedProjector(state, device.projectorMac) === null
            )
        }

        for (const device of devices) {
            const projectorMac = device.projectorMac
            internalFetchProjector(state, projectorMac)
        }
    }
)

const internalFetchDirector = (state: RootState, customerId: number) => {
    if (isFetchingDirector(state, customerId))
        return

    store
        .dispatch(fetchDirectorForCustomer({customerId}))
        .then(unwrapResult)
        .catch(_ => {
            store.dispatch(messageActions.showError({
                header: "Something went wrong.",
                content: `Could not load details for the director.`
            }))
        })
}

const internalFetchProjector = (state: RootState, projectorMac: string) => {
    if (isFetchingProjector(state, projectorMac))
        return

    store
        .dispatch(fetchProjector({projectorMac}))
        .then(unwrapResult)
        .catch(_ => {
            store.dispatch(messageActions.showError({
                header: "Something went wrong.",
                content: `Could not load details for the projector: ${projectorMac}`
            }))
        })
}