import { DeviceButton } from "@features/device-details/components/buttons/device-button"
import { actions as tutorialActions } from "@features/preview-playlist-tutorial/playlist-preview-tutorial-slice"
import FA from "@src/components/fa"
import { Modal } from "@src/components/modal"
import { useAppSelector } from "@states/hooks"
import { playPlaylistPreview } from "@states/projector/projector-util"
import store from "@states/store"
import * as React from "react"
import { useState } from "react"

export const PlaylistPreviewTutorial = () => {
    const show = useAppSelector(state => state.previewPlaylistTutorial.show)
    const playlistGuid = useAppSelector(state => state.previewPlaylistTutorial.guid)

    const [doNotShowAgain, setDoNotShowAgain] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const play = () => {
        // Fake "loading" indicator for the play button to give the user feedback that something is happening
        setIsLoading(true)
        setTimeout(() => setIsLoading(false), 1000)

        playPlaylistPreview(playlistGuid)
    }

    const close = () => {
        store.dispatch(tutorialActions.hide({hidePermanently: doNotShowAgain}))
    }

    const playButtonIcon = isLoading
        ? <FA icon="spinner" spin/>
        : <FA icon="film"/>

    return <Modal show={show} onClose={close} className="modal--message">
        <h3>Preview Playlist</h3>
        <p>This function will show you a preview of the playlist that is playing on the projector.</p>

        <ul>
            <li>Ensure that Shoppa 3.5 or later is installed</li>
            <li>Ensure that Shoppa has been launched at least once</li>
            <li>Once these requirements are met, press Play</li>
        </ul>

        <p>
            For questions or troubleshooting, please contact Shoppa support:
            <br/>
            <a href="mailto:support@shoppa.com">support@shoppa.com</a>
        </p>

        <div className="playlist-preview-tutorial__navbar">
            <label title="Preview the playlist without first opening this dialog">
                <input type="checkbox"
                       checked={doNotShowAgain}
                       onChange={(e) => setDoNotShowAgain(e.target.checked)}/>
                Don't show this dialog again
            </label>

            <DeviceButton icon={playButtonIcon}
                          enabled={!isLoading}
                          onClick={play}>
                Play
            </DeviceButton>
        </div>
    </Modal>
}