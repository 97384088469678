import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@states/state";
import { AppDispatch } from "@states/store";
import { Tracked } from "mummet-core/dist/types";
import xhr from "../../lib/xhr";
import { actions } from "./brighttime-slice";
import { actions as chainActions } from "@states/customer-chain/customer-chain-slice";
import { selectBrightTimes, selectDirtyBrightTimes } from "./brighttime-selectors";
import { BrightTime, ServerBrightTime } from "./brighttime-types";
import { findRemoved } from "mummet-core";
import { isPresent } from "ts-is-present";
import { fetchCustomerIdsForChain } from "../customer/customer-thunks";

interface ThunkArgs { dispatch: AppDispatch, state: RootState, rejectValue: Tracked<BrightTime>[] };

//types: Returned payload + argument to payload creator
export const handleSave = createAsyncThunk<ServerBrightTime[], void, ThunkArgs>(
    "brighttime/handleSave",
    async (_, thunkApi) => {
        const state = thunkApi.getState();
        const brightTimes = selectBrightTimes(state)
        const toAddUpdate = selectDirtyBrightTimes(brightTimes)
        const toDelete = findRemoved(brightTimes) as BrightTime[]

        const data = buildXhrDataFrom(toAddUpdate, toDelete);
        if (!data.length)
            return [];

        const ids = toAddUpdate.map(x => x.current!.id)
        thunkApi.dispatch(actions.commit(ids)) //by committing early and rolling back on exception, the UI will appear to save very fast

        try {
            const response = await xhr.put<ServerBrightTime[]>('/Settings/BrightScenarios', data)
            console.log('xhr returned', response.data)

            response.data
                .map((bt, index) => ({ prev: data[index].id, next: bt.id }))
                .filter(x => x.prev !== x.next)
                .forEach(payload =>
                    thunkApi.dispatch(actions.setId(payload))
            )
            const chainId = thunkApi.getState().customerTree.selectedChainId
            const chain = thunkApi.getState().customerChain[chainId];
            if (chain !== undefined) {
                thunkApi.dispatch(chainActions.requested({ id: chain.id }));
                thunkApi.dispatch(fetchCustomerIdsForChain())
            }

            return response.data
        }
        catch (e) {
            console.warn('Failed to save brighttimes', e);
            return thunkApi.rejectWithValue(toAddUpdate)
        }
    })

function buildXhrDataFrom(toAddUpdate: Tracked<BrightTime>[], toDelete: BrightTime[]): ServerBrightTime[] {
    const addUpdateData = toAddUpdate
        .map(t => t.current)
        .filter(isPresent)
        .map(item => ({
            id: item.id,
            customerId: item.customerId,
            name: item.name,
            description: item.description
        } as ServerBrightTime));

    const deleteData = toDelete.map(item => ({
        id: item.id,
        customerId: item.customerId,
        depricated: true
    } as ServerBrightTime));

    return [
        ...addUpdateData,
        ...deleteData
    ];
}
