import { createAsyncThunk } from "@reduxjs/toolkit"
import { DictionaryNum } from "@reduxjs/toolkit/src/entities/models"
import { actions as message } from "@states/message/message-slice"
import { Tracked } from "mummet-core/dist/types"
import xhr from "../../lib/xhr"
import { ThunkArgs } from "../state-types"
import { selectCurrentUser } from "../user/user-selectors"
import {
    selectAcknowledgeDefaultUntil,
    selectAcknowledges,
    selectDirtyAcknowledges,
    selectIsAcknowledgeDraft,
    selectNextDraftId
} from "./alarm-selectors"
import { actions } from "./alarm-slice"
import {
    Acknowledge,
    Alarm,
    AlarmOverview,
    InactiveAlarm,
    ServerAcknowledge,
    ServerAlarm,
    ServerAlarmOverview
} from "./alarm-types"
import { mapServerAcknowledge, mapServerAlarm, mapServerOverview } from "./alarm-util"

const ACKNOWLEDGE_NOT_CREATED_HEADER = "Acknowledge could not be created."
const ACKNOWLEDGE_NOT_CLOSED_HEADER = "Acknowledge could not be closed."
const ACKNOWLEDGE_NOT_UPDATED_HEADER = "Acknowledge could not be updated."
const ACKNOWLEDGES_NOT_UPDATED_HEADER = "Acknowledges could not be updated."
const DEFAULT_ERROR_CONTENT = "Something went wrong."

export const fetchAlarms = createAsyncThunk<
    Alarm[],
    { customerId: number, clear: 'all' | 'customer' }>
    ("alarms/get",
        async (args) => {
            const { data: models } = await xhr.get<ServerAlarm[]>(`/alarms/customer/${args.customerId}`)

            return models.map(mapServerAlarm)
        })

export const fetchAcknowledges = createAsyncThunk<
    Acknowledge[],
    { customerId: number, clear: 'all' | 'customer' }>
    ("acknowledges/get",
        async (args) => {
            const { data: models } = await xhr.get<ServerAcknowledge[]>(`/acknowledges/customer/${args.customerId}`)

            return models.map(mapServerAcknowledge)
        })

export const fetchAlarmOverview = createAsyncThunk<AlarmOverview, { chainId: number }>
    ("alarms/overview/get",
        async (args) => {
            const { data: overview } = await xhr.get<ServerAlarmOverview>(`/alarms/chain/${args.chainId}/overview`)
            return mapServerOverview(overview)
        })

/**
 * Creates a draft acknowledge on this client and starts a post to the server.
 * 
 * If the server confirms the acknowledge by returning a copy of the acknowledge with
 * its update acknowledged-at and id,
 * fulfilled is called which updates the local acknowledge.
 * 
 * If the put fails,
 * rejected is called which removes the acknowledge from the client.
 */
export const createAcknowledge = createAsyncThunk<
    { result: Acknowledge, draftId: number },
    { alarm: InactiveAlarm, customerId: number },
    { rejectValue: { draft: Acknowledge } } & ThunkArgs>
    ("acknowledges/create",
        async (args, thunk) => {
            const draftId = selectNextDraftId(thunk.getState().alarm)
            thunk.dispatch(actions.incrementDraftId())

            const draft: Acknowledge = {
                ...args.alarm,

                id: draftId,
                customerId: args.customerId,

                acknowledgedAt: null,
                acknowledgedBy: selectCurrentUser(thunk.getState().user).id,
                acknowledgedUntil: selectAcknowledgeDefaultUntil(),
                clearWhenResolved: true,

                reason: ""
            }

            thunk.dispatch(actions.setAcknowledge(draft))
            thunk.dispatch(actions.updateOverviewFromLocalAcknowledges(draft.customerId))

            try {
                const { data: results } = await xhr.put<ServerAcknowledgeUpdate[]>(`/acknowledges`, [draft])

                if (results.length !== 1)
                    throw new Error("Did not receive expected data from server when creating acknowledge.")

                if (results[0].acknowledge !== null)
                    return { result: mapServerAcknowledge(results[0].acknowledge), draftId }

            } catch (e) {
                console.warn("Failed to create acknowledge.", e, draft)
            }

            thunk.dispatch(message.showError({
                header: ACKNOWLEDGE_NOT_CREATED_HEADER,
                content: DEFAULT_ERROR_CONTENT
            }))

            return thunk.rejectWithValue({ draft })
        })

export const handleSaveAcknowledges = createAsyncThunk<void, void, ThunkArgs>
    ("acknowledges/handleSave",
        async (args, thunk) => {
            const dirty = selectDirtyAcknowledges(selectAcknowledges(thunk.getState()))
                .filter(ack => ack.current?.id !== undefined)
                .map(ack => ack.current!.id)

            if (dirty.length === 0)
                return

            await thunk.dispatch(updateAcknowledges({ ids: dirty }))
        })

/**
 * Sends an update of a non-draft acknowledge to the server.
 * 
 * The underlying state is immediately set to match the current state
 * which makes the update seem instant.
 * 
 * If the update fails,
 * rejected is called which rollbacks to the previous underlying state.
 */
export const updateAcknowledges = createAsyncThunk<
    AcknowledgeUpdate[],
    { ids: number[] },
    { rejectValue: { previousUnderlyings: Acknowledge[] } } & ThunkArgs>
    ("acknowledges/update",
        async (args, thunk) => {
            const allAcks = thunk.getState().alarm.acknowledges
            const acksToUpdate: Tracked<Acknowledge>[] = []
            const customerIds = new Set<number>()

            args.ids.forEach(id => {
                const ack = allAcks[id] as Tracked<Acknowledge> | undefined

                if (!ack?.current) {
                    console.warn("Cant update acknowledge because it doesn't exist on the client", id)
                    return
                }

                if (selectIsAcknowledgeDraft(ack)) {
                    console.warn("Cant update acknowledge because it doesn't exist on the server", id)
                    return
                }

                acksToUpdate.push(ack)
                customerIds.add(ack.current.customerId)
                thunk.dispatch(actions.setAcknowledgeUnderlying(ack.current))
            })

            customerIds.forEach(customerId => thunk.dispatch(actions.updateOverviewFromLocalAcknowledges(customerId)))

            try {
                const currents = acksToUpdate.map(ack => ack.current)
                const { data: results } = await xhr.put<ServerAcknowledgeUpdate[]>(`acknowledges`, currents)

                if (results.length !== acksToUpdate.length)
                    throw new Error("Did not receive expected data from server when updating acknowledges.")

                return acksToUpdate.map((ackToUpdate, index) => {
                    const result = results[index]

                    if (result.acknowledge !== null)
                        return ({ acknowledge: mapServerAcknowledge(result.acknowledge)})

                    thunk.dispatch(message.showError({
                        header: ACKNOWLEDGE_NOT_UPDATED_HEADER,
                        content: result.error
                    }))

                    return ({
                        error: result.error,
                        previousUnderlying: ackToUpdate.underlying!
                    })
                })
            } catch (e) {
                console.warn("Failed to update acknowledges.", e, acksToUpdate)

                thunk.dispatch(message.showError({
                    header: ACKNOWLEDGES_NOT_UPDATED_HEADER,
                    content: DEFAULT_ERROR_CONTENT
                }))

                return thunk.rejectWithValue({previousUnderlyings: [...acksToUpdate.map(ack => ack.underlying!)]})
            }
        })

/*
 * Closes an acknowledge on the server.
 * 
 * The underlying state is immediately removed locally which makes the update seem instant.
 * 
 * If the closure fails,
 * rejected is called which restores the acknowledge to its previous state.
 */
export const closeAcknowledge = createAsyncThunk<
    void,
    { id: number },
    { rejectValue: Tracked<Acknowledge> } & ThunkArgs>
    ("acknowledges/close",
        async (args, thunk) => {
            const allAcks = thunk.getState().alarm.acknowledges
            let acknowledge = allAcks[args.id] as Tracked<Acknowledge> | undefined 

            if (!acknowledge?.current) {
                console.warn("Can't close acknowledge because it doesn't exist on the client", args.id)
                return
            }

            if (selectIsAcknowledgeDraft(acknowledge)) {
                console.warn("Can't close acknowledge because it's a draft", acknowledge)
                return
            }

            const id = acknowledge.current.id
            const customerId = acknowledge.current.customerId

            thunk.dispatch(actions.removeAcknowledge(id))
            thunk.dispatch(actions.updateOverviewFromLocalAcknowledges(customerId))

            try {
                await xhr.delete<boolean[]>(`/acknowledges/${id}/?customer=${customerId}`)
                return
            } catch (e) {
                console.warn("Failed to close acknowledge", e, acknowledge)

                thunk.dispatch(message.showError({
                    header: ACKNOWLEDGE_NOT_CLOSED_HEADER,
                    content: DEFAULT_ERROR_CONTENT
                }))

                return thunk.rejectWithValue(acknowledge)
            }
        })

export const fetchCountersForCustomers = createAsyncThunk<
    DictionaryNum<{alarms: number, acks: number}>,
    number[]>
    ("alarms/counters", async (customerIds) => {
        if (customerIds.length === 0)
            return []

        const {data: counters} = await xhr.post<DictionaryNum<{alarms: number, acks: number}>>('/alarms/counters/', customerIds)
        return counters
    })

type ServerAcknowledgeUpdate =
    { acknowledge: ServerAcknowledge, error: null } |
    { acknowledge: null, error: string }

type AcknowledgeUpdateSuccess = { acknowledge: Acknowledge }
type AcknowledgeUpdateFailed = { previousUnderlying: Acknowledge, error: string }
export type AcknowledgeUpdate = AcknowledgeUpdateSuccess | AcknowledgeUpdateFailed

export const isAcknowledgeUpdateSuccess = (update: AcknowledgeUpdate): update is AcknowledgeUpdateSuccess =>
    update.hasOwnProperty("acknowledge")

export const isAcknowledgeUpdateFailure = (update: AcknowledgeUpdate): update is AcknowledgeUpdateFailed =>
    update.hasOwnProperty("error")