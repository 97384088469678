import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { actions as customerChainActions } from "@states/customer-chain/customer-chain-slice"
import { commit, remove, rollback, setUnderlying, track, updateProperty } from "mummet-core"
import { Dictionary, Tracked } from "mummet-core/dist/types"
import { buildShineDefinitions } from "./brighttime-controller"
import { handleSave } from "./brighttime-thunks"
import { BrightTime } from "./brighttime-types"

const INITIAL_STATE: Dictionary<Tracked<BrightTime>> = {}

const slice = createSlice({
    name: 'brighttimes',
    initialState: INITIAL_STATE,
    reducers: {
        setName: (state, action: PayloadAction<{ id: number, name: string }>) => {
            const { id, name } = action.payload
            return updateProperty(state, id, "name", name)
        },
        setDescription: (state, action: PayloadAction<{ id: number, description: string }>) => {
            const { id, description } = action.payload
            const definitions = buildShineDefinitions(description)

            state = updateProperty(state, id, "description", description)
            state = updateProperty(state, id, "definitions", definitions)
            return state
        },
        add: (state, action: PayloadAction<{id: number, customerId: number}>) => {
            const { id, customerId } = action.payload
            return {
                ...state,
                [id]: {
                    current: { id, customerId, changed: "", description: "1|", definitions: [], guid: "", name: "Empty" },
                    underlying: null,
                    loaded: null
                }
            }
        },
        remove: (state, action: PayloadAction<number>) => {
            return remove(state, action.payload)
        },
        commit: (state, action: PayloadAction<number[]>) => {
            return commit(state, action.payload); //rollback on error is handled by "handleSave.rejected" below
        },
        setId: (state, action: PayloadAction<{ prev: number, next: number }>) => {
            const { prev, next } = action.payload

            const prevItem: Tracked<BrightTime> = state[prev];
            const nextItem: Tracked<BrightTime> = {
                ...prevItem,
                current: { ...prevItem.current!, id: next }
            };

            state = { ...state };
            delete state[prev]
            return { ...state, [next]: nextItem }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(customerChainActions.loaded, (state, action) => {
                const { id: chainId, brightTimes: serverBrightTimes } = action.payload;

                const brightTimes = serverBrightTimes.map(bt => ({
                    ...bt,
                    definitions: buildShineDefinitions(bt.description)
                }))

                for (const bt of brightTimes) {
                    state[bt.id] = track(bt)
                }

                return state;
            })
            .addCase(handleSave.fulfilled, (state, action) => {
                const deleted = action.payload.filter(bt => bt.depricated)
                const updated = action.payload.filter(bt => !bt.depricated)

                if (deleted.length) {
                    state = { ...state }
                    for (const t of deleted) {
                        delete state[t.id]
                    }
                }

                const underlying = updated.map(bt => ({ ...bt, definitions: buildShineDefinitions(bt.description) }))
                state = setUnderlying(state as Dictionary<Tracked<BrightTime>>, underlying, "id")

                return state
            })
            .addCase(handleSave.rejected, (state, action) => {
                for (const t of action.payload!) {
                    state = rollback(state, t.underlying!.id, t.underlying);
                }
                return state;
            })
    }
})

export const actions = slice.actions;
export const reducer = slice.reducer;
