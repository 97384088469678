import MainPage from "./main-page";
import DevicesRepairCentre from "./devices-repair-centre";

var Broken =
{
    loadedCustomerId: -1,
    hasDirector: false,
    clickingOnRow: false,
    requestViewChange: function (newCustomerId: number, prevSelection: string[], newHasDirector: boolean, forceRefresh: boolean) {
        if (newCustomerId != Broken.loadedCustomerId || forceRefresh) {


            MainPage.onViewChanging(); // does this take care of unbinds???
            Broken.loadedCustomerId = newCustomerId;
            Broken.hasDirector = newHasDirector;

            var $target = $("#main-page-view");
            MainPage.fadeInOverlay();

            // need to be able to take a selection simillar as directorOverview!
            return $.ajax({
                url: "/Main/BrokenDevicesView",
                type: "POST",
                data: JSON.stringify({ 'customerId': newCustomerId, 'selectedDevices': prevSelection }),
                dataType: "html",
                contentType: "application/json; charset=utf-8",
                success: function (result) {
                    $target.html(result);
                    MainPage.checkIfLoggedInAfterAjax($target);
                    Broken.bindEvents();
                },
                error: function () {
                    alert("An error has occured in broken.requestViewChange()");
                },
                complete: function () {
                    MainPage.fadeOutOverlay();
                }
            });
        }
        return undefined;
    },
    refreshView: function () {
        Broken.requestViewChange(Broken.loadedCustomerId, Broken.getSelectedDevices()!, Broken.hasDirector, true);
    },
    bindEvents: function () {
        $('.broken-row').on('mousedown', function (e) {
            if (e.which == 1) {
                var $tab = $(this);
                if ($tab.hasClass('projector-row-selected')) {
                    return;
                }
                else {
                    Broken.clickingOnRow = true;
                    $tab.removeClass('projector-row-normal');
                    $tab.addClass('projector-row-clicking');
                }
            }
        });
        $('.broken-row').on('mouseup', function (e) {
            if (Broken.clickingOnRow) {
                Broken.clickingOnRow = false;
                Broken.clearSelected();
                var $tab = $(this);
                $tab.addClass('projector-row-selected');
                $tab.removeClass('projector-row-clicking');
            }

        });
        $('.broken-row').on('mouseleave', function (e) {
            if (Broken.clickingOnRow) {
                Broken.clickingOnRow = false;
                var $tab = $(this);
                $tab.addClass('projector-row-normal');
                $tab.removeClass('projector-row-clicking');
            }
        });
        $('.broken-row').on('dblclick', function (e) {
            var $row = $(this);
            var $checkBox = $row.find("input[type='checkbox']");
            $checkBox.prop('checked', !$checkBox.prop('checked'));
            $checkBox.change();

        });
        $('#commands-button').on('click', function (e) {
            Broken.loadRepairCentre(Broken.getSelectedDevices(), 'commands');
        });
        $('#logs-button').on('click', function (e) {
            Broken.loadRepairCentre(Broken.getSelectedDevices(), 'logs');
        });
    },
    getSelectedDevices: function () {
        var selectedDevices = [];
        var $checkedDevices = $(".broken-row input[type='checkbox']:checked");
        if ($checkedDevices.length && $checkedDevices.length > 0) {
            $checkedDevices.each(function (index) {
                var $rowElem = $($checkedDevices[index]).closest('.broken-row');
                selectedDevices.push($rowElem.attr('data-projector-id')!);
            });
        }
        if (selectedDevices.length == 0) {
            var $curSelectedRow = $('.projector-row-selected');
            if ($curSelectedRow.length) {
                selectedDevices.push($curSelectedRow.attr('data-projector-id')!);
            }
        }
        return selectedDevices;
    },
    loadRepairCentre: function (selectedDevices: string[], targetView: string) {
        if (Broken.hasDirector && selectedDevices.length > 0) {
            DevicesRepairCentre.requestViewChange(Broken.loadedCustomerId, selectedDevices, targetView, 'broken');
        }
    },
    unbindEvents: function () {
        $('.broken-row').unbind('mouseup');
        $('.broken-row').unbind('mouseup');
        $('.broken-row').unbind('mouseleave');
        $('.broken-row').unbind('dblclick');
    },
    clearSelected: function () {
        var $oldTab = $('.projector-row-selected');
        if ($oldTab.length) {
            $oldTab.removeClass('projector-row-selected');
            $oldTab.addClass('projector-row-normal');
        }
    },
    clear: function () {
        Broken.loadedCustomerId = -1;
        //Broken.loadedHasDirector = null;//MAAS: Expected to be hasDirector?
        Broken.clickingOnRow = false;
    }
};

export default Broken;