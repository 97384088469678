import { combineReducers } from 'redux'

import user from '@states/user'
import projector from '@states/projector'
import infoMessage from "@states/info-message"
import { reducer as deviceOverview } from '@states/device-overview/device-overview-slice'
import { reducer as director } from '@states/director/director-slice'
import { reducer as alarm } from "@states/alarm/alarm-slice"
import { reducer as message } from "@states/message/message-slice"

import { reducer as customerChain } from './customer-chain/customer-chain-slice'
import { reducer as customer } from './customer/customer-slice'
import { reducer as customerTree } from '@features/customer-tree/customer-tree-slice'
import { reducer as channel } from './channel/channel-slice'
import { reducer as brightTime } from './brighttime/brighttime-slice'
import { reducer as deviceEvent } from './device-event/device-event-slice'
import { reducer as sharedSaveButton } from "@features/shared-save-button"
import { reducer as previewPlaylistTutorial } from "@features/preview-playlist-tutorial/playlist-preview-tutorial-slice"

export const rootReducer = combineReducers({
    customerTree,
    customerChain,
    customer,
    channel,
    brightTime,
    deviceEvent,
    sharedSaveButton,

    user,
    projector,
    director,
    deviceOverview,
    infoMessage,
    alarm,
    message,
    previewPlaylistTutorial
})

export type RootState = ReturnType<typeof rootReducer>
