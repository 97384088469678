import { RootState } from "../../states/state";
import { SelectedTabValue } from "../customer-tree/customer-tree-types";
import { TabState } from "../../components/tab";

export const selectTabState = (state: RootState, name: SelectedTabValue, isDisabled: boolean = false, isHidden: boolean = false) => {
    if (state.customerTree.selectedPageTab === name)
        return TabState.Selected;

    if (isHidden)
        return TabState.Hidden;

    if (isDisabled)
        return TabState.Disabled;

    return TabState.Enabled;
}
