import { configureStore } from "@reduxjs/toolkit"
//import { startIntervals } from "./intervals";
import { rootReducer as reducer } from "./state"

const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({immutableCheck: true})
});
//startIntervals();

export default store;
export type AppDispatch = typeof store.dispatch;
