import * as React from "react"
import { Dispatch } from "redux";
import { connect } from "react-redux"
import { RootState } from "../../states/state"
import { validateShineDefinitions } from "../../states/brighttime/brighttime-controller";
import { actions } from "../../states/brighttime/brighttime-slice"
import { ValidatingTextbox } from "../../components/validated-textbox";

interface OwnProps {
    id: number
}

const mapState = (state: RootState, props: OwnProps) => ({
    brighttime: state.brightTime[props.id]
})

const mapDispatch = (dispatch: Dispatch, props: OwnProps) => ({
    onName: (e: React.ChangeEvent) => dispatch(actions.setName({ id: props.id, name: (e.target as HTMLInputElement).value })),
    onDescription: (description: string) => dispatch(actions.setDescription({ id: props.id, description })),
    onDelete: () => dispatch(actions.remove(props.id))
})

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & OwnProps

const BrighttimeEditorInternal = (props: Props) => {
    if (!props.brighttime?.current)
        return null;

    const current = props.brighttime.current
    const underlying = props.brighttime.underlying
    const nameClassName = current.name !== underlying?.name ? "tracked-dirty": ""
    const descriptionClassName = current.description !== underlying?.description ? "tracked-dirty" : ""

    return (
        <div className="editor">
            <label>Name</label>
            <input type="text"
                className={nameClassName}
                value={current.name}
                onChange={props.onName}
            />

            <label>Definition</label>
            <ValidatingTextbox
                className={descriptionClassName}
                value={current.description}
                onChange={props.onDescription}
                validationCallback={validateShineDefinitions}
                validationFailedClassName="validate--failed"
            />

            <button className="alert radius" onClick={props.onDelete}>Delete</button>
        </div>
    )
}

export const BrighttimeEditor = connect(mapState, mapDispatch)(BrighttimeEditorInternal)
