import { createSlice, Dictionary, PayloadAction } from "@reduxjs/toolkit"
import { filterDictionary } from "@src/lib/dictionary-util"
import { ContainerStatus } from "@src/lib/types/container-status"
import { fetchCountersForCustomers } from "@states/alarm/alarm-thunks"
import { actions as customerChainActions } from "@states/customer-chain/customer-chain-slice"
import { checkChainsHaveDirectors, loadChains } from "@states/customer-chain/customer-chain-thunks"
import { fetchMissingCustomerIds } from "./customer-thunks"
import { Customer, ServerCustomer } from "./customer-types"

const createRequestedCustomer = (customerId: number): Customer => ({
    customerId,
    directorId: null,
    parentId: null,
    chainId: -1,
    name: '',
    children: [],
    infoMessages: [],
    status: ContainerStatus.Requested
})

const INITIAL_STATE: Dictionary<Customer> = {}

const slice = createSlice({
    name: 'customers',
    initialState: INITIAL_STATE,
    reducers: {
        requested: (state, action: PayloadAction<number[]>) => {
            action.payload.forEach(customerId => {
                if (!(customerId in state)) {
                    state[customerId] = createRequestedCustomer(customerId);
                }
            });
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadChains.fulfilled, (state, action) => {
                for (const serverChainInfo of action.payload) {
                    if (!(serverChainInfo.customerId in state)) {
                        state[serverChainInfo.customerId] = {
                            ...serverChainInfo,
                            chainId: serverChainInfo.customerId,
                            children: [],
                            infoMessages: [],
                            status: ContainerStatus.Partial
                        }
                    }
                }
            })

            .addCase(fetchCountersForCustomers.fulfilled, (state, action) => {
                const customersWithCounters = action.payload

                Object.keys(customersWithCounters).forEach(key => {
                    const customerId = +key

                    if (customerId in state)
                        state[customerId]!.counters = customersWithCounters[customerId]!
                })
            })

            .addCase(customerChainActions.loaded, (state, action) => {
                const { id: chainId, customers } = action.payload;
                for (const customer of customers) {
                    const current = state[customer.customerId]

                    state[customer.customerId] = {
                        counters: current?.counters,
                        containsDirectors: current?.containsDirectors,

                        ...customer,
                        chainId,
                        status: ContainerStatus.Loaded
                    };
                }
            })

            .addCase(checkChainsHaveDirectors.fulfilled, (state, action) => {
                const chainWithDirectors = filterDictionary(action.payload, (hasDirector) => hasDirector === true)

                for (const chainId in chainWithDirectors) {
                    const chainCustomer = state[chainId]

                    if (chainCustomer)
                        chainCustomer.containsDirectors = true
                }
            })

            .addCase(fetchMissingCustomerIds.pending, (state, action) => {
                const missingIds = action.meta.arg.filter(id => !(id in state))
                //console.log('Adding missing customers as requested', missingIds)
                missingIds.forEach(id => state[id] = createRequestedCustomer(id))
            })

            .addCase(fetchMissingCustomerIds.fulfilled, (state, action) => {
                const serverCustomers = action.payload;

                const missingCustomers = serverCustomers
                    .filter(sc => !(sc.customerId in state) || state[sc.customerId]!.status !== ContainerStatus.Loaded)

                for (const serverCustomer of missingCustomers) {
                    const current = state[serverCustomer.customerId]

                    state[serverCustomer.customerId] = {
                        counters: current?.counters,
                        ...serverCustomer,
                        chainId: findChain(serverCustomer, serverCustomers),
                        status: ContainerStatus.Partial
                    }
                }
            })
    }
})

export const actions = slice.actions;
export const reducer = slice.reducer;

function findChain(customer: ServerCustomer, array: ServerCustomer[]): number {
    let walker: ServerCustomer = customer

    while (walker /*protect for customer not in array*/ && walker.parentId && walker.customerId !== customer.customerId /*protect for circular dependencies*/) {
        walker = array.find(c => c.customerId === walker?.parentId) ?? walker
    }

    return walker.customerId
}