import { ChannelFilterEnum, CustomerFilterEnum } from "@states/customer/customer-types";

export const COOKIE_CHAIN_ID_KEY = "SUNFLOW_CHAIN_ID";
export const COOKIE_CUSTOMER_ID_KEY = "SUNFLOW_CUSTOMER_ID";

export type SelectedTabValue =
    "ChainOverview" |
    "StoreOverview" |
    "Overview" |
    "Channels" |
    "Setup" |
    "Broken" |
    "Integrator" |
    "Settings" |
    "Dashboard";

export interface CustomerTreeState {
    selectedCustomerId: number;
    selectedChainId: number;
    selectedPageTab: SelectedTabValue;
    filter: CustomerFilterEnum;
    filterChannels: ChannelFilterEnum;
    scrollToCustomerWhenSelected: boolean;
    showChainDialog: boolean;
}
