import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@states/state";
import { AppDispatch } from "@states/store";
import { Tracked } from "mummet-core/dist/types";
import { isPresent } from "ts-is-present";
import xhr from "../../lib/xhr";
import { selectChannels, selectDirtyChannels } from "./channel-selectors";
import { actions } from "./channel-slice";
import { Channel } from "./channel-types";

interface ThunkArgs { dispatch: AppDispatch, state: RootState, rejectValue: Tracked<Channel>[] };

//types: Returned payload + argument to payload creator
export const handleSave = createAsyncThunk<number[], void, ThunkArgs>(
    "channel/handleSave",
    async (_, thunkApi) => {
        const state = thunkApi.getState();
        const channels = selectDirtyChannels(selectChannels(state));
        const channelsToAdd = channels.filter(t => t.underlying === null)
        const channelsToDelete = channels.filter(t => t.current === null)
        const channelsToUpdate = channels.filter(t => t.current !== null && t.underlying !== null)

        if (channelsToAdd.length > 0 || channelsToDelete.length > 0)
            throw new Error("Add or delete channels has not been implemented!");

        if (!channelsToUpdate.length)
            return [];

        const data = channelsToUpdate
            .map(t => t.current)
            .filter(isPresent)
            .map(ch => ({
                id: ch.id,
                brightTimeId: ch.brightTimeId,
                local: ch.local,
                locked: ch.locked
            }));


        const ids = data.map(x => x.id);
        thunkApi.dispatch(actions.commit(ids)) //by committing early and rolling back on exception, the UI will appear to save very fast

        try {
            await xhr.put('/Channel', data)
            return ids;
        }
        catch (e) {
            console.warn('Failed to save channels', e);
            return thunkApi.rejectWithValue(channelsToUpdate)
        }
    })
