import * as React from "react";

interface OwnProps {
    acknowledged: boolean;
    onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

export const AcknowledgeButton = (props: OwnProps) => {
    const buttonText = props.acknowledged
        ? "Clear"
        : "Acknowledge";

    return <input type="button" value={buttonText} onClick={props.onClick} />;
}