import { createAsyncThunk } from "@reduxjs/toolkit"
import xhr from "@src/lib/xhr"
import { Director } from "@states/director/director-types"
import { directorFromServerModel } from "@states/director/director-util"
import { actions as messageActions } from "@states/message/message-slice"
import { ThunkArgs } from "@states/state-types"

const showMarkBrokenFailedError = (director: string) =>
    messageActions.showError({
        header: "Something went wrong.",
        content: `Could not update broken status for the director: ${director}`
    })

export const fetchDirectorForCustomer = createAsyncThunk<Director | null, {customerId: number}, ThunkArgs & {rejectValue: {customerId: number}}>
('directors/get',
    async (input, thunkApi) => {
        const customerId = input.customerId

        try {
            const {data: model, status} = await xhr.get(`/director/${customerId}/details`,
                {validateStatus: status => [200, 404].includes(status)}
            )

            if (status === 404) {
                console.log("Customer doesn't have director:", customerId)
                return null
            }

            const director = directorFromServerModel(model, customerId)

            if (director === null) {
                console.error("Server returned an invalid director for customer:", customerId, director)
                return thunkApi.rejectWithValue({customerId})
            }

            return director
        } catch (e) {
            console.error("Failed to fetch director for customer:", customerId, e)
            return thunkApi.rejectWithValue({customerId})
        }
    })

export const setDirectorBroken = createAsyncThunk<
    void,
    {director: Director} & ({broken: true, replacementMac: string, reason: string} | {broken: false}),
    ThunkArgs
    >('director/setBroken',
    async (input, thunkApi) => {
        const {director} = input

        try {
            if (input.broken) {
                await xhr.put(`/director/${director.id}/broken?replacementMac=${input.replacementMac}&reason=${input.reason}`)
            } else {
                await xhr.delete(`/director/${director.id}/broken`)
            }

            return
        } catch (e) {
            console.error("Failed to update projector broken status", input, e)
            thunkApi.dispatch(showMarkBrokenFailedError(director.id))
            return thunkApi.rejectWithValue(e)
        }
    })