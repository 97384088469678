import * as React from "react"
import { useState } from "react";
import { connect } from "react-redux"
import { RootState } from "@states/state";
import { Menu, MenuItem } from "../../components/menu";
import { Dispatch } from "redux";
import { actions } from "../../states/brighttime/brighttime-slice"
import { BrighttimeEditor } from "./brighttime-editor";
import FA from "../../components/fa";
import { isPresent } from "ts-is-present";
import { createSelector } from "@reduxjs/toolkit";
import { selectCustomers, selectAllChildren, selectChainId, selectCustomerAsCustomerId } from "../../features/customer-tree/customer-tree-selectors";

type OwnProps = {
    chainId: number
}

const makeMapStateToProps = () => {
    const memoizedCustomersInChain = createSelector([selectCustomers, selectChainId], selectAllChildren)
    const memoizedCustomerInChainAsId = createSelector([memoizedCustomersInChain], selectCustomerAsCustomerId);
    return (state: RootState) => {
        return {
            items: Object.keys(state.brightTime).map(id => state.brightTime[+id]),
            customersInChain: memoizedCustomerInChainAsId(state)
        }
    }
}

const mapDispatch = (dispatch: Dispatch, props: OwnProps) => ({
    onChange: (id: number, description: string) => dispatch(actions.setDescription({ id, description })),
    onAdd: (id: number) => dispatch(actions.add({ id, customerId: props.chainId })),
    chainId: props.chainId
})

type Props = ReturnType<ReturnType<typeof makeMapStateToProps>> & ReturnType<typeof mapDispatch>

const BrightTimesSettingsInternal = (props: Props) => {

    const [selectedId, setSelected] = useState(+Object.keys(props.items)[0])
    const menuItems = props.items
        .filter(t => t.current?.customerId !== undefined && props.customersInChain.includes(t.current?.customerId))
        .map(t => t.current
            ? { id: t.current.id, name: t.current.name, disabled: false }
            : { id: t.underlying!.id, name: t.underlying!.name, disabled: true }
        )

    const selectedMenuItem = menuItems.find(item => item.id === selectedId)
    const selectedIdForEditor = selectedMenuItem === undefined ? 0 : selectedMenuItem.id;
    const onClick = (e: MenuItem) => setSelected(e.id as number)
    const onAdd = () => {
        const newId = props.items
            .map(t => t.current)
            .filter(isPresent)
            .map(item => item.id)
            .reduce((prev, next) => prev < next ? prev : next, 0) - 1
        props.onAdd(newId)
        setSelected(newId)
    }

    return (
        <div className="container">
            <section className="container__left">
                <h2>Brighttimes</h2>
                <Menu items={menuItems} selected={selectedMenuItem!} onClick={onClick} />
                <button className="success radius" onClick={onAdd}><FA className="" icon="plus-circle" />Add</button>
            </section>
            <section className="container__right">
                <BrighttimeEditor id={selectedIdForEditor} />
            </section>
        </div>
    )
}

export const BrightTimesSettings = connect(makeMapStateToProps, mapDispatch)(BrightTimesSettingsInternal)
