import { DeviceButton } from "@features/device-details/components/buttons/device-button"
import {
    CommandsButton,
    DeleteButton,
    FactoryResetButton,
    FlowPlannerButton,
    LogsButton,
    MarkBrokenButton,
    PLAYLIST_BUTTON_TEXT,
    RestartButton,
    SCREENSHOT_BUTTON_PLURAL_TEXT
} from "@features/device-details/components/buttons/shared-device-buttons"
import * as React from "react"

interface Props {
    displayFlowPlannerButton: boolean,
    displayRepairCenterButtons: boolean
}

export const DisabledButtons = (props: Props) => <>
    <DeviceButton enabled={false}>{SCREENSHOT_BUTTON_PLURAL_TEXT}</DeviceButton>
    <DeviceButton enabled={false}>{PLAYLIST_BUTTON_TEXT}</DeviceButton>

    {
        props.displayFlowPlannerButton &&
            <FlowPlannerButton enabled={false}/>
    }

    {
        props.displayRepairCenterButtons && <>
            <CommandsButton enabled={false}/>
            <RestartButton enabled={false}/>
            <FactoryResetButton enabled={false}/>

            <LogsButton enabled={false}/>
            <MarkBrokenButton enabled={false}/>
            <DeleteButton enabled={false}/>
        </>
    }
</>