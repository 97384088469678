import { CustomerTreeState, SelectedTabValue } from "./customer-tree-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChannelFilterEnum, CustomerFilterEnum } from "@states/customer/customer-types";

const INITIAL_STATE: CustomerTreeState = {
    selectedCustomerId: -1,
    selectedChainId: -1,
    selectedPageTab: "StoreOverview",
    filter: CustomerFilterEnum.WithScreens,
    filterChannels: ChannelFilterEnum.SelectedCustomer,
    scrollToCustomerWhenSelected: true,
    showChainDialog: false
}

const slice = createSlice({
    name: 'customer-tree',
    initialState: INITIAL_STATE,
    reducers: {
        setCustomerId: (state, action: PayloadAction<number>) => {
            return { ...state, selectedCustomerId: action.payload };
        },
        setChain: (state, action: PayloadAction<number>) => {
            state.selectedChainId = action.payload;
            state.selectedCustomerId = action.payload;
            state.showChainDialog = false;
        },
        setFilter: (state, action: PayloadAction<CustomerFilterEnum>) => {
            state.filter = action.payload;
        },
        setChannelFilter: (state, action: PayloadAction<ChannelFilterEnum>) => {
            state.filterChannels = action.payload;
        },
        setScrollToCustomerWhenSelected: (state, action: PayloadAction<boolean>) => {
            state.scrollToCustomerWhenSelected = action.payload;
        },
        selectPageTab: (state, action: PayloadAction<SelectedTabValue>) => {
            state.selectedPageTab = action.payload;
        },
        showChainDialog: (state, action: PayloadAction<boolean>) => {
            state.showChainDialog = action.payload;
        }
    }
})

export const actions = slice.actions;
export const reducer = slice.reducer;
