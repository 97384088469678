import * as React from "react";

export type AlarmColor = 'success' | 'alert' | 'inactive';

type OwnProps = {
    color: AlarmColor;

    header: string;
    subheader?: {
        text: string;
        tooltip?: string;
    },

    headerRight?: React.ReactNode;
    children?: React.ReactNode;
}

export const Alarm = (props: OwnProps) => {
    let alarmClass = "alarm " + props.color;
    if (props.children) alarmClass += " acknowledged";

    const detailedHeader = props.subheader ? " detailed" : "";
    const detailedContent = props.children ? " detailed" : "";

    return <div className={alarmClass}>
        <div className={"alarm__header" + detailedHeader}>
            <div className="alarm__header__left">
                <p className="alarm__header__title">{props.header}</p>
                {props.subheader && <p title={props.subheader.tooltip}>{props.subheader.text}</p>}
            </div>
            <div className="alarm__header__right">
                {props.headerRight}
            </div>
        </div>
        {props.children && <>
            <div className="alarm__divider"/>
            <div className={"alarm__content" + detailedContent}>
                {props.children}
            </div>
        </>}
    </div>
}