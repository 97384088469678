// We shouldn't use browser alert popups when problems occur.
// But in cases that we still do this anyways,
// use these functions to provide a consistent experience and
// to make it easy to find where we still use this workflow and to customize the message.

const DEFAULT_MESSAGE = "Something went wrong."
const CONTACT_SUPPORT = "Contact support if you continue experiencing the problem."

export function alertError(message: string) {
    alert(`⚠️ ${message}\n` +
        "Try refreshing the page.\n\n" +
        CONTACT_SUPPORT)
}

export function alertGenericError() {
    alertError(DEFAULT_MESSAGE)
}

export function alertErrorAndReloadPage(message: string) {
    alert(`⚠️ ${message}\n` +
        "The page will be refreshed and you can try again.\n\n" +
        CONTACT_SUPPORT)
    window.location.reload()
}

export function alertGenericErrorAndReloadPage() {
    alertErrorAndReloadPage(DEFAULT_MESSAGE)
}