import * as ReactDOM from "react-dom";
import * as React from "react";
import { Provider } from "react-redux";
import { Store } from "@reduxjs/toolkit";
import { RootState } from "../../states/state";
import StoreFilter from "../../features/customer-tree/components/store-filter"
import Chain from "../../features/customer-tree/components/chain"
import { ChainDialog } from "../chain-tree";

export default (store: Store<RootState>, container: HTMLElement) => {
    ReactDOM.render(
        <Provider store={store}>
            <h2>Locations</h2>
            <div className="tree-inner">
                <StoreFilter />
                <Chain />
            </div>
            <ChainDialog />
        </Provider>,
        container
    );
}
