import { Tracked } from "mummet-core/dist/types"
import { toDateTime } from "../../lib/date-string"
import { Acknowledge, AcknowledgeDictionary, Alarm, AlarmOverview, ServerAcknowledge, ServerAlarm, ServerAlarmOverview } from "./alarm-types"

export const mapServerAlarm = (model: ServerAlarm): Alarm => ({
    id: model.id,
    customerId: model.customerId,
    type: model.type,
    target: {
        id: model.targetId,
        type: model.targetType
    },
    createdAt: toDateTime(model.createdAt),
    resolvedAt: !!model.resolvedAt ? toDateTime(model.resolvedAt) : null,
    triggeredAt: null
})

export const mapServerAcknowledge = (model: ServerAcknowledge): Acknowledge => ({
    id: model.id,
    customerId: model.customerId,
    type: model.alarmType,
    target: {
        id: model.targetId,
        type: model.targetType
    },
    acknowledgedAt: toDateTime(model.acknowledgedAt),
    acknowledgedUntil: toDateTime(model.acknowledgedUntil),
    acknowledgedBy: model.acknowledgedBy,
    clearWhenResolved: model.clearWhenResolved,
    reason: model.reason
})

export const mapServerOverview = (model: ServerAlarmOverview): AlarmOverview => {
    const overview = {} as AlarmOverview

    model.customers.forEach(customer => {
        overview[customer.id] = {}

        customer.entries.forEach(entry => {
            overview[customer.id][entry.alarmId] = entry.acknowledgeId ?? null
        })
    })

    return overview
}

export const changeAcknowledgeId = (acknowledges: AcknowledgeDictionary, oldId: number, newId: number): Tracked<Acknowledge> => {
    const acknowledge = acknowledges[oldId] as Tracked<Acknowledge> | undefined

    if (!acknowledge)
        throw new Error(`Can't change id of acknowledge ${oldId} to ${newId} because it doesn't exist.`)

    delete acknowledges[oldId]

    return acknowledges[newId] = {
        ...acknowledge,
        current: acknowledge.current === null
            ? null
            : {
                ...acknowledge.current,
                id: newId
            },
        underlying: acknowledge.underlying === null
            ? null
            : {
                ...acknowledge.underlying,
                id: newId
            }
    }
}