import "jquery";
import "jqueryui";
import { LabeledValue } from "./interfaces";

declare global {
    interface JQuery {
        catAutocomplete(): JQuery;
        catAutocomplete(options: CatAutocompleteOptions): JQuery;
        catAutocomplete(methodName: string, param1?: any, param2?: any, param3?: any, param4?: any): JQuery;
    }
}

export interface CatAutocompleteOptions {
    source: LabeledValue[],
    select: (event: any, ui: any) => void
}

$.widget("custom.catAutocomplete", $.ui.autocomplete, {
    _create: function (this: any) {
        this._super();
        this.widget().menu("option", "items", "> :not(.ui-autocomplete-category)");
    },

    _renderMenu: function (this: any, ul: JQuery, items: LabeledValue[]) {
        var that = this,
            currentCategory = "";
        var i = 0;
        $.each(items, function (index, item) {
            if (i >= 21) {
                return;
            }
            var li;
            if (item.category !== currentCategory) {
                ul.append("<li class='ui-autocomplete-category'>" + item.category + ":</li>");
                currentCategory = item.category;
            }
            li = that._renderItemData(ul, item);
            if (item.category) {
                li.attr("aria-label", item.category + " : " + item.label);
            }
            i++;
        });
    },
    _renderItem: function (ul: JQuery, item: LabeledValue) {
        var textToDisplay;
        if (item.category === 'Customer Name') {
            textToDisplay = item.label;
        }
        else {
            textToDisplay = item.label + " (" + item.value + ")";
        }


        return $("<li>")
            .append("<div>" + textToDisplay + "</div>")
            .appendTo(ul);
    }
});