import * as React from "react"
import { connect } from "react-redux"
import { RootState } from "@states/state";
import { AppDispatch } from "@states/store"
import { CustomerFilterEnum } from "@states/customer/customer-types"
import { actions } from "../customer-tree-slice"
import { selectCurrentUserHasAnyRole } from "../../../states/user/user-selectors";

const mapStateToProps = (state: RootState) => ({
    filter: state.customerTree.filter,
    visible: selectCurrentUserHasAnyRole(state.user, 'LoginAsAnyCustomer', 'Reseller')
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    onWithScreens: () => dispatch(actions.setFilter(CustomerFilterEnum.WithScreens)),
    onWithoutScreens: () => dispatch(actions.setFilter(CustomerFilterEnum.WithoutScreens)),
    onAll: () => dispatch(actions.setFilter(CustomerFilterEnum.All))
})

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps

const StoreFilter = (props: Props) => (
    !props.visible ? null : (
        <div className="store-selection">
            <label>
                <input type="radio" name="ReactradioShowNodes" checked={props.filter === CustomerFilterEnum.WithScreens} onChange={props.onWithScreens} />
                <span>Stores with screens</span>
            </label>
            <label>
                <input type="radio" name="ReactradioShowNodes" checked={props.filter === CustomerFilterEnum.WithoutScreens} onChange={props.onWithoutScreens} />
                <span>Stores without screens</span>
            </label>
            <label>
                <input type="radio" name="ReactradioShowNodes" checked={props.filter === CustomerFilterEnum.All} onChange={props.onAll} />
                <span>All stores</span>
            </label>
        </div>
    )
)

export default connect(mapStateToProps, mapDispatchToProps)(StoreFilter)
