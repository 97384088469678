import { createSlice } from "@reduxjs/toolkit"
import { actions as chainActions } from "@states/customer-chain/customer-chain-slice"
import { fetchDirectorForCustomer, setDirectorBroken } from "@states/director/director-thunks"
import { Director, DirectorsState } from "@states/director/director-types"

const INITIAL_STATE: DirectorsState = {
    loaded: {},
    fetching: []
}

const slice = createSlice({
    name: 'directors',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: builder => { builder
        .addCase(fetchDirectorForCustomer.pending, (state, action) => {
            const customerId = action.meta.arg.customerId
            state.fetching = [...state.fetching, customerId]
        })
        .addCase(fetchDirectorForCustomer.fulfilled, (state, action) => {
            const director = action.payload
            const customerId = action.meta.arg.customerId

            if (director !== null) {
                addOrReplaceDirector(state, director)
            } else {
                removeDirector(state, customerId)
            }

            removeFetchingStatus(state, customerId)
        })
        .addCase(fetchDirectorForCustomer.rejected, (state, action) => {
            const customerId = action.meta.arg.customerId

            removeDirector(state, customerId)
            removeFetchingStatus(state, customerId)
        })

        .addCase(setDirectorBroken.pending, (state, action) => {
            const arg = action.meta.arg

            const director = state.loaded[arg.director.customerId]
            if (!director) return

            if (arg.broken)
                director.broken.current = {reason: arg.reason}
            else
                director.broken.current = null
        })
        .addCase(setDirectorBroken.fulfilled, (state, action) => {
            const arg = action.meta.arg

            const director = state.loaded[arg.director.customerId]
            if (!director) return

            if (arg.broken) {
                director.replacementMac = arg.replacementMac
                director.broken.underlying = {reason: arg.reason}
            }
            else
                director.broken.underlying = null
        })
        .addCase(setDirectorBroken.rejected, (state, action) => {
            const arg = action.meta.arg

            const director = state.loaded[arg.director.customerId]
            if (!director) return

            director.broken.current = director.broken.underlying
        })

        .addCase(chainActions.clearChainInfo, (state, action) => {
            state.loaded = {}
        })
    }
})

function addOrReplaceDirector(state: DirectorsState, director: Director) {
    state.loaded[director.customerId] = director;
}

function removeDirector(state: DirectorsState, customerId: number) {
    delete state.loaded[customerId]
}

function removeFetchingStatus(state: DirectorsState, customerId: number) {
    const index = state.fetching.indexOf(customerId)
    state.fetching = state.fetching.filter((_, i) => i !== index)
}

export const actions = slice.actions
export const reducer = slice.reducer