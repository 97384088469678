import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { MessageState, MessageType } from "@states/message/message-types"

const INITIAL_STATE: MessageState = {
    messages: [],
    nextId: 0
}

const show = (state: MessageState, action: { header: string, content: string, type: MessageType }) => {
    const id = state.nextId++

    state.messages[id] = {
        id,
        header: action.header,
        content: action.content,
        type: action.type
    }
}

const slice = createSlice({
    name: 'message',
    initialState: INITIAL_STATE,
    reducers: {
        show: (state, action: PayloadAction<{ header: string, content: string, type: MessageType }>) => {
            show(state, action.payload)
        },
        showInfo: (state, action: PayloadAction<{ header: string, content: string }>) => {
            show(state, {...action.payload, type: 'Info'})
        },
        showError: (state, action: PayloadAction<{ header: string, content: string }>) => {
            show(state, { ...action.payload, type: 'Error' })
        },
        hide: (state, action: PayloadAction<{ id: number }>) => {
            delete state.messages[action.payload.id]
        }
    }
})

export const actions = slice.actions
export const reducer = slice.reducer