import * as React from "react";

interface TabsProps extends React.Props<{}> {
    className?: string
}

const Tabs = (props: TabsProps) => {
    return (
        <div className={props.className}>
            {props.children}
        </div>
    )
}

export default Tabs