import { InfoMessage, selectMessages, InfoMessageReminderStatus } from '@states/info-message';
import { RootState } from "@states/state";
import { Tracked } from 'mummet-core/dist/types';
import { createSelector } from '@reduxjs/toolkit';
import { selectMessageOrEmpty } from '@states/info-message/info-message-selectors';
import { selectCustomerId } from '../customer-tree/customer-tree-selectors';
import { Target } from '../../lib/targetable';

export const selectTarget = (state: RootState, props: { target: Target }) => props.target;

export const selectHasReminder = (message: Tracked<InfoMessage>) =>
    !!message.current?.reminder

export const selectIsReminderClosed = (message: Tracked<InfoMessage>) =>
    message.current?.reminder?.status === InfoMessageReminderStatus.Closed

export const createMemoizedMessage = () => createSelector([selectMessages, selectTarget, selectCustomerId], selectMessageOrEmpty);