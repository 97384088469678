import { selectCustomerId } from "@features/customer-tree/customer-tree-selectors"
import { createSelector } from "@reduxjs/toolkit"
import { shouldShine } from "@states/brighttime/brighttime-controller"
import { selectBrightTimes, selectShineDefinitions } from "@states/brighttime/brighttime-selectors"
import { selectChannelFilter, selectChannels, selectChildren } from "@states/channel/channel-selectors"
import { Channel, MaterialStatus, MaterialStatusTexts } from "@states/channel/channel-types"
import { selectCustomer } from "@states/customer/customer-selectors"
import { RootState } from "@states/state"
import * as React from "react"
import { connect } from "react-redux"
import { Brighttime } from "./components/brighttime"
import { LightBulb } from "./components/lightbulb"
import { LockIcon } from "./components/lockicon"

type OwnProps = {
    channel: Channel,
    parents: number[]
}

const selectChannel = (_: RootState, props: OwnProps) => props.channel
const selectParentIds = (_: RootState, props: OwnProps) => props.parents
const selectBrightTimeId = (_: RootState, props: OwnProps) => props.channel.brightTimeId!

const makeMapStateToProps = () => {
    const memoizedShineDefinitions = createSelector(
        [selectBrightTimes, selectBrightTimeId],
        selectShineDefinitions
    )

    const memoizedSubchannels = createSelector(
        [selectChannels, selectCustomerId, selectChannelFilter, selectChannel, selectParentIds],
        selectChildren
    )

    return (state: RootState, props: OwnProps) => {
        const shineDefinitions = memoizedShineDefinitions(state, props)
        const subchannels = memoizedSubchannels(state, props);
        
        return {
            id: props.channel.id,
            name: props.channel.name,
            materialStatus: props.channel.materialStatus,
            materialStatusClassName: "channel-status--" + MaterialStatus[props.channel.materialStatus].toLowerCase(),
            fallbackPlaylistName: props.channel.fallbackPlaylistName,
            companyName: selectCustomer(state, props.channel.customerId).name,
            channelTypeUrl: props.channel.local ? '/Content/Images/icon_tree_local.png' : '/Content/Images/icon_tree_global.png',
            brightTimeId: props.channel.brightTimeId,
            brightTimeIsActive: shouldShine(shineDefinitions, new Date()),
            subchannels
        }
    }
}

type StateProps = ReturnType<ReturnType<typeof makeMapStateToProps>>
type Props = StateProps & OwnProps
const ChannelRow = (props: Props) => {
    return (
        <>
            <tr key={props.id} className={`node-depth-${props.parents.length}`} title={props.companyName}>
                <td><img src={props.channelTypeUrl} /></td>
                <td className="text-align-left">{props.name}</td>
                <td className={"text-align-left " + props.materialStatusClassName}>{MaterialStatusTexts[props.materialStatus]}</td>
                <td className="text-align-left">{props.fallbackPlaylistName}</td>
                <td className="text-align-left channel__brighttime">
                    <LightBulb id={props.brightTimeId!} isActive={props.brightTimeIsActive} />
                    <Brighttime id={props.channel.id} />
                </td>
                <td className="text-align-center channel__lock">
                    <LockIcon id={props.channel.id} />
                </td>
            </tr>

            {props.subchannels.map(child => <ConnectedChannelRow key={child.id} channel={child} parents={[...props.parents, props.channel.id]} />)}
        </>
    )
}

const ConnectedChannelRow = connect(makeMapStateToProps)(ChannelRow);
export default ConnectedChannelRow;
