import { DeviceButton } from "@features/device-details/components/buttons/device-button"
import {
    CommandsButton,
    DeleteButton,
    FactoryResetButton,
    FlowPlannerButton,
    LogsButton,
    MarkBrokenButton,
    PLAYLIST_BUTTON_TEXT,
    RestartButton,
    SCREENSHOT_BUTTON_PLURAL_TEXT
} from "@features/device-details/components/buttons/shared-device-buttons"
import deviceDetails from "@features/device-details/device-details-legacy"
import { Director } from "@states/director/director-types"
import * as React from "react"

interface Props {
    director: Director,
    customerId: number,

    displayFlowPlannerButton: boolean,
    displayRepairCenterButtons: boolean
}

export const DirectorButtons = (props: Props) => <>
    <DeviceButton
        onClick={() => deviceDetails.getScreenshotsForAllProjectors(props.customerId)}>
        {SCREENSHOT_BUTTON_PLURAL_TEXT}
    </DeviceButton>

    <DeviceButton enabled={false}>{PLAYLIST_BUTTON_TEXT}</DeviceButton>

    {
        props.displayFlowPlannerButton &&
            <FlowPlannerButton enabled={false}/>
    }

    {
        props.displayRepairCenterButtons && <>
            <CommandsButton/>
            <RestartButton/>
            <FactoryResetButton/>

            <LogsButton/>
            <MarkBrokenButton customerId={props.customerId} devices={props.director} />
            <DeleteButton/>
        </>
    }
</>