import { createAsyncThunk } from "@reduxjs/toolkit";
import xhr from "../../lib/xhr";
import { ThunkArgs } from "../state-types";

export const fetchUserNames = createAsyncThunk<string[], number[], ThunkArgs>(
    "users/fetchUserNames",
    async (args) => {
        if (args.length === 0)
            return []

        const { data: userNames } = await xhr.get<string[]>(`/users/names?userIds=${args.join("&userIds=")}`)
        return userNames
    })