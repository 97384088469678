import Axios, { AxiosError } from "axios"

const xhr = Axios.create();

const requestVerificationToken = (document.querySelector("[name='__RequestVerificationToken']") as HTMLInputElement)
if (requestVerificationToken) {
    xhr.interceptors.request.use((request) => {
        if (request.method !== "get") {
            console.log('XHR injecting validation token in request headers')
            request.headers['__RequestVerificationToken'] = requestVerificationToken.value
        }

        return request;
    })
}

xhr.interceptors.response.use((response) => {
    if (response.headers['content-type'] === "application/json; charset=utf-8" && typeof (response.data) === 'string') {
        response.data = JSON.parse(response.data);
    }

    return response;
})

export default xhr;

export function isXhrError<T>(error: AxiosError | any): error is AxiosError<T> {
    return error && error.isAxiosError
}