import { Device } from "@src/lib/device/device-types"
import { Target, TargetType } from "@src/lib/targetable"
import { isDirector } from "@states/director/director-util"
import { isProjector } from "@states/projector/projector-util"

export function deviceToTarget(device: Device | null): Target | null {
    if (device === null) return null

    if (isProjector(device))
        return { id: device.numericalId, type: TargetType.Screen }

    if (isDirector(device))
        return { id: device.numericalId, type: TargetType.RenderEngine }

    return null
}