import * as React from "react"
import { connect } from "react-redux"
import { RootState } from "@states/state";
import { ChannelFilter } from "./channel-filter";
import ChannelTable from "./channel-table";

const mapStateToProps = (state: RootState) => {
    return {
        selected: state.customerTree.selectedPageTab === "Channels"
    }
}

type Props = ReturnType<typeof mapStateToProps>

const ChannelsPage = (props: Props) => {
    if (!props.selected)
        return null;

    return (
        <div className="page page--channels">
            <ChannelFilter />
            <ChannelTable />
        </div>
    )
}

export default connect(mapStateToProps)(ChannelsPage)
