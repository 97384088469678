import CustomerTree from "@features/customer-tree/customer-tree"
import MainPage from "@features/main-page"
import { MessagePopups } from "@features/message/message"
import { PlaylistPreviewTutorial } from "@features/preview-playlist-tutorial/playlist-preview-tutorial"
import { unwrapResult } from "@reduxjs/toolkit"
import { fetchCountersForCustomers } from "@states/alarm/alarm-thunks"
import { checkChainsHaveDirectors, loadChains } from "@states/customer-chain/customer-chain-thunks"
import store from "@states/store"
import { actions as userActions, selectCurrentUserName } from "@states/user"
import * as $ from "jquery"
import * as React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import Cookies from "universal-cookie/cjs"
import { fetchInfoMessagesForOverview } from "../../states/info-message/info-message-thunks"
import { User } from "../../states/user/user-types"
import { setChainWithSideEffects, setCustomerIdWithSideEffects } from "../customer-tree"
import { COOKIE_CHAIN_ID_KEY, COOKIE_CUSTOMER_ID_KEY } from "../customer-tree/customer-tree-types"
import renderCustomerTree from "./customers-root"
import renderMenuRoot from "./menu-root"
import renderPageViews from "./pages-root"
import renderPageTabs from "./pagetabs-root"

declare global {
    // Assigned in Start.cshtml
    interface Window {
        userModel?: User,
        selectedChainId?: number
    }
}

const SunFlowMain = {
    DIRECTORIDLENGTH: 8,
    isIE8: $("html").hasClass("ie8"),
    initialize: function () {
        if (!window.userModel) {
            console.warn('User not logged in. sunflow-main not initializing')
            return
        }

        store.dispatch(userActions.setCurrentUser(window.userModel));
        delete window.userModel;

        const userName = selectCurrentUserName(store.getState().user)

        const cookies = new Cookies();
        let selectedChainId: number | undefined = +cookies.get(userName + COOKIE_CHAIN_ID_KEY);
        let selectedCustomerId: number | undefined = +cookies.get(userName + COOKIE_CUSTOMER_ID_KEY);

        if (isNaN(selectedChainId) || isNaN(selectedCustomerId)) {
            selectedChainId = window.selectedChainId
            selectedCustomerId = window.selectedChainId
        }

        store.dispatch(loadChains())
            .then(unwrapResult)
            .then((serverChainInfos) => {
                const chainIds = serverChainInfos.map(c => c.customerId)
                store.dispatch(fetchCountersForCustomers(chainIds))
                store.dispatch(checkChainsHaveDirectors(chainIds))
            })

        store.dispatch(setChainWithSideEffects(selectedChainId!))
        store.dispatch(setCustomerIdWithSideEffects(selectedCustomerId!));
        delete window.selectedChainId;

        store.dispatch(fetchInfoMessagesForOverview());

        CustomerTree.initialize();
        renderReactElements();

        $("#menu-user-name").dblclick((e) => {
            if (e.altKey) {
                store.dispatch(userActions.toggleDebug());
            }
        });
    },
    readyCallback: function (view:string) {
        if (view === 'customerTree') {
            MainPage.initialize(); // main page needs to be loaded after customerTree.
        }
    },
    scrollToSelectedItem: function ($scrollingContainer: JQuery, $itemToScroll: JQuery) {
        if ($itemToScroll.length) {
            if (!SunFlowMain.isIE8) {
                $scrollingContainer.animate(
                    {
                        scrollTop: $itemToScroll.offset()!.top - $scrollingContainer.offset()!.top + $scrollingContainer.scrollTop()!
                    });
            }
            else {
                $scrollingContainer.scrollTop($itemToScroll.offset()!.top - $scrollingContainer.offset()!.top + $scrollingContainer.scrollTop()!);
            }
        }
    },
    isNullOrWhitespace: function (input:string) {
        return (typeof input === 'undefined' || input == null)
            || input.replace(/\s/g, '').length < 1;
    },
    arraysEqual: function <T>(a: T[], b: T[]) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length != b.length) return false;

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    },
    addAntiForgeryToken: function (data:any) {
        data.__RequestVerificationToken = $('#__AjaxAntiForgeryForm input[name=__RequestVerificationToken]').val();
        return data;
    }
};

const renderReactElements = () => {
    renderCustomerTree(store, document.getElementById("side-bar-content-root")!)
    renderPageTabs(store, document.getElementById("main-page-upper-root")!)
    renderPageViews(store, document.getElementById("main-page-view-root")!)
    renderMenuRoot(store, document.getElementById("customer-title-menu")!);

    render(
        <Provider store={store}>
            <PlaylistPreviewTutorial />
            <MessagePopups />
        </Provider>,
        document.getElementById("message-root")
    )
}

export default SunFlowMain;