import { ContainerStatus } from "@src/lib/types/container-status"
import { CustomerChain } from "@states/customer-chain/customer-chain-types"
import { RootState } from "@states/state"

export const UNKNOWN_CHAIN: CustomerChain = {
    id: -1,
    customers: [],
    channels: [],
    searchInfo: [],
    brightTimes: {},

    status: ContainerStatus.Loaded,
    statusText: "Unknown"
}

export const selectCurrentChain = (state: RootState) =>
    state.customerChain[state.customerTree.selectedChainId] ?? UNKNOWN_CHAIN