import { selectCustomerId } from "@features/customer-tree/customer-tree-selectors"
import { createSelector } from "@reduxjs/toolkit"
import { selectLoadedDirectors } from "@states/director/director-selectors"
import { selectLoadedProjectors } from "@states/projector/projector-selectors"
import { RootState } from "@states/state"
import { isFilled } from "ts-is-present"

export const selectSelectedDevice = (state: RootState): null | 'director' | {projectorMac: string} => {
    const customerId = selectCustomerId(state)
    return state.deviceOverview[customerId]?.selected ?? null
}

export const selectCheckedProjectorMacs = (state: RootState): string[] => {
    const customerId = selectCustomerId(state)
    return state.deviceOverview[customerId]?.checkedProjectorMacs ?? []
}

export const memoizedActiveDevices = createSelector(
    [selectSelectedDevice, selectCheckedProjectorMacs],
    (selectedDevice, checkedProjectorMacs) => {

        if (selectedDevice === null)
            return null

        if (checkedProjectorMacs.length > 0)
            return checkedProjectorMacs.map(projectorMac => ({projectorMac}))

        if (selectedDevice === 'director')
            return 'director'

        return [selectedDevice]
    }
)

export const memoizedActiveLoadedDevices = createSelector(
    [selectCustomerId, selectLoadedDirectors, selectLoadedProjectors, memoizedActiveDevices],
    (customerId, loadedDirectors, loadedProjectors, activeDevices) => {

        if (activeDevices === 'director')
            return loadedDirectors[customerId] ?? null

        if (Array.isArray(activeDevices)) {
            return activeDevices
                .map(ad => loadedProjectors[ad.projectorMac] ?? null)
                .filter(isFilled)
        }

        return null
    }
)

export const selectIsFetchingAnyDevice = (state: RootState) => {
    if (state.director.fetching.length > 0)
        return true

    if (state.projector.fetching.length > 0)
        return true

    return false
}