import * as ReactDOM from "react-dom";
import * as React from "react";
import { Provider } from "react-redux";
import { Store } from "@reduxjs/toolkit";
import { RootState } from "../../states/state";
import { Menu } from "../menu";
import MainPage from "../main-page";
import CustomerTree from "../customer-tree/customer-tree";
import EmailMonitor from "../email-monitor";
import { actions } from "@features/customer-tree/customer-tree-slice"

const emailMonitorClicked = () => EmailMonitor.requestViewChange(CustomerTree.getSelectedCustomerId(), CustomerTree.getSelectedChainId(), false);

const setupClicked = () => MainPage.selectTab('Setup');
const brokenClicked = () => MainPage.selectTab('Broken');
const settingsClicked = () => MainPage.selectTab('Settings');
const dashboardClicked = () => MainPage.selectTab('Dashboard');

function clickOutsideHamburg(compareUpTarget: HTMLElement, compareDownTarget: HTMLElement) {
    var $overlay = $('#main-page-floating-hamburger-menu,#menu-hamburger,#menu-user-name')
    if (MainPage.isTargetOutsideElement(compareUpTarget, $overlay) && MainPage.isTargetOutsideElement(compareDownTarget, $overlay)) {
        onUserPanelHidden();
    }
}

function clickOutsideInfo(compareUpTarget: HTMLElement, compareDownTarget: HTMLElement) {
    var $overlay = $('#info-message-fanout,#info-message-list-button')
    if (MainPage.isTargetOutsideElement(compareUpTarget, $overlay) && MainPage.isTargetOutsideElement(compareDownTarget, $overlay)) {
        onInfoMessageListHidden();
    }
}

const onUserPanelShown = () => {
    document.getElementById('main-page-floating-hamburger-menu')!.classList.toggle('hidden');
    MainPage.registerOutsideClickCallback("menu-root-hamburg", clickOutsideHamburg);
};
const onUserPanelHidden = () => {
    document.getElementById('main-page-floating-hamburger-menu')!.classList.toggle('hidden');
    MainPage.unregisterOutsideClickCallback("menu-root-hamburg");

};

const onInfoMessageListShown = () => {
    document.getElementById('info-message-fanout')!.classList.toggle('hidden');
    MainPage.registerOutsideClickCallback("menu-root-info", clickOutsideInfo);
};
const onInfoMessageListHidden = () => {
    document.getElementById('info-message-fanout')!.classList.toggle('hidden');
    MainPage.unregisterOutsideClickCallback("menu-root-info");

};

export default (store: Store<RootState>, container: HTMLElement) => {
    const openChainDialog = () => {
        store.dispatch(actions.showChainDialog(true))
    }

    ReactDOM.render(
        <Provider store={store}>
            <Menu
                onEmailMonitoringClick={emailMonitorClicked}
                onSetupClick={setupClicked}
                onChangeChainClick={openChainDialog}
                onBrokenClick={brokenClicked}
                onSettingsClick={settingsClicked}
                onDashboardClick={dashboardClicked}
                onUserPanelShown={onUserPanelShown}
                onUserPanelHidden={onUserPanelHidden}
                onInfoMessageListHidden={onInfoMessageListHidden}
                onInfoMessageListShown={onInfoMessageListShown}
            />
        </Provider>,
        container
    );
}
