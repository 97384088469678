import { MAX_ACKNOWLEDGE_LENGTH_IN_DAYS, MIN_ACKNOWLEDGE_LENGTH_IN_MINUTES } from "@states/alarm/alarm-types"
import * as moment from "moment"
import * as React from "react"
import { DateTimeString, toDateTime } from "@src/lib/date-string"
import { AcknowledgeButton } from "./acknowledge-button"
import { Alarm, AlarmColor } from "./alarm"

type AlarmProp = {
    header: string;
    at: null | {
        friendly: string;
        tooltip: string;
    },
    color: AlarmColor;
}

export type AcknowledgeProp = {
    by: string
    at: {
        friendly: string
        tooltip: string
    },
    until: {
        dirty: boolean
        tooltip: string
        iso: DateTimeString,
        clearWhenResolved: boolean
    },
    comment: {
        dirty: boolean
        text: string
    }    
}

interface OwnProps {
    alarm: AlarmProp
    acknowledge?: AcknowledgeProp
    nowSupplier: () => DateTimeString
    onAcknowledgeToggle?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
    onCommentChange?: (event: React.ChangeEvent<HTMLInputElement>) => void

    onRequestDefaultUntilValue?: () => void
    onUntilChange?: (date: DateTimeString) => void
    onClearWhenResolvedChange?: (clearWhenResolved: boolean) => void
}

export const AcknowledgeableAlarmInternal = (props: OwnProps) => {
    const onUntilChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!props.onUntilChange)
            return

        const value = e.target.value;
        props.onUntilChange(toDateTime(new Date(value)));
    }

    const onClearWhenResolvedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!props.onClearWhenResolvedChange)
            return

        const value = e.target.checked
        props.onClearWhenResolvedChange(value)
    }

    const htmlDateFromIsoDate = (iso: string) => {
        return moment(iso).format("YYYY-MM-DD[T]HH:mm");
    }

    const getAcknowledgeUntilMin = () => {
        const date = new Date(props.nowSupplier());
        date.setMinutes(date.getMinutes() + MIN_ACKNOWLEDGE_LENGTH_IN_MINUTES)
        return htmlDateFromIsoDate(date.toISOString());
    }

    const getAcknowledgeUntilMax = () => {
        const date = new Date(props.nowSupplier());
        date.setDate(date.getDate() + MAX_ACKNOWLEDGE_LENGTH_IN_DAYS)
        return htmlDateFromIsoDate(date.toISOString());
    }

    const createUntilElements = (acknowledge: AcknowledgeProp) => <>
        <p className="acknowledge__header">Until</p>

        <input
            type="datetime-local"
            className="acknowledge__until__date input"
            title={acknowledge.until.tooltip}
            min={getAcknowledgeUntilMin()}
            max={getAcknowledgeUntilMax()}
            value={htmlDateFromIsoDate(acknowledge.until.iso)}
            onChange={onUntilChange}/>

        <label>
            <input
                type="checkbox"
                className="acknowledge__until__resolve input"
                checked={acknowledge.until.clearWhenResolved}
                onChange={onClearWhenResolvedChange}/>
            Clear when resolved
        </label>
    </>

    const subheader = props.alarm.at
        ? { text: props.alarm.at.friendly, tooltip: "Since " + props.alarm.at.tooltip }
        : undefined;

    const untilDirty = props.acknowledge?.until.dirty === true ? " tracked-dirty" : ""
    const commentDirty = props.acknowledge?.comment.dirty === true ? " tracked-dirty" : ""

    return <Alarm
        header={props.alarm.header}
        subheader={subheader}
        color={props.alarm.color}
        headerRight={<AcknowledgeButton acknowledged={!!props.acknowledge} onClick={props.onAcknowledgeToggle} />}>
        {props.acknowledge && <>
            <div className="acknowledge__top">
                <div className="acknowledge__details">
                    <p className="acknowledge__header">Acknowledged</p>
                    <p title={"At " + props.acknowledge.at.tooltip.toLowerCase()}>{props.acknowledge.at.friendly}</p>
                    <p>{props.acknowledge.by}</p>
                </div>
                <div className={"acknowledge__until" + untilDirty}>
                    {createUntilElements(props.acknowledge)}
                </div>
            </div>
            <div className={"acknowledge__comment" + commentDirty}>
                <p className="acknowledge__header">Comment</p>
                <input className="input" type="text" value={props.acknowledge.comment.text} onChange={props.onCommentChange} />
            </div>
        </>}
    </Alarm>
}