import * as React from "react"
import { Tracked } from "mummet-core/dist/types"
import { Acknowledge, InactiveAlarm as InactiveAlarmType } from "../../../states/alarm/alarm-types"
import { AcknowledgeableAlarm } from "./acknowledgeable-alarm"
import { DateTimeString } from "../../../lib/date-string"

type OwnProps = {
    customerId: number;
    alarm: InactiveAlarmType;
    acknowledge?: Tracked<Acknowledge>;
    nowSupplier: () => DateTimeString;
}

export const InactiveAlarm = (props: OwnProps) => (
    <AcknowledgeableAlarm
        customerId={props.customerId}
        color='inactive'
        alarm={props.alarm}
        acknowledge={props.acknowledge}
        nowSupplier={props.nowSupplier}
    />)