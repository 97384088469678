import * as ReactDOM from "react-dom";
import * as React from "react";
import { Provider } from "react-redux";
import { Store } from "@reduxjs/toolkit";
import { RootState } from "../../states/state";
import ChannelsPage from "../../pages/channels"
import SettingsPage from "../../pages/settings"
import DashboardPage from "../../pages/dashboard"

export default (store: Store<RootState>, container: HTMLElement) => {
    ReactDOM.render(
        <Provider store={store}>
            <ChannelsPage />
            <SettingsPage />
            <DashboardPage />
        </Provider>,
        container
    );
}
