import * as moment from "moment";

const yyyy_MM_dd_REGEX = /^(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})$/

enum DateStringBrand { _ = "" }
export type DateString = string & DateStringBrand

export function isValidDate(dateString: string) {
    const result = yyyy_MM_dd_REGEX.exec(dateString)
    if (!result) {
        return false
    }

    const group = (result as any).groups
    if (!group) {
        return false
    }

    const dt = {
        year: Number(group.year),
        month: Number(group.month),
        day: Number(group.day)
    }
    const date = new Date(dt.year, dt.month - 1, dt.day)
    return date && (date.getMonth() + 1) === dt.month && date.getDate() === dt.day
}

const isDateString = (dateString: string): dateString is DateString => isValidDate(dateString)

export function toDate(date: Date | moment.Moment | string): DateString {
    if (typeof date === 'string') {
        if (isDateString(date)) {
            return date;
        }
    }

    if (date) {
        const dateString = moment(date).format('YYYY-MM-DD');
        if (isDateString(dateString)) {
            return dateString;
        }
    }

    throw new Error(`Failed to convert '${date}' to DateString!`);
}
