import { connect } from "react-redux"
import { AppDispatch } from "../../../states/store"
import { RootState } from "../../../states/state"
import Select from "../../../components/select"
import { selectCurrentChain } from "@states/customer-chain/customer-chain-selectors"
import { actions } from "@states/channel/channel-slice"

type OwnProps = { id: number }

const mapStateToProps = (state: RootState, props: OwnProps) => {
    const channel = state.channel[props.id]

    return {
        value: channel.current?.brightTimeId ?? '',
        options: selectCurrentChain(state).brightTimes,
        optionLabel: '',
        className: channel.underlying?.brightTimeId !== channel.current!.brightTimeId ? 'tracked-dirty' : undefined
    }
}

const mapDispatchToProps = (dispatch: AppDispatch, props: OwnProps) => ({
    onChange: (value: number | string) => dispatch(actions.setBrightTime({ channel: props.id, brightTime: +value }))
})

export const Brighttime = connect(mapStateToProps, mapDispatchToProps)(Select)
