import * as React from "react"
import { connect } from "react-redux"
import { RootState } from "@states/state";
import { AppDispatch } from "@states/store"
import { setCustomerIdOnLegacyDOM } from "../customer-tree-thunks"
import { actions as customerTreeActions } from "../customer-tree-slice"
import CustomerStatus from "./customer-status"
import { useScrollIntoView } from "../../../lib/useScrollIntoView";
import { createSelector } from "@reduxjs/toolkit";
import { selectCustomers, selectAllChildren, selectIsWithDirector, selectIsWithoutDirector, customerFilter, selectIsVisible } from "../customer-tree-selectors";

const selectId = (state: RootState, props: OwnProps) => props.id
const selectIsTopNode = (state: RootState, props: OwnProps) => !state.customer[props.id]?.parentId

interface OwnProps {
    id: number,
    depth: number
}

const makeMapStateToProps = () => {
    const memoizedCustomersInChain = createSelector([selectCustomers, selectId], selectAllChildren)
    const memoizedWithDirector = createSelector([memoizedCustomersInChain], selectIsWithDirector)
    const memoizedWithoutDirector = createSelector([memoizedCustomersInChain], selectIsWithoutDirector)

    const memoizedIsVisible = createSelector([
        customerFilter, memoizedWithDirector, memoizedWithoutDirector, selectIsTopNode],
        selectIsVisible
    )

    return (state: RootState, props: OwnProps) => {
        const customer = state.customer[props.id];
        const selectedId = state.customerTree.selectedCustomerId;

        return {
            name: customer!.name,
            children: customer!.children,
            isSelected: props.id === selectedId,
            visible: memoizedIsVisible(state, props),
            shouldScroll: state.customerTree.scrollToCustomerWhenSelected
        }
    }
}

const mapDispatchToProps = (dispatch: AppDispatch, props: OwnProps) => ({
    onSelect: () => {
        dispatch(customerTreeActions.setScrollToCustomerWhenSelected(false));
        dispatch(setCustomerIdOnLegacyDOM(props.id));
    }
})

type StateProps = ReturnType<ReturnType<typeof makeMapStateToProps>>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & OwnProps & DispatchProps;

const ConnectedCustomer = connect(makeMapStateToProps, mapDispatchToProps)(CustomerElement);

function CustomerElement(props: Props) {
    const [element] = useScrollIntoView<HTMLDivElement>(props.shouldScroll)

    if (!props.visible)
        return null;

    let className = `node-depth-${props.depth} customer-node`
    if (props.isSelected) className += '-selected'

    return (
        <>
            <div className={className} ref={element} title={props.name} onClick={props.onSelect}>
                <div>{props.name}</div>
                <CustomerStatus id={props.id} />
            </div>

            {props.children.map(id => <ConnectedCustomer key={id} id={id} depth={props.depth + 1} />)}
        </>
    )
}

export default ConnectedCustomer
