import * as React from "react"
import { connect } from "react-redux"
import { RootState } from "@states/state";
import { ChannelFilterEnum } from "@states/customer/customer-types";
import { Dispatch } from "redux";
import { actions } from "@features/customer-tree/customer-tree-slice"
import { Label } from "../../components/label";
import { createSelector } from "@reduxjs/toolkit";
import { selectCustomerId } from "../../features/customer-tree/customer-tree-selectors";
import { selectChannels, selectTopLevelCustomerChannels, countChannelsInTree, selectChannelFilter } from "../../states/channel/channel-selectors";

const memoizedCustomerChannels = createSelector(
    [selectChannels, selectCustomerId],
    selectTopLevelCustomerChannels
)

const memoizedChannelCounter = createSelector(
    [selectChannels, selectCustomerId, selectChannelFilter, memoizedCustomerChannels],
    countChannelsInTree
)

const mapState = (state: RootState) => ({
    selectedFilter: selectChannelFilter(state),
    count: memoizedChannelCounter(state)
})

const mapDispatch = (dispatch: Dispatch) => ({
    onCustomer: () => dispatch(actions.setChannelFilter(ChannelFilterEnum.SelectedCustomer)),
    onAll: () => dispatch(actions.setChannelFilter(ChannelFilterEnum.AllCustomers)),
})


type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

const FilterFilterInternal = (props: Props) => {
    return (
        <div className="row">
            <Label>{props.count}</Label>
            <input type="radio" name="radio-channel-filter" checked={props.selectedFilter === ChannelFilterEnum.SelectedCustomer} onChange={props.onCustomer} /><span>On this location</span>
            <input type="radio" name="radio-channel-filter" checked={props.selectedFilter === ChannelFilterEnum.AllCustomers} onChange={props.onAll}/><span>Include sub locations</span>
        </div>
    )
}

export const ChannelFilter = connect(mapState, mapDispatch)(FilterFilterInternal)
