import { Tracked } from "mummet-core/dist/types"
import { isPresent } from "ts-is-present"

export interface Target {
    id: number,
    type: TargetType
}

export interface Targetable {
    target: Target
}

export enum TargetType {
    Screen = 1,
    RenderEngine = 2,
    Customer = 3
}

type TargetableType = Target | Targetable | Tracked<Targetable> | null

/**
 * Checks if two targetable types reference the same target.
 * 
 * That is, if both the target's id and target's type are equal.
 * Two null targets are not considered to be equal.
 */
export const areTargetsEqual = (a: TargetableType, b: TargetableType): boolean => {
    const targetA = getTarget(a)
    const targetB = getTarget(b)

    if (targetA === null || targetB === null)
        return false

    return targetA.id === targetB.id && targetA.type === targetB.type
}

const getTarget = (a: TargetableType): Target | null => {
    if (isTargetable(a))
        return a.target

    if (isTracked(a))
        return a.current?.target ?? null

    return a
}

const isTargetable = (a: any): a is Targetable => {
    if (!isPresent(a)) return false

    if ('target' in a) {
        const target = a.target

        return 'id' in target && 'type' in target
    }

    return false
}

const isTracked = (a: any): a is Tracked<Targetable> => {
    if (!isPresent(a)) return false

    return 'underlying' in a &&
        'current' in a &&
        'loaded' in a &&
        isTargetable(a.current)
}