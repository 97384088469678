import * as ReactDOM from "react-dom";
import * as React from "react";
import { Provider } from "react-redux";
import { Store } from "@reduxjs/toolkit";
import { RootState } from "../../states/state";
import PageTabs from "../page-tabs/page-tabs"

export default (store: Store<RootState>, container: HTMLElement) => {
    ReactDOM.render(
        <Provider store={store}>
            <PageTabs />
        </Provider>,
        container
    );
}
