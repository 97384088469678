import * as React from "react"
import { connect } from "react-redux"
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@states/state";
import FA from "../../../components/fa";
import { selectAcknowledgedAlarmCountForCustomer, selectUnacknowledgedAlarmCountForCustomer } from "../../../states/alarm/alarm-selectors";

interface OwnProps {
    id: number
}

const makeMapStateToProps = () => {

    const selectCustomerId = (state: RootState, props: OwnProps) => props.id

    const memoizedAlarmCount = createSelector(
        (state: RootState) => state.alarm.overview, selectCustomerId,
        (overview, customerId) => selectUnacknowledgedAlarmCountForCustomer(overview, customerId)
    )

    const memoizedAcknowledgeCount = createSelector(
        (state: RootState) => state.alarm.overview, selectCustomerId,
        (overview, customerId) => selectAcknowledgedAlarmCountForCustomer(overview, customerId)
    )

    return (state: RootState, props: OwnProps) => {
        const hasDirector = !!state.customer[props.id]?.directorId;
        const deviceEvents = hasDirector ? memoizedAlarmCount(state, props) : 0;
        const ackedDeviceEvents = hasDirector ? memoizedAcknowledgeCount(state, props) : 0;
        return { hasDirector, deviceEvents, ackedDeviceEvents }
    }
}

type StateProps = ReturnType<ReturnType<typeof makeMapStateToProps>>
type Props = StateProps & OwnProps;

const ConnectedCustomerState = connect(makeMapStateToProps)(CustomerState);

function CustomerState(props: Props) {
    if (!props.hasDirector)
        return null;

    if (props.deviceEvents) {
        return <div className="label label-small label-alert">{props.deviceEvents}</div>
    }
    else if (props.ackedDeviceEvents) {
        return <div className="label label-small">{props.ackedDeviceEvents}</div>
    }
    else {
        return <div><FA icon="check-circle" className="color-success" /></div>
    }
}

export default ConnectedCustomerState
