import { Dictionary } from "@reduxjs/toolkit";
import { Tracked } from "mummet-core/dist/types";
import { RootState } from "../state";
import { ChannelFilterEnum } from "../customer/customer-types";
import { Channel } from "./channel-types";
import { isPresent } from "ts-is-present";

export const selectChannelFilter = (state: RootState) => state.customerTree.filterChannels
export const selectChannels = (state: RootState) => state.channel;

export const selectTopLevelCustomerChannels = (channels: Dictionary<Tracked<Channel>>, customerId: number) =>
    Object.keys(channels)
        .map(id => channels[id]?.current)
        .filter(isPresent)
        .filter(ch => ch.customerId === customerId)
        .filter(ch => ch.parentId === null)
        .sort((a, b) => a.name.localeCompare(b.name))

export const selectChannelsWithName = (channels: Dictionary<Tracked<Channel>>, channelName: string) =>
    Object.keys(channels)
        .map(id => channels[id]?.current)
        .filter(isPresent)
        .filter(ch => ch.name === channelName);

export const selectDirtyChannels = (channels: Dictionary<Tracked<Channel>>) =>
    Object.keys(channels)
        .map(id => channels[id]!)
        .filter(t => t.current !== t.underlying)
        .filter(t => {
            if (t.current === null || t.underlying === null) // added or deleted
                return true;

            const { current: c, underlying: u } = t;
            return c.brightTimeId !== u.brightTimeId || c.local !== u.local || c.locked !== u.locked;
        });

export const countChannelsInTree = (
    channels: Dictionary<Tracked<Channel>>,
    customerId: number,
    filter: ChannelFilterEnum,
    customerChannels: Channel[]
) => aggregate(channels, customerId, filter, customerChannels, [])

function aggregate(
    channels: Dictionary<Tracked<Channel>>,
    customerId: number,
    filter: ChannelFilterEnum,
    parents: Channel[],
    loopDetection: number[]
) {
    return parents.reduce(
        (sum, ch) => sum + countChildren(channels, customerId, filter, ch, [...loopDetection, ch.id]),
        parents.length
    );
}

function countChildren(
    channels: Dictionary<Tracked<Channel>>,
    customerId: number,
    filter: ChannelFilterEnum,
    parent: Channel,
    loopDetection: number[]
): number {
    const children = selectChildren(channels, customerId, filter, parent, loopDetection);
    return aggregate(channels, customerId, filter, children, loopDetection);
}

export function selectChildren(
    channels: Dictionary<Tracked<Channel>>,
    customerId: number,
    filter: ChannelFilterEnum,
    parent: Channel,
    loopDetection: number[]
) {
    let children = parent.children
        .map(id => channels[id]?.current)
        .filter(isPresent)
        .filter(ch => !loopDetection.includes(ch.id))

    if (filter === ChannelFilterEnum.SelectedCustomer)
        children = children.filter(ch => ch.customerId === customerId);

    return children;
}
    
export function selectParents(
    channels: Dictionary<Tracked<Channel>>,
    channelId: number
) {
    const parents: Channel[] = [];
    selectParentsInternal(parents, channels, channelId, []);
    return parents;
}

function selectParentsInternal(
    parents: Channel[],
    channels: Dictionary<Tracked<Channel>>,
    channelId: number,
    loopDetection: number[]
) {
    loopDetection.push(channelId);
    const curChannel = channels[channelId]?.current ?? null;
    if (curChannel !== null) {
        parents.push(curChannel);
        const parentId = curChannel?.parentId ?? null;
        if (parentId !== null && !loopDetection.includes(parentId)) {
            selectParentsInternal(parents, channels, parentId, loopDetection);
        }
    }
}