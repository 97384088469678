import * as React from "react";
import { RootState } from "@states/state";
import { AppDispatch } from "@states/store";
import { connect } from "react-redux";
import { selectChainName, selectCurrentUserRoles, selectCurrentUserName } from "@states/user";
import { createSelector } from "@reduxjs/toolkit";
import { SharedSaveButton } from "@features/shared-save-button";
import FA from "../../components/fa";
import { memoizedReminderCount } from "../../states/info-message";
import InfoMessageList from "./components/info-message-list";
import { selectHasAnyRole, selectHasRole } from "../../states/user/user-selectors";
import { memoizedAggregateAlarmsCount } from "../chain-tree/chain-tree-selectors";

interface OwnProps {
    onEmailMonitoringClick: React.ReactEventHandler,
    onSetupClick: React.ReactEventHandler,
    onChangeChainClick: React.ReactEventHandler,
    onBrokenClick: React.ReactEventHandler,
    onSettingsClick: React.ReactEventHandler,
    onDashboardClick: React.ReactEventHandler,
    onUserPanelShown: () => void,
    onUserPanelHidden: () => void,
    onInfoMessageListShown: () => void,
    onInfoMessageListHidden: () => void,
}


const makeMapStateToProps = () => {
    const memoizedIsShoppaAdmin = createSelector([selectCurrentUserRoles],
        (roles) => selectHasRole(roles, 'LoginAsAnyCustomer'));
    const memoizedIsReseller = createSelector([selectCurrentUserRoles],
        (roles) => selectHasAnyRole(roles, 'LoginAsAnyCustomer', 'Reseller'));
    const memoizedIsSunflowInstaller = createSelector([selectCurrentUserRoles],
        (roles) => selectHasAnyRole(roles, 'LoginAsAnyCustomer', 'SunFlowInstaller'));

    return (state: RootState, props: OwnProps) => ({
        userName: selectCurrentUserName(state.user),
        chainName: selectChainName(state),
        changeChainVisible: memoizedIsReseller(state.user),
        setupVisible: memoizedIsSunflowInstaller(state.user),
        emailMonitorVisible: memoizedIsReseller(state.user),
        messageCount: memoizedReminderCount(state),
        remindersVisible: memoizedIsShoppaAdmin(state.user),
        totalAlarmCount: memoizedAggregateAlarmsCount(state)
    })
}

const mapDispatchToProps = (dispatch: AppDispatch, props: OwnProps) => ({
    onShowUserPanel: () => {
        const menu = document.getElementById('main-page-floating-hamburger-menu');
        if (menu!.classList.contains('hidden')) {
            props.onUserPanelShown();
        }
        else {
            props.onUserPanelHidden();
        }
    },
    onShowInfoMessageList: () => {
        const infoMessageFanout = document.getElementById('info-message-fanout');
        if (infoMessageFanout!.classList.contains('hidden')) {
            props.onInfoMessageListShown();
        }
        else {
            props.onInfoMessageListHidden();
        }
    },
    onLogoutClick: () => { window.location.href = '/account/logout' },
    onEmailMonitoringClick: (e: React.SyntheticEvent) => { props.onUserPanelHidden(); props.onEmailMonitoringClick(e); },
    onSetupClick: (e: React.SyntheticEvent) => { props.onUserPanelHidden(); props.onSetupClick(e); },
    onBrokenClick: (e: React.SyntheticEvent) => { props.onUserPanelHidden(); props.onBrokenClick(e); },
    onSettingsClick: (e: React.SyntheticEvent) => { props.onUserPanelHidden(); props.onSettingsClick(e); },
    onDashboardClick: (e: React.SyntheticEvent) => { props.onUserPanelHidden(); props.onDashboardClick(e); },
    onChangeChainClick: props.onChangeChainClick,
});

type Props = ReturnType<ReturnType<typeof makeMapStateToProps>> & ReturnType<typeof mapDispatchToProps> & OwnProps;

export const Menu = (props: Props) => {
    return (<>
        {props.remindersVisible &&
            <>
                <div className="title__item">
                    <SharedSaveButton />
                </div>
                <div className="title-menu-button-with-fanout" id="info-message-list-button">
                    <div className="title__item title-menu-button" onClick={props.onShowInfoMessageList}>
                        <FA icon="bell" fixedWidth={true} style={props.messageCount > 0 ? null : "r"} />
                        {props.messageCount > 0 &&
                            <span className="title-menu-button-badge">{props.messageCount}</span>
                        }
                    </div>
                    <div className="hidden user-panel-floating-fanout" id="info-message-fanout">
                        <InfoMessageList closeInfoMessageList={props.onInfoMessageListHidden} />
                    </div>
                </div>
            </>
        }

        {props.changeChainVisible &&
            <div className="title__item title__item__text title-menu-button" onClick={props.onChangeChainClick}>
                <FA icon="building" fixedWidth={true} style="r" />
                <span>{props.chainName}</span>
                {props.totalAlarmCount > 0 && <span className="title-menu-button-badge badge--left">{props.totalAlarmCount}</span>}
            </div>
        }

        <div id="menu-user-name" className="title__item title__item__text title-menu-button" onClick={props.onShowUserPanel}>
            <FA icon="user-circle" fixedWidth={true} />
            <span>{props.userName}</span>
        </div>
        <div id="menu-hamburger" className="title__hamburger title-menu-button-with-fanout">
            <FA className="main-page-hamburger-button" fixedWidth={true} onClick={props.onShowUserPanel} icon="bars" size="2x" />
            <div className="hidden user-panel-floating-fanout" id="main-page-floating-hamburger-menu">
                {props.setupVisible &&
                    <button type="button" onClick={props.onSetupClick} className="primary" data-tab="Setup" title="Register devices"><FA icon="tv" fixedWidth={true} /> Register devices</button>
                }
                <button type="button" onClick={props.onBrokenClick} className="primary" data-tab="Broken" title="Broken devices"><FA icon="unlink" fixedWidth={true} /> Broken devices</button>
                {props.emailMonitorVisible &&
                    <button onClick={props.onEmailMonitoringClick} type="button" className="primary"><FA icon="envelope" fixedWidth={true} /> E-mail monitoring</button>
                }
                <button type="button" onClick={props.onSettingsClick} className="primary" data-tab="Settings" title="Settings"><FA icon="cog" fixedWidth={true} /> Settings</button>
                <button type="button" onClick={props.onDashboardClick} className="primary" data-tab="Dashboard" title="Dashboard"><FA icon="cog" fixedWidth={true} /> Dashboard</button>
                <hr />
                <button type="button" className="primary" onClick={props.onLogoutClick}><FA icon="power-off" fixedWidth={true} /> Log Out</button>
            </div>
        </div>
    </>)
}

export default connect(makeMapStateToProps, mapDispatchToProps)(Menu)