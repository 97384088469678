import { Dictionary } from "@reduxjs/toolkit"
import * as React from "react"

type Props = {
    value: number|string,
    options: Dictionary<string>
    optionLabel?: string,
    className?: string,
    onChange: (id:number|string)=>void
}


export default (props: Props) => {
    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => props.onChange(e.target.value)

    const options = Object.keys(props.options)
        .map(id => <option key={id} value={id}>{props.options[id]}</option>)

    if ('optionLabel' in props)
        options.unshift(<option key={'OPTION_LABEL_KEY'} value=''></option>)

    return (
        <select className={props.className} value={props.value} onChange={onChange}>
            {...options}
        </select>
    )
}