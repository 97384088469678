import { DeviceButton } from "@features/device-details/components/buttons/device-button"
import FA from "@src/components/fa"
import { useAppSelector } from "@states/hooks"
import { RootState } from "@states/state"
import * as React from "react"
import { ReactNode, useMemo } from "react"

interface Props<TDevice, TContent> {
    loadedIcon: string,
    enabled?: boolean,

    /** Requests the device that contains the content. */
    onSelectDevice: (state: RootState) => TDevice | null,

    /** Retrieves the content from the device. */
    onSelectContentOrStatus: (device: TDevice) => TContent | 'loading' | null,

    /** Called when the button is clicked.
     *
     * The handler should start loading the content if it's <code>null</code>
     */
    onClick: (device: TDevice, content: TContent | null) => void,

    children: ReactNode
}

/**
 * A button with 3 states:
 * - unloaded
 * - loading
 * - loaded
 *
 * Clicking the button will start the loading process.
 * The button will be disabled while the content is being loaded.
 * The button will be enabled and display the specified icon when the content is loaded.
 */
export const LoadableContentDeviceButton = <TDevice, TContent>(props: Props<TDevice, TContent>) => {
    const device = useAppSelector(props.onSelectDevice)

    const contentOrStatus = useMemo(() => {
        return device
            ? props.onSelectContentOrStatus(device)
            : null
    }, [device])

    const icon = useMemo(() => {
        if (contentOrStatus === 'loading')
            return <FA icon="spinner" spin />

        return contentOrStatus
            ? <FA icon={props.loadedIcon} />
            : undefined
    }, [contentOrStatus])

    const enabled =
        props.enabled !== false &&
        device !== null &&
        contentOrStatus !== 'loading'

    return <DeviceButton
        enabled={enabled}
        icon={icon}
        onClick={() => enabled && props.onClick(device, contentOrStatus)}>
        {props.children}
    </DeviceButton>
}