import { Dictionary } from "@reduxjs/toolkit";
import { RootState } from "@states/state";
import { Customer, CustomerFilterEnum } from "../../states/customer/customer-types";

export const selectCustomerId = (state: RootState) =>
    state.customerTree.selectedCustomerId;

export const selectChainId = (state: RootState) =>
    state.customerTree.selectedChainId;

export const selectCustomers = (state: RootState) => state.customer
export const customerFilter = (state: RootState) => state.customerTree.filter

export const selectAllChildren = (customers: Dictionary<Customer>, id: number) => {
    return getChildren(id)

    function getChildren(id: number): Customer[] {
        const current = customers[id]!;
        const children = current.children.map(getChildren)!
        const flattenedChildren = ([] as Customer[]).concat(...children)
        return [current, ...flattenedChildren]
    }
}

export const selectCustomerAsCustomerId = (customers: Customer[]) => customers.map(c => c.customerId)

export const selectIsWithDirector = (customers: Customer[]) => customers.some(customer => customer.directorId !== null)
export const selectIsWithoutDirector = (customers: Customer[]) => customers.some(customer => customer.directorId === null)

export const selectIsVisible = (customerFilter: CustomerFilterEnum, withDirector: boolean, withoutDirector: boolean, isTopNode: boolean) => {
    if (isTopNode || customerFilter === CustomerFilterEnum.All)
        return true;

    return customerFilter === CustomerFilterEnum.WithScreens
        ? withDirector
        : withoutDirector;
}
