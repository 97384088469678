import { ContainerStatus } from "@src/lib/types/container-status"
import { CustomerChain } from "@states/customer-chain/customer-chain-types"
import { RootState } from "@states/state"
import { AppDispatch } from "@states/store"
import * as React from "react"
import { connect } from "react-redux"
import FA from "@src/components/fa"
import Customer from "./customer"

interface StateProps {
    chain: CustomerChain
}

interface OwnProps {
}

interface DispatchProps {
}

type Props = StateProps & OwnProps & DispatchProps;

const makeMapStateToProps = () => {
    return (state: RootState, props: OwnProps) => {
        const chainId = state.customerTree.selectedChainId;
        const chain = state.customerChain[chainId]!;
        return { chain };
    }
}

const mapDispatchToProps = (dispatch: AppDispatch, props: OwnProps) => ({

})

const Chain = (props: Props) => {

    let loadingStatus = null;
    if (props.chain.status === ContainerStatus.Requested || props.chain.status === ContainerStatus.Loading)
        loadingStatus = (<div className="tree-list__status">
            <FA icon="spinner" size={"3x"} spin={true} />
        </div>)
    else if (props.chain.status === ContainerStatus.Error) {
        loadingStatus = (<div className="tree-list__status tree-list__status--error">
            {props.chain.statusText.length > 0 ? props.chain.statusText : "Error"}
        </div>)
    }

    return (
        <>
            <div className="tree-list">
                {props.chain.customers.map(id =>
                    <Customer key={id} id={id} depth={0} />
                )}
                {loadingStatus}
            </div>
        </>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(Chain)
