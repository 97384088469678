import * as React from "react"
import { MouseEventHandler, ReactNode } from "react"

interface OwnProps {
    /** Enabled if true or undefined, otherwise disabled. */
    enabled?: boolean,
    icon?: JSX.Element,
    spacer?: boolean,
    onClick?: MouseEventHandler<HTMLButtonElement>,
    children?: ReactNode
}

export const DeviceButton = (props: OwnProps) => {
    const disabled = props.enabled === false

    const content: ReactNode = <>
        {props.icon}
        {props.children}
    </>

    let className = "secondary radius device__button"

    if (props.spacer)
        className += " device__button--spacer"

    if (props.icon)
        className += " device__button--has-icon"

    return <button
        className={className}
        disabled={disabled}
        onClick={props.onClick}>
        {content}
    </button>
}