import * as React from 'react';
import { RootState } from "@states/state";
import { AppDispatch } from '@states/store';
import { connect, Provider } from 'react-redux';
import { actions, selectMessages, InfoMessageReminderStatus } from '@states/info-message';
import ReminderStrip from './components/reminder-strip';
import { selectHasReminder, createMemoizedMessage } from './info-message-box-selectors';
import { createSelector, Store } from '@reduxjs/toolkit';
import { selectIsTextDirty } from '@states/info-message/info-message-selectors';
import { selectCustomerId } from '../customer-tree/customer-tree-selectors';
import { selectCurrentUserRoles } from '../../states/user';
import { selectHasRole } from '../../states/user/user-selectors';
import * as ReactDOM from 'react-dom';
import { Target, TargetType } from '../../lib/targetable';

interface StateProps {
    status: string,
    text: string,
    textAreaClassnames: string,
    reminderDate: string,
    reminderStatus: string,
    displayDebug: boolean,
    customerId: number,
    remindersVisible: boolean
}

interface DispatchProps {
    onTextAreaChange: (e: React.ChangeEvent<HTMLTextAreaElement>, customerId: number) => void
}

interface OwnProps {
    target: Target
}

type Props = StateProps & DispatchProps & OwnProps;

const makeMapStateToProps = () => {

    const memoizedMessage = createMemoizedMessage();
    const memoizedIsTextDirty = createSelector([memoizedMessage], selectIsTextDirty);

    const memoizedSelectStatus = createSelector(
        [selectMessages, memoizedMessage],
        (messages, message) => {
            const isEmptyMessage = messages.indexOf(message) == -1;
            return isEmptyMessage ? "doesn't exist yet" : message.underlying!.id == null ? "draft" : message.underlying!.id.toString();
        });

    const memoizedText = createSelector([memoizedMessage], (message) => message.current!.text);

    const memoizedTextAreaClassnames = createSelector([memoizedIsTextDirty], (dirty) => {
        return "input" + (dirty ? " tracked-dirty" : "");
    });

    const memoizedHasReminder = createSelector([memoizedMessage], selectHasReminder);

    const memoizedReminderDate = createSelector(
        [memoizedHasReminder, memoizedMessage],
        (hasReminder, message) => hasReminder ? message.current!.reminder!.date : ""
    );

    const memoizedReminderStatusDebug = createSelector([memoizedHasReminder, memoizedMessage],
        (hasReminder, message) =>
            hasReminder ? InfoMessageReminderStatus[message.current!.reminder!.status] : "Not Set");

    const selectReminderVisible = createSelector([selectCurrentUserRoles],
        (roles) => selectHasRole(roles, 'LoginAsAnyCustomer'));

    return function mapStateToProps(state: RootState, props: OwnProps): StateProps {
        return ({
            status: memoizedSelectStatus(state, props),
            text: memoizedText(state, props),
            textAreaClassnames: memoizedTextAreaClassnames(state, props),

            reminderDate: memoizedReminderDate(state, props),
            reminderStatus: memoizedReminderStatusDebug(state, props),
            displayDebug: state.user.debug,
            customerId: selectCustomerId(state),
            remindersVisible: selectReminderVisible(state.user)
        });
    }
}

const mapDispatchToProps = (dispatch: AppDispatch, props: OwnProps): DispatchProps => ({
    onTextAreaChange: (e, customerId: number) => {
        dispatch(actions.setText({ text: e.target.value, target: props.target, customerId }));
    },
});

const DisconnectedInfoMessageBox = (props: Props) => {
    const textAreaOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => { props.onTextAreaChange(e, props.customerId) };
    return <div className="info-message">
        {props.remindersVisible &&
            <>
                <ReminderStrip target={props.target} />
                <div className="textarea" style={{ position: "relative" }}>
                    <textarea className={props.textAreaClassnames} id="infobox-textarea" rows={4} cols={50} value={props.text} onChange={textAreaOnChange} />

                    {props.displayDebug &&
                        <pre style={{ position: "absolute", left: "0.5em", bottom: "0.5em", fontSize: ".7rem", color: "#bdcfef", userSelect: "none", pointerEvents: "none" }}>
                            id: {props.status}<br />
                            target: {props.target.id} ({TargetType[props.target.type]})<br />
                            reminder: {props.reminderDate}, status: {props.reminderStatus}<br />
                            text: <span style={{ fontSize: ".5rem" }}>{props.text}</span>
                        </pre>
                    }
                </div>
            </>
        }
    </div>
}

export const InfoMessageBox = connect(makeMapStateToProps, mapDispatchToProps)(DisconnectedInfoMessageBox);

export const renderInfoMessageBox = (target: Target, store: Store<RootState>, root: Element | null) => {
    if (!root) return void 0
    return ReactDOM.render(
        <Provider store={store}>
            <InfoMessageBox target={target} />
        </Provider>,
        root
    )
}