import * as React from "react";
import { connect } from "react-redux";
import { createSelector, Dispatch } from "@reduxjs/toolkit";
import { RootState } from "../../states/state";
import { InfoMessageStatusIcon } from "@features/info-message-status-icon";
import { selectCurrentCustomer } from "../../states/customer/customer-selectors";
import { actions as customerTreeActions } from "../customer-tree/customer-tree-slice";
import { Tab, Tabs } from "../../components/tab";
import { selectTabState } from "./page-tabs-selectors";
import { selectHasAnyRole, selectCurrentUserRoles } from "../../states/user/user-selectors";
import { TargetType } from "../../lib/targetable";

const memoizedShineVisible = createSelector([selectCurrentUserRoles], (roles) => selectHasAnyRole(roles, 'LoginAsAnyCustomer', 'SunFlowChannelTree'));

const mapStateToProps = (state: RootState) => {
    const customer = selectCurrentCustomer(state)
    const hasChildren = customer.children.length > 0

    return ({
        customer,
        statusIconTarget: {
            id: customer.customerId,
            type: TargetType.Customer
        },
        chainOverviewState: selectTabState(state, "ChainOverview", !hasChildren),
        storeOverviewState: selectTabState(state, "StoreOverview"),
        screensState: selectTabState(state, "Overview"),
        channelsState: selectTabState(state, "Channels", false, !memoizedShineVisible(state.user)),

        //brokenState: selectTabState(state, "Broken", false, true),
        //integratorState: selectTabState(state, "Integrator", false, true),
        //setupState: selectTabState(state, "Setup", false, true),
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onChainOverview: () => dispatch(customerTreeActions.selectPageTab("ChainOverview")),
    onStoreOverview: () => dispatch(customerTreeActions.selectPageTab("StoreOverview")),
    onOverview: () => dispatch(customerTreeActions.selectPageTab("Overview")),
    onChannels: () => dispatch(customerTreeActions.selectPageTab("Channels")),
})

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps

const PageTabs = (props: Props) => {
    return (
        <>
            <div className="customer-title-bar">
                <h1>{props.customer.name}</h1>
                <div className="info-message-status-icon-root">
                    <InfoMessageStatusIcon target={props.statusIconTarget} />
                </div>
            </div>

            <Tabs className="main-page-menu">
                <Tab text="Chain Overview" state={props.chainOverviewState} onClick={props.onChainOverview} />
                <Tab text="Channels" state={props.channelsState} onClick={props.onChannels} />
                <Tab text="Store Overview" state={props.storeOverviewState} onClick={props.onStoreOverview} />
                <Tab text="Screens" state={props.screensState} onClick={props.onOverview} />

                {/*
                <Tab text="Broken devices" state={props.brokenState} />
                <Tab text="Integrator" state={props.integratorState} />
                <Tab text="Register devices" state={props.setupState} />
                 */}
            </Tabs>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTabs)
