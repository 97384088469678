import FA from "@src/components/fa"
import * as React from "react"
import { useScrollIntoView } from "../../../lib/useScrollIntoView"
import { Customer } from "../../../states/customer/customer-types"

type Props = {
    customer: Customer,
    onClick: (id: number) => void,
    isSelected: boolean,
    isActive: boolean
}

export function ChainItem(props: Props) {
    const [element] = useScrollIntoView<HTMLDivElement>(props.isSelected);

    let className = "chain-list__item"
    if (props.isSelected) className += " chain-list__item--selected"
    if (props.isActive) className += " chain-list__item--active"

    const onSelect = () => props.onClick(props.customer.customerId);

    const alarms = props.customer.counters?.alarms || 0
    const acks = props.customer.counters?.acks || 0

    return (
        <div className={className} ref={element} onClick={onSelect}>
            <div>{props.customer.name}</div>
            <div className={"label label-small " + (alarms ? "label-alert" : "disabled")}>{alarms}</div>
            <div className={"label label-small " + (acks ? "label-info" : "disabled")}>{acks}</div>
            <FA
                icon="desktop"
                fixedWidth={true}
                className={props.customer.containsDirectors === true ? "" : "disabled"}
            />
        </div>
    );
}
