import SunFlowMain from "./sunflow-main";
import MainPage from "./main-page";
import Setup from "./setup";
import { Guid } from "@src/lib/interfaces";

var SetupEditProjectors =
{
    loadedProjectorIds: <string[]>[],
    clickingOnOrientation: false,
    clickingOnChannel: false,
    loadProjectors: function (projectorsToLoad: string[]) {
        if (SunFlowMain.arraysEqual(projectorsToLoad, SetupEditProjectors.loadedProjectorIds)) {
            return;
        }
        SetupEditProjectors.clear();
        SetupEditProjectors.unbindEvents();
        SetupEditProjectors.loadedProjectorIds = projectorsToLoad;
        var $target = $('.setup-edit-projectors-view');
        var $fadeTarget = $('#setup-edit-projector');

        var projectorName: string,
            requireName: boolean,
            projectorOrientation;

        if (projectorsToLoad.length === 1) {
            var $projectorElement = $('.setup-row-selected');
            projectorName = $projectorElement.attr('data-projector-name') as string;
            projectorOrientation = $projectorElement.attr('data-projector-orientation');
            requireName = SunFlowMain.isNullOrWhitespace(projectorName);
        }
        else {
            requireName = false;
            $(".setup-row input[type='checkbox']:checked").each(function (i, item) {
                if (SunFlowMain.isNullOrWhitespace($(item).closest('.setup-row').attr('data-projector-name')!)) {
                    requireName = true
                    //return false;
                }
            });
            projectorName = '';
            projectorOrientation = 0;
        }

        if (projectorsToLoad.length > 0) {
            MainPage.fadeInOverlay();
            $.ajax({
                url: "/Setup/EditProjectors",
                type: "POST",
                data: JSON.stringify({ 'customerId': Setup.loadedCustomerId, 'projectorIds': projectorsToLoad, 'name': projectorName, 'orientation': projectorOrientation, 'requireName': requireName }),
                contentType: "application/json; charset=utf-8",
                success: function (result) {
                    if ($fadeTarget.hasClass('hidden')) {
                        $fadeTarget.removeClass('hidden');
                    }
                    $target.html(result);
                    SetupEditProjectors.initialize();
                    SetupEditProjectors.scrollToSelectedChannel();
                    MainPage.checkIfLoggedInAfterAjax($target);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    alert("An error has occured in SetupEditProjectors.loadProjectors()");
                    window.location.reload();
                },
                complete: function () {
                    MainPage.fadeOutOverlay();
                }
            });
        }
    },
    initialize: function () {
        SetupEditProjectors.clearSelectedChannel();
        if (SetupEditProjectors.loadedProjectorIds.length === 1) {
            var channelGuid = $('.setup-row-selected').attr('data-channel-guid');
            var $channelToSelect = $(".setup-channel-item[data-channel-guid='" + channelGuid + "']");
            if ($channelToSelect.length) {
                $channelToSelect.removeClass('setup-channel-item-normal');
                $channelToSelect.addClass('setup-channel-item-selected');
            }
        }
        SetupEditProjectors.bindEvents();
    },
    scrollToSelectedChannel: function () {
        var $container = $('.setup-channel-trunk'),
            $scrollTo = $('.setup-channel-item-selected');
        SunFlowMain.scrollToSelectedItem($container, $scrollTo);
    },
    bindEvents: function () {
        $('.setup-orientation-button').on('mousedown', function (e) {
            if (e.which === 1) {
                var $tab = $(this);
                if ($tab.hasClass('setup-orientation-button-selected')) {
                    return;
                }
                else {
                    SetupEditProjectors.clickingOnOrientation = true;
                    $tab.addClass('setup-orientation-button-clicking');
                    $tab.removeClass('setup-orientation-button-normal');
                }
            }
        });
        $('.setup-orientation-button').on('mouseup', function (e) {
            if (SetupEditProjectors.clickingOnOrientation) {
                SetupEditProjectors.clickingOnOrientation = false;

                SetupEditProjectors.clearSelectedOrientation();
                var $tab = $(this);
                $tab.addClass('setup-orientation-button-selected');
                $tab.removeClass('setup-orientation-button-clicking');
            }
        });
        $('.setup-orientation-button').on('mouseleave', function (e) {
            if (SetupEditProjectors.clickingOnOrientation) {
                SetupEditProjectors.clickingOnOrientation = false;
                var $tab = $(this);
                $tab.removeClass('setup-orientation-button-clicking');
                $tab.addClass('setup-orientation-button-normal');
            }
        });

        $('.setup-channel-item').on('mousedown', function (e) {
            if (e.which === 1) {
                var $tab = $(this);
                if ($tab.hasClass('setup-channel-item-selected')) {
                    return;
                }
                else {
                    SetupEditProjectors.clickingOnChannel = true;
                    $tab.addClass('setup-channel-item-clicking');
                    $tab.removeClass('setup-channel-item-normal');
                }
            }
        });
        $('.setup-channel-item').on('mouseup', function (e) {
            if (SetupEditProjectors.clickingOnChannel) {
                SetupEditProjectors.clickingOnChannel = false;

                SetupEditProjectors.clearSelectedChannel();
                var $tab = $(this);
                $tab.addClass('setup-channel-item-selected');
                $tab.removeClass('setup-channel-item-clicking');
            }
        });
        $('.setup-channel-item').on('mouseleave', function (e) {
            if (SetupEditProjectors.clickingOnChannel) {
                SetupEditProjectors.clickingOnChannel = false;
                var $tab = $(this);
                $tab.removeClass('setup-channel-item-clicking');
                $tab.addClass('setup-channel-item-normal');
            }
        });
        $('#setup-edit-cancel-button').on('click', function (e) {
            SetupEditProjectors.closeAndClearSelectedProjectors();
        });
        $('#setup-edit-delete-button').on('click', function (e) {
            SetupEditProjectors.deleteProjectors(SetupEditProjectors.loadedProjectorIds);
            SetupEditProjectors.closeAndClearSelectedProjectors();
            Setup.reloadProjectorList();
        });
        $('#setup-edit-save-button').on('click', function (e) {

            var $nameElem = $('#setup-projector-name');
            var name = $nameElem.val() as string;
            if ($nameElem.attr('data-name-required') === 'True' && SunFlowMain.isNullOrWhitespace(name)) {
                $nameElem.effect('highlight');
                $nameElem.focus();
                $('#setup-input-validation').text('Please enter a name!');
            }
            else {
                var $selectedOrientationElem = $('.setup-orientation-button-selected');
                var selectedOrientation: number;
                if ($selectedOrientationElem.length) {
                    selectedOrientation = +<string>$selectedOrientationElem.attr('data-orientation-id'); // todo get value stored in html
                }
                else {
                    selectedOrientation = -1;
                }

                var $selectedChannelElem = $('.setup-channel-item-selected'),
                    selectedChannel: Guid;
                if ($selectedChannelElem.length) {
                    selectedChannel = <string>$selectedChannelElem.attr('data-channel-guid');
                }
                else {
                    selectedChannel = -1;
                }

                SetupEditProjectors.saveProjectorChanges(name, selectedOrientation, selectedChannel, SetupEditProjectors.loadedProjectorIds);
                SetupEditProjectors.closeAndClearSelectedProjectors();
                Setup.reloadProjectorList();
            }
        });
    },
    saveProjectorChanges: function (namePattern: string, orientationToSave: number, channelIdToSave: Guid, projectorsToSave: string[]) {
        $.ajax({
            url: '/Setup/SaveProjectorChanges',
            type: "POST",
            traditional: true,
            async: false,
            data: { 'customerId': Setup.loadedCustomerId, 'namePattern': namePattern, 'orientationId': orientationToSave, 'channelId': channelIdToSave, 'projectorIds': projectorsToSave },
            success: function (result) {
                if (result === 'True') {

                }
                else if (result === "False") {
                    alert('failed to save projectorChanges');
                    window.location.reload();
                }
                else {
                    window.location.reload();
                }
            },
            error: function () {
                alert("An error has occured!!!");
                window.location.reload();
            }
        });
    },
    deleteProjectors: function (projectorsToDelete: string[]) {
        $.ajax({
            url: '/Setup/DeleteProjectors',
            type: "POST",
            traditional: true,
            async: false,
            data: { 'customerId': Setup.loadedCustomerId, 'projectorIds': projectorsToDelete },
            success: function (result) {
                if (result === 'True') {

                }
                else if (result === "False") {
                    alert('failed to save projectorChanges');
                    window.location.reload();
                }
                else {
                    window.location.reload();
                }
            },
            error: function () {
                alert("An error has occured!!!");
                window.location.reload();
            }
        });
    },
    closeAndClearSelectedProjectors: function () {
        SetupEditProjectors.loadProjectors([]);
        Setup.clearSelected();
        Setup.clearChecked();
    },

    clearSelectedOrientation: function () {
        var $oldTab = $('.setup-orientation-button-selected');
        if ($oldTab.length) {
            $oldTab.removeClass('setup-orientation-button-selected');
            $oldTab.addClass('setup-orientation-button-normal');
        }
    },
    clearSelectedChannel: function () {
        var $oldTab = $('.setup-channel-item-selected');
        if ($oldTab.length) {
            $oldTab.removeClass('setup-channel-item-selected');
            $oldTab.addClass('setup-channel-item-normal');
        }
    },
    unbindEvents: function () {
        $('.setup-orientation-button').unbind('mousedown');
        $('.setup-orientation-button').unbind('mouseup');
        $('.setup-orientation-button').unbind('mouseleave');
        $('.setup-channel-item').unbind('mousedown');
        $('.setup-channel-item').unbind('mouseup');
        $('.setup-channel-item').unbind('mouseleave');
        $('#setup-edit-cancel-button').unbind('click');
        $('#setup-edit-delete-button').unbind('click');
        $('#setup-edit-save-button').unbind('click');
    },
    clear: function () {
        SetupEditProjectors.loadedProjectorIds = [];
        SetupEditProjectors.clickingOnOrientation = false;
    }
};

export default SetupEditProjectors;