import { createAsyncThunk, unwrapResult } from "@reduxjs/toolkit"
import { fetchAcknowledges, fetchAlarmOverview, fetchAlarms } from "@states/alarm/alarm-thunks"
import { Acknowledge } from "@states/alarm/alarm-types"
import { actions as chainActions } from "@states/customer-chain/customer-chain-slice"
import { fetchCustomerIdsForChain } from "@states/customer/customer-thunks"
import { fetchInfoMessages } from "@states/info-message"
import { fetchInfoMessagesForChain } from "@states/info-message/info-message-thunks"
import { RootState } from "@states/state"
import { ThunkArgs } from "@states/state-types"
import { selectCurrentUserName } from "@states/user"
import { selectUserName } from "@states/user/user-selectors"
import { fetchUserNames } from "@states/user/user-thunks"
import Cookies from "universal-cookie/cjs"
import CustomerTree from "./customer-tree"
import { actions as customerActions } from "./customer-tree-slice"
import { COOKIE_CHAIN_ID_KEY, COOKIE_CUSTOMER_ID_KEY } from "./customer-tree-types"

export const setCustomerIdWithSideEffects = createAsyncThunk<void, number, ThunkArgs>(
    'customerTree/setCustomerIdWithSideEffects',
    async (customerId: number, thunk) => {
        if (thunk.getState().customerTree.selectedCustomerId !== customerId) {
            thunk.dispatch(fetchInfoMessages(customerId))
            thunk.dispatch(fetchAlarms({ customerId, clear: 'customer' }))

            thunk
                .dispatch(fetchAcknowledges({ customerId, clear: 'customer' }))
                .then(unwrapResult)
                .then(acks => thunk.dispatch(fetchUserNamesForAcknowledges(thunk.getState(), acks)))

            const cookies = new Cookies()
            cookies.set(selectCurrentUserName(thunk.getState().user) + COOKIE_CUSTOMER_ID_KEY, customerId)

            thunk.dispatch(customerActions.setCustomerId(customerId))
        }
    })

export const setChainWithSideEffects = createAsyncThunk<void, number, ThunkArgs>(
    'customerTree/setChainWithSideEffects',
    async (chainId, thunk) => {
        const priorId = thunk.getState().customerTree.selectedChainId
        thunk.dispatch(customerActions.setChain(chainId))

        if (priorId !== chainId) {
            thunk.dispatch(fetchInfoMessagesForChain(chainId))
            thunk.dispatch(fetchAlarms({ customerId: chainId, clear: 'all' }))

            thunk
                .dispatch(fetchAcknowledges({ customerId: chainId, clear: 'all' }))
                .then(unwrapResult)
                .then(acks => thunk.dispatch(fetchUserNamesForAcknowledges(thunk.getState(), acks)))

            thunk.dispatch(fetchAlarmOverview({ chainId }))

            const cookies = new Cookies()
            cookies.set(selectCurrentUserName(thunk.getState().user) + COOKIE_CHAIN_ID_KEY, chainId)

            thunk.dispatch(fetchCustomerIdsForChain())

            thunk.dispatch(chainActions.clearChainInfo(priorId))
        }
    })

export const setCustomerIdOnLegacyDOM = createAsyncThunk<void, number>(
    'customerTree/setCustomerIdOnLegacyDOM',
    async (customerId: number, _) => {
        CustomerTree.selectCustomerById(customerId)
    })

/**
 * Fetches the usernames of users associated with the specified acknowledges.
 * 
 * Users whose username already exist on the client will not be fetched.
 */
const fetchUserNamesForAcknowledges = (state: RootState, acks: Acknowledge[]) => {
    let userIds = acks.map(ack => ack.acknowledgedBy)
    userIds = Array.from(new Set(userIds)) // distinct ids
    userIds = userIds.filter(id => selectUserName(state.user, id).length === 0)

    return fetchUserNames(userIds)
}
    