import { DateTimeString, toDateTime } from "@src/lib/date-string"

export type TimeStamp = {
    createdAt: DateTimeString
    expiresAt?: DateTimeString
}

export const createTimeStamp = (expiresInMs: number): TimeStamp => {
    const now = new Date()
    const expiry = new Date()
    expiry.setMilliseconds(expiry.getMilliseconds() + expiresInMs)

    return {
        createdAt: toDateTime(now),
        expiresAt: toDateTime(expiry)
    }
}

export const isExpired = (time: TimeStamp): boolean => {
    if (!time.expiresAt)
        return false

    const nowDate = new Date()
    const expiryDate = new Date(time.expiresAt)

    return nowDate > expiryDate
}