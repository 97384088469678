import { MultiProjectorInfo } from "@features/device-details/components/device-info/multi-projector-info"
import { SingleDeviceInfo } from "@features/device-details/components/device-info/single-device-info"
import * as React from "react"
import { useMemo } from "react"

interface Props {
    device: 'director' | {projectorMac: string}[],
    customerId: number
}

export const DeviceInfo = (props: Props) => {
    const infoContent = useMemo(() => createInfoContent(props.device, props.customerId), [props.device, props.customerId])

    return <div id="device-info">
        {infoContent}
    </div>
}

const createInfoContent = (target: 'director' | {projectorMac: string}[], customerId: number) => {
    if (Array.isArray(target) && target.length > 1)
        return <MultiProjectorInfo projectorMacs={target.map(p => p.projectorMac)}/>

    const device = Array.isArray(target)
        ? target[0]
        : target

    return <SingleDeviceInfo device={device} customerId={customerId} />
}