import { ContainerStatus } from "@src/lib/types/container-status"
import { RootState } from "@states/state"
import { Customer } from "./customer-types"

export const UNKNOWN_CUSTOMER: Customer = {
    name: "Unknown",
    customerId: -1,
    chainId: -1,
    parentId: -1,
    children: [],
    infoMessages: [],
    status: ContainerStatus.Loaded
}

export const selectCustomer = (state: RootState, id: number) => state.customer[id] ?? UNKNOWN_CUSTOMER
export const selectCurrentCustomer = (state: RootState) => selectCustomer(state, state.customerTree.selectedCustomerId)

export const selectCustomersInChain = (state: RootState, chainId: number) =>
    Object.keys(state.customer)
        .map(id => state.customer[id]!)
        .filter(c => c.chainId === chainId)

export const selectChainForCustomer = (state: RootState, customerId: number): Customer | null => {
    const customer = state.customer[customerId] || null
    if (customer && (customer.parentId ?? 0) > 0) {
        return selectChainForCustomer(state, customer.parentId!)
    }
    return customer
}

export const selectCustomersInCurrentChain = (state: RootState) => selectCustomersInChain(state, state.customerTree.selectedChainId)