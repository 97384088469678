import { DateTimeString } from "@src/lib/date-string"
import { Targetable, TargetType } from "@src/lib/targetable"
import { Dictionary, Tracked } from "mummet-core/dist/types"

export const MAX_ACKNOWLEDGE_LENGTH_IN_DAYS = 3 * 30
export const MIN_ACKNOWLEDGE_LENGTH_IN_MINUTES = 60

export const DEFAULT_ACKNOWLEDGE_LENGTH_IN_HOURS = 24

export interface AlarmState {
    alarms: AlarmDictionary
    acknowledges: AcknowledgeDictionary
    overview: AlarmOverview
    nextAcknowledgeDraftId: number
}

export interface ServerAlarm {
    id: number,
    customerId: number,
    targetId: number,
    targetType: TargetType,
    type: AlarmType,
    createdAt: string,
    resolvedAt?: string | null
}

export interface ServerAcknowledge {
    id: number,
    customerId: number,
    targetId: number,
    targetType: TargetType,
    alarmType: AlarmType,
    acknowledgedAt: string,
    acknowledgedUntil: string,
    acknowledgedBy: number,
    clearWhenResolved: boolean,
    reason: string
}

export type ServerAlarmOverview = {
    customers: {
        id: number,
        entries: {
            alarmId: number,
            acknowledgeId?: number | null
        }[]
    }[]
}

export type AlarmOverview = Dictionary<AlarmToAcknowledgeDictionary>

export interface InactiveAlarm extends Targetable {
    type: AlarmType
}

export interface Alarm extends InactiveAlarm {
    id: number
    customerId: number

    createdAt: DateTimeString
    /** When the alarm was resolved, or null if not resolved. */
    resolvedAt: DateTimeString | null
    /** When the alarm was triggered, or null if not triggered. */
    triggeredAt: DateTimeString | null
}

export interface Acknowledge extends InactiveAlarm {
    id: number
    customerId: number

    /** When the acknowledge was created on the server, or null if creation time is pending. */
    acknowledgedAt: DateTimeString | null

    /** When the acknowledge expires. */
    acknowledgedUntil: DateTimeString

    /** The id of the user who created this acknowledge. */
    acknowledgedBy: number

    clearWhenResolved: boolean

    reason: string
}

export type AlarmDictionary = Dictionary<Alarm>
export type AcknowledgeDictionary = Dictionary<Tracked<Acknowledge>>
export type AlarmToAcknowledgeDictionary = Dictionary<number | null>

export enum AlarmType {
    Offline = 0,
    //NoMaterial = 1
}