import { delay } from "@src/lib/async-util"

export enum ResultCode {
    Ready = 0,
    Aborted = -3,
    CancelledByUser = -4
}

export default class Screenshot {
    public static load(customerId: number, projectorId: string, maxImageWidth: number): Promise<number | ResultCode> {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/Projector/PrepareScreenshot',
                type: "POST",
                dataType: "text",
                data: { customerId, projectorId, maxImageWidth },
                success: function (result) {
                    if (result !== '' && !isNaN(result)) {
                        let timeToPicture = parseInt(result, 10)
                        resolve(timeToPicture)
                    }
                    else {
                        resolve(ResultCode.Aborted)
                    }
                },
                error: function () {
                    resolve(ResultCode.Aborted)
                }
            })
        })
    }

    public static async checkAvailable(projectorId: string, customerId: number, imageWidth: number, cancelCheck: () => boolean) {
        try {
            let resultCodeOrWaitTime = await Screenshot.load(customerId, projectorId, imageWidth)

            while (resultCodeOrWaitTime > 0) {
                await delay(resultCodeOrWaitTime * 1000, {cancelCheck})
                resultCodeOrWaitTime = await Screenshot.checkAvailable(projectorId, customerId, imageWidth, cancelCheck)
            }

            return resultCodeOrWaitTime as ResultCode

        } catch (e) {
            if (cancelCheck()) {
                return ResultCode.CancelledByUser
            }

            throw e
        }
    }
}