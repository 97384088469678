import { createAsyncThunk } from "@reduxjs/toolkit"
import { ContainerStatus } from "@src/lib/types/container-status"
import xhr from "@src/lib/xhr"
import { loadChainContainerAsync } from "@states/customer-chain/customer-chain-thunks"
import { ServerCustomer } from "@states/customer/customer-types"
import { loadChainActionsAsync } from "@states/device-event/device-event-thunks"
import { ThunkArgs } from "@states/state-types"

export const fetchMissingCustomerIds = createAsyncThunk<ServerCustomer[], number[], ThunkArgs
>('/customer/getMissingCustomer',
    async (customerIds: number[]) => {

        if (customerIds.length === 0)
            return []

        const url = `/Customer/GetCustomers?${customerIds.map((id, i) => `ids[${i}]=${id}`).join('&')}`
        const { data: items } = await xhr.get<ServerCustomer[]>(url)
        return items
    })

export const fetchCustomerIdsForChain = createAsyncThunk<void, void, ThunkArgs
>('/customer/fetchCustomersForChain',
    async (_, thunkApi) => {
        const chainId = thunkApi.getState().customerTree.selectedChainId
        const chain = thunkApi.getState().customerChain[chainId]

        if (chain!.status === ContainerStatus.Requested) {
            await Promise.all([
                loadChainContainerAsync(chain!, thunkApi.dispatch),
                loadChainActionsAsync(chain!, thunkApi.dispatch)
            ])
        }
    })