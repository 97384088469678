import { DateTimeString, toDateTime } from "@src/lib/date-string"
import { Tracked } from "mummet-core/dist/types"
import { dictionaryToArray, filterDictionary } from "../../lib/dictionary-util"
import { areTargetsEqual, Target } from "../../lib/targetable"
import { RootState } from "../state"
import {
    Acknowledge,
    AcknowledgeDictionary,
    Alarm,
    AlarmDictionary,
    AlarmOverview,
    AlarmState,
    AlarmType,
    DEFAULT_ACKNOWLEDGE_LENGTH_IN_HOURS,
    InactiveAlarm
} from "./alarm-types"

export const selectAlarm = (alarms: AlarmDictionary, id: number): Alarm | undefined => alarms[id];
export const selectAcknowledge = (acknowledges: AcknowledgeDictionary, id: number): Tracked<Acknowledge> | undefined => acknowledges[id];

export const selectAlarms = (state: RootState) => state.alarm.alarms;
export const selectAcknowledges = (state: RootState) => state.alarm.acknowledges;

export const selectNextDraftId = (state: AlarmState) => state.nextAcknowledgeDraftId;

export const selectIsAcknowledgeDraft = (acknowledge: Tracked<Acknowledge>) => (acknowledge.current?.id ?? 0) < 0 || acknowledge.underlying === null

/** Selects alarms that at specified date are triggered and have not been resolved */
export const selectActiveAlarms = (alarms: AlarmDictionary, date?: Date) => {
    const d = date ?? new Date();

    return filterDictionary(
        selectOpenAlarms(alarms, d),
        alarm => isAlarmTriggered(alarm, d));
}

/** Selects alarms that at the specified date have not been resolved */
export const selectOpenAlarms = (alarms: AlarmDictionary, date?: Date) =>
    filterDictionary(alarms, alarm =>
        !isAlarmResolved(alarm, date ?? new Date()))

/** Selects alarms who's target matches the specified target */
export const selectAlarmsForTarget = (alarms: AlarmDictionary, target: Target) =>
    filterDictionary(alarms, alarm => areTargetsEqual(alarm.target, target));

/** Selects acknowledges that are open at the specified date */
export const selectOpenAcknowledges = (acknowledges: AcknowledgeDictionary, date?: Date) =>
    filterDictionary(acknowledges, ack => isAcknowledgeOpen(ack, date ?? new Date()))

/** Selects all alarm-types that are not present on any alarms in the specified dictionary */
export const selectMissingAlarmTypes = (alarms: AlarmDictionary): AlarmType[] => {
    const missingTypes = allAlarmTypes();

    Object.keys(alarms)
        .map(key => alarms[+key])
        .forEach(alarm => {
            const indexOfType = missingTypes.indexOf(alarm.type as AlarmType);

            if (indexOfType > -1)
                missingTypes.splice(indexOfType, 1);
        }
    )

    return missingTypes;
}

/** Selects the latest acknowledge that targets the specified active alarm */
export const selectAcknowledgeForAlarm = (alarm: Alarm, acknowledges: AcknowledgeDictionary): Tracked<Acknowledge> | null => {
    let acks = getAcknowledgesSortedByUntilDate(acknowledges);

    acks = acks.filter(ack => isAcknowledgeForAlarm(alarm, ack));

    return acks.length > 0 ? acks[0] : null;
}

/** Selects the latest acknowledge that targets the specified inactive alarm */
export const selectAcknowledgeForInactiveAlarm = (alarm: InactiveAlarm, acknowledges: AcknowledgeDictionary): Tracked<Acknowledge> | null => {
    let acks = getAcknowledgesSortedByUntilDate(acknowledges);

    acks = acks.filter(ack => isAcknowledgeForInactiveAlarm(alarm, ack));

    return acks.length > 0 ? acks[0] : null;
}

/** Selects all acknowledges that have unsaved changes */
export const selectDirtyAcknowledges = (acknowledges: AcknowledgeDictionary): Tracked<Acknowledge>[] =>
    dictionaryToArray(acknowledges)
        .filter(selectIsAcknowledgeDirty)

export const selectIsAcknowledgeDirty = (acknowledge: Tracked<Acknowledge>) =>
    selectIsAcknowledgeReasonDirty(acknowledge) ||
    selectIsAcknowledgeUntilDirty(acknowledge)

export const selectIsAcknowledgeReasonDirty = (acknowledge: Tracked<Acknowledge>) =>
    acknowledge.current?.reason !== acknowledge.underlying?.reason

export const selectIsAcknowledgeUntilDirty = (acknowledge: Tracked<Acknowledge>) =>
    acknowledge.current?.acknowledgedUntil !== acknowledge.underlying?.acknowledgedUntil ||
    acknowledge.current?.clearWhenResolved !== acknowledge.underlying?.clearWhenResolved

export const selectUnacknowledgedAlarmCountForCustomer = (overview: AlarmOverview, customerId: number) => {
    if (!(customerId in overview))
        return 0

    const alarmsWithoutAcknowledges = filterDictionary(overview[customerId], (ackId) => ackId === null)

    return Object.keys(alarmsWithoutAcknowledges).length
}

export const selectAcknowledgedAlarmCountForCustomer = (overview: AlarmOverview, customerId: number) => {
    if (!(customerId in overview))
        return 0

    const acknowledgedAlarms = filterDictionary(overview[customerId], (ackId) => ackId !== null)

    return Object.keys(acknowledgedAlarms).length
}

export const selectUnacknowledgedAlarmCountForTarget = (overview: AlarmOverview, alarms: AlarmDictionary, customerId: number, target: Target) => {
    if (!(customerId in overview))
        return 0

    const alarmsWithoutAcknowledges = filterDictionary(overview[customerId], (ackId) => ackId === null)
    const alarmIdsWithoutAcknowledges = Object.keys(alarmsWithoutAcknowledges).map(Number)

    const alarmsForTarget = selectAlarmsForTarget(alarms, target)
    const alarmsForTargetWithoutAcknowleges = filterDictionary(alarmsForTarget, alarm => alarmIdsWithoutAcknowledges.includes(alarm.id))

    return Object.keys(alarmsForTargetWithoutAcknowleges).length
}

export const selectAcknowledgedAlarmCountForTarget = (overview: AlarmOverview, alarms: AlarmDictionary, customerId: number, target: Target) => {
    if (!(customerId in overview))
        return 0

    const alarmsWithAcknowledges = filterDictionary(overview[customerId], (ackId) => ackId !== null)
    const alarmIdsWithAcknowledges = Object.keys(alarmsWithAcknowledges).map(Number)

    const alarmsForTarget = selectAlarmsForTarget(alarms, target)
    const alarmsForTargetWithAcknowleges = filterDictionary(alarmsForTarget, alarm => alarmIdsWithAcknowledges.includes(alarm.id))

    return Object.keys(alarmsForTargetWithAcknowleges).length
}

export const allAlarmTypes = () => Object
    .keys(AlarmType)
    .map(key => AlarmType[key as keyof typeof AlarmType])
    .filter(type => typeof type === 'number');

export const getAlarmTypeName = (type: AlarmType) => {
    switch (type) {
        case AlarmType.Offline: return "Device is offline";
        //case AlarmType.NoMaterial: return "No material is playing";

        default:
            console.error("Unsupported alarm type:", AlarmType[type]);
            return "";
    }
}

export const selectAcknowledgeDefaultUntil = (): DateTimeString => {
    const date = new Date()
    date.setHours(date.getHours() + DEFAULT_ACKNOWLEDGE_LENGTH_IN_HOURS)
    return toDateTime(date)
}

const getAcknowledgesSortedByUntilDate = (acknowledges: AcknowledgeDictionary): Tracked<Acknowledge>[] =>
    dictionaryToArray(acknowledges).sort(sortAcknowledgeByFurthestUntilDate);

/** Sorts acknowledges by which has the latest until date. */
const sortAcknowledgeByFurthestUntilDate = (a: Tracked<Acknowledge>, b: Tracked<Acknowledge>): number => {
    if (a === b) return 0;
    if (a.current === b.current) return 0;
    if (!a.current?.acknowledgedUntil) return -1;
    if (!b.current?.acknowledgedUntil) return 1;

    const dateA = new Date(a.current.acknowledgedUntil);
    const dateB = new Date(b.current.acknowledgedUntil);

    return Math.sign(dateA.getTime() - dateB.getTime());
}

/** If the alarm is triggered at or after the specified date */
const isAlarmTriggered = (alarm: Alarm, date: Date): boolean =>
    alarm.triggeredAt !== null &&
    new Date(alarm.triggeredAt) <= date;

/** If the alarm is resolved before the specified date */
const isAlarmResolved = (alarm: Alarm, date: Date): boolean =>
    alarm.resolvedAt !== null &&
    new Date(alarm.resolvedAt) < date;

export const isAcknowledgeForAlarm = (alarm: Alarm, acknowledge: Tracked<Acknowledge>): boolean => {
    // Target and type must be same
    if (!isAcknowledgeForInactiveAlarm(alarm, acknowledge))
        return false;

    if (acknowledge.current === null)
        return false;

    if (alarm.customerId !== acknowledge.current.customerId)
        return false;

    const acknowledgedAt = acknowledge.current.acknowledgedAt
    const acknowledgedUntil = acknowledge.current.acknowledgedUntil

    // Acknowledge must end after the alarm was created
    if (new Date(alarm.createdAt) >= new Date(acknowledgedUntil))
        return false;

    // Acknowledge must be created before the alarm was resolved
    if (acknowledgedAt !== null && alarm.resolvedAt !== null) {
        if (new Date(acknowledgedAt) >= new Date(alarm.resolvedAt))
            return false;
    }

    return true;
}

const isAcknowledgeForInactiveAlarm = (alarm: InactiveAlarm, acknowledge: Tracked<Acknowledge>): boolean => {
    return acknowledge.current !== null
        && areTargetsEqual(alarm, acknowledge)
        && alarm.type === acknowledge.current.type;
}

/** If the acknowledge is open at the specified date */
const isAcknowledgeOpen = (acknowledge: Tracked<Acknowledge>, date: Date): boolean => {
    if (acknowledge.current === null)
        return false;

    const currentUntil = acknowledge.current.acknowledgedUntil
    const underlyingUntil = acknowledge.underlying?.acknowledgedUntil ?? currentUntil

    const currentUntilDate = new Date(currentUntil)
    const underlyingUntilDate = new Date(underlyingUntil)

    const maxUntilDate = currentUntilDate > underlyingUntilDate ? currentUntilDate : underlyingUntilDate

    return maxUntilDate > date;
}