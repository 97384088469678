import * as moment from "moment"
import { BrightDays, ShineDefinition } from "./brighttime-types";

const SHINE_DEFINITION_1_REGEX = /^(?<days>\d+)\s(?<riseHours>\d{2}):(?<riseMinutes>\d{2})-(?<sleepHours>\d{2}):(?<sleepMinutes>\d{2})$/

interface ShineDefinitionGroup {
    days: string,
    riseHours: string,
    riseMinutes: string,
    sleepHours: string,
    sleepMinutes: string
}

export const deserializeDefinitionGroups = (description: string): ShineDefinitionGroup[] => {
    const [version, ...sections] = description.split('|').filter(section => section.trim() !== '')

    if (!version)
        return []

    switch (+version) {
        case 1:
            return sections.map(definition => (SHINE_DEFINITION_1_REGEX.exec(definition) as any)?.groups)

        default:
            throw new Error(`Can not build list of BrightTimes from description version ${version}`)
    }
}

export const validateShineDefinitions = (description: string) => {
    try {
        return deserializeDefinitionGroups(description).every(grp => !!grp)
    } catch (e) {
        return false
    }
}

export const buildShineDefinitions = (description: string): ShineDefinition[] => {
    try {
        return deserializeDefinitionGroups(description)
            .filter(grp => !!grp)
            .map(grp => ({
                days: +grp.days as BrightDays,
                rise: +grp.riseHours * 60 + +grp.riseMinutes,
                sleep: +grp.sleepHours * 60 + +grp.sleepMinutes
            }))
    }
    catch {
        return []
    }
}

export const dateToBrightDays = (time: Date) => Math.pow(2, moment(time).isoWeekday() - 1) as BrightDays

export const shouldShine = (shineDefinitions: ShineDefinition[], time: Date) => {
    const brightDate = dateToBrightDays(time)
    const minutesOfDay = time.getHours() * 60 + time.getMinutes()

    return shineDefinitions.some(bt => {
        if ((bt.days & brightDate) === 0)
            return false;

        return minutesOfDay >= bt.rise && minutesOfDay < bt.sleep
    })
}
