import { connect } from "react-redux"
import FA from "../../../components/fa"
import { AppDispatch } from "../../../states/store"
import { RootState } from "../../../states/state"
import { actions } from "@states/channel/channel-slice"

type OwnProps = {
    id: number
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
    const channel = state.channel[props.id]

    const icon = channel.current!.locked ? 'lock' : 'lock-open';

    const className = channel.underlying?.locked !== channel.current!.locked
        ? 'tracked-dirty'
        : channel.current!.locked
            ? 'locked--on'
            : 'locked--off'

    return { icon, className }
}

const mapDispatchToProps = (dispatch: AppDispatch, props: OwnProps) => ({
    onClick: () => dispatch(actions.toggleLock({ id: props.id })),
})

export const LockIcon = connect(mapStateToProps, mapDispatchToProps)(FA)
