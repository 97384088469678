import { Projector, TimeStampedUrl } from "@states/projector/projector-types"
import { getActiveScreenshot } from "@states/projector/projector-util"
import { RootState } from "../state"

export const selectLoadedProjectors = (state: RootState) => state.projector.loaded;

export const selectLoadedProjector = (state: RootState, projectorMac: string): Projector | null =>
    state.projector.loaded[projectorMac] ?? null

export const isFetchingProjector = (state: RootState, projectorMac: string): boolean =>
    state.projector.fetching.includes(projectorMac)

export const selectActiveScreenshot = (state: RootState, projectorMac: string): TimeStampedUrl | null => {
    const projector = state.projector.loaded[projectorMac] ?? null

    if (projector === null)
        return null

    return getActiveScreenshot(projector)
}