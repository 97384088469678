import * as React from "react"
import { isValidElement, ReactNode } from "react"

interface Props {
    label: string,
    title?: string,
    children: ReactNode
}

export const DeviceInfoColumn = (props: Props) => {
    return <div className="device-info__column column">
        <label className="device-info__column__label">{props.label}</label>
        {
            isValidElement(props.children)
                ? <div title={props.title}>{props.children}</div>
                : <p className="device-info__column__value" title={props.title}>{props.children}</p>
        }
    </div>
}