import { createSelector } from "@reduxjs/toolkit"
import { selectDirtyMessages, selectMessages } from "@states/info-message/info-message-selectors"
import { RootState } from "@states/state"
import { AppDispatch } from "@states/store"
import { findRemoved } from "mummet-core"
import * as React from "react"
import { connect } from "react-redux"
import FA, { FaStyle } from "../../components/fa"
import { selectAcknowledges, selectDirtyAcknowledges } from "@states/alarm/alarm-selectors"
import { selectBrightTimes, selectDirtyBrightTimes } from "@states/brighttime/brighttime-selectors"
import { selectChannels, selectDirtyChannels } from "@states/channel/channel-selectors"
import { selectIsSaving } from "./shared-save-button-selectors"
import { saveAll } from "./shared-save-button-thunks"

interface DispatchProps {
    save: () => void
}

interface StateProps {
    dirty: boolean
    className: string

    icon: string
    spin: boolean
    style: FaStyle
}

type Props = StateProps & DispatchProps;

const memoizedDirtyMessages = createSelector([selectMessages], selectDirtyMessages);
const memoizedDirtyChannels = createSelector([selectChannels], selectDirtyChannels);
const memoizedBrightTimes = createSelector([selectBrightTimes], selectDirtyBrightTimes)
const memoizedDirtyAcknowledges = createSelector([selectAcknowledges], selectDirtyAcknowledges)
const removedBrightTimes = (state: RootState) => findRemoved(state.brightTime)

const memoizedIsDirty = createSelector(
    [memoizedDirtyMessages, memoizedDirtyChannels, memoizedBrightTimes, removedBrightTimes, memoizedDirtyAcknowledges],
    (dirtyMessages, dirtyChannels, dirtyBrightTimes, removedBrightTimes, dirtyAcknowledges) => {
        return dirtyMessages.length > 0 ||
            dirtyAcknowledges.length > 0 ||
            dirtyChannels.length > 0 ||
            dirtyBrightTimes.length > 0 ||
            removedBrightTimes.length > 0
    }
);
const memoizedClassName = createSelector([memoizedIsDirty, selectIsSaving], (dirty, saving) => "shared-save-button" + (dirty && !saving ? " dirty" : ""));
const memoizedIcon = createSelector([selectIsSaving], saving => saving ? "compact-disc" : "save");
const memoizedStyle = createSelector([selectIsSaving], saving => saving ? "s" : "r");

const mapStateToProps = (state: RootState): StateProps => ({
    dirty: memoizedIsDirty(state),
    className: memoizedClassName(state),

    spin: selectIsSaving(state),
    icon: memoizedIcon(state),
    style: memoizedStyle(state)
})

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
    save: () => {
        dispatch(saveAll());
    }
})

class SharedSaveButton extends React.Component<Props> {
    constructor(props: Props) {
        super(props)

        this.onSaveClick = this.onSaveClick.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onBeforeUnload = this.onBeforeUnload.bind(this);
    }

    render() {
        return (
            <FA icon={this.props.icon}
                fixedWidth={true}
                style={this.props.style}
                size="2x"
                spin={this.props.spin}
                className={this.props.className}
                onClick={this.onSaveClick}
            />
        );
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onKeyPress);
        window.addEventListener("beforeunload", this.onBeforeUnload);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyPress);
        window.removeEventListener("beforeunload", this.onBeforeUnload);
    }

    onSaveClick(_: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (this.props.dirty) {
            this.props.save();
        }
    }

    onKeyPress(e: KeyboardEvent) {
        if (e.ctrlKey && e.key === "s") {
            e.preventDefault();

            if (this.props.dirty) {
                this.props.save();
            }
        }
    }

    onBeforeUnload(e: BeforeUnloadEvent) {
        if (this.props.dirty) {
            e.preventDefault();
            e.returnValue = "You have unsaved changes. Are you sure you want to leave?"; // not shown in most browsers
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedSaveButton);