import { dictionaryToArray, mapDictionary } from "@src/lib/dictionary-util"
import { Target } from "@src/lib/targetable"
import {
    selectAcknowledgeForAlarm,
    selectAcknowledgeForInactiveAlarm,
    selectAcknowledges,
    selectAlarms,
    selectAlarmsForTarget,
    selectMissingAlarmTypes,
    selectOpenAcknowledges,
    selectOpenAlarms
} from "@states/alarm/alarm-selectors"
import { useAppSelector } from "@states/hooks"
import { RootState } from "@states/state"
import { Dictionary, Tracked } from "mummet-core/dist/types"
import * as React from "react"
import { useMemo, useState } from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import { Store } from "redux"
import { DateTimeString, toDateTime } from "../../lib/date-string"
import { Acknowledge, Alarm, AlarmType, InactiveAlarm as InactiveAlarmType } from "../../states/alarm/alarm-types"
import { ActiveAlarm } from "./components/active-alarm"
import { InactiveAlarm } from "./components/inactive-alarm"
import NoAlarms from "./components/noalarms-alarm"
import { ViewAll } from "./components/view-all"

interface AlarmAndAcknowledge {
    alarm: Alarm
    acknowledge?: Tracked<Acknowledge>
}

interface AlarmTypeAndAcknowledge {
    alarm: InactiveAlarmType
    acknowledge?: Tracked<Acknowledge>
}

interface Props {
    target: Target,
    customerId: number,

    /**
     * Optional override for getting the current time. Useful for unit testing.
     */
    nowSupplier?: () => DateTimeString
}

export const AlarmList = (props: Props) => {
    const nowSupplier = props.nowSupplier ?? (() => toDateTime(new Date()))
    const now = new Date(nowSupplier())

    const [isExpanded, setExpanded] = useState(false)

    const allAlarms = useAppSelector(selectAlarms)
    const allAcknowledges = useAppSelector(selectAcknowledges)

    const allOpenAlarms = useMemo(() => selectOpenAlarms(allAlarms, now), [allAlarms])
    const allOpenAcknowledges = useMemo(() => selectOpenAcknowledges(allAcknowledges, now), [allAcknowledges])

    const alarms = useMemo(() =>
        selectAlarmsForTarget(allOpenAlarms, props.target),
        [allOpenAlarms, props.target])

    const inactiveAlarmTypes = useMemo(() =>
        selectMissingAlarmTypes(alarms),
        [alarms])

    const activeAlarms = useMemo(() =>
        getActiveAlarmsForTarget(alarms, allOpenAcknowledges),
        [alarms, allOpenAcknowledges])

    const inactiveAlarms = useMemo(() =>
        getInactiveAlarmsForTarget(props.target, inactiveAlarmTypes, allOpenAcknowledges),
        [props.target, inactiveAlarmTypes, allOpenAcknowledges])

    const activeAlarmElements = useMemo(() =>
        createActiveAlarmsElements(activeAlarms, props.customerId, nowSupplier),
        [activeAlarms])

    const inactiveAlarmsSection = useMemo(() =>
            inactiveAlarms.length === 0
                ? null
                : <>
                    <ViewAll expanded={isExpanded} onClick={() => setExpanded(expanded => !expanded)} />
                    {isExpanded && createInactiveAlarmsElements(inactiveAlarms, props.customerId, nowSupplier)}
                </>,
        [inactiveAlarms, props.customerId, isExpanded])

    return <>
        {activeAlarmElements}
        {inactiveAlarmsSection}
    </>
}

const getActiveAlarmsForTarget = (alarmsForTarget: Dictionary<Alarm>, allOpenAcknowledges: Dictionary<Tracked<Acknowledge>>) =>
    mapDictionary(alarmsForTarget, (alarm) => ({
        alarm: alarm,
        acknowledge: selectAcknowledgeForAlarm(alarm, allOpenAcknowledges) || undefined
    }) as AlarmAndAcknowledge)

const getInactiveAlarmsForTarget = (target: Target, inactiveAlarmTypes: AlarmType[], allOpenAcknowledges: Dictionary<Tracked<Acknowledge>>) =>
    inactiveAlarmTypes.map((type) => {
        const alarm: InactiveAlarmType = { target, type }

        return {
            alarm,
            acknowledge: selectAcknowledgeForInactiveAlarm(alarm, allOpenAcknowledges) || undefined
        } as AlarmTypeAndAcknowledge
    })

const createActiveAlarmsElements = (activeAlarms: Dictionary<AlarmAndAcknowledge>, customerId: number, nowSupplier: () => DateTimeString) => {
    const elements = dictionaryToArray(activeAlarms)
        .map(entry =>
            <ActiveAlarm
                key={entry.alarm.id}
                customerId={customerId}
                alarm={entry.alarm}
                acknowledge={entry.acknowledge}
                nowSupplier={nowSupplier}
            />)

    return elements.length === 0
        ? <NoAlarms/>
        : elements
}

const createInactiveAlarmsElements = (inactiveAlarms: AlarmTypeAndAcknowledge[], customerId: number, nowSupplier: () => DateTimeString) =>
    inactiveAlarms.map(entry =>
        <InactiveAlarm
            key={entry.alarm.type}
            customerId={customerId}
            alarm={entry.alarm}
            acknowledge={entry.acknowledge}
            nowSupplier={nowSupplier}
        />)

export const renderAlarmList = (target: Target, customerId: number, store: Store<RootState>, root: Element | null, nowSupplier?: () => DateTimeString) => {
    if (!root) return void 0
    return render(
        <Provider store={store}>
            <AlarmList target={target} customerId={customerId} nowSupplier={nowSupplier} />
        </Provider>,
        root
    )
}