import { Modal } from "@src/components/modal"
import { dictionaryToArray } from "@src/lib/dictionary-util"
import { actions } from "@states/message/message-slice"
import { MessageType } from "@states/message/message-types"
import { RootState } from "@states/state"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"

interface MessageProp {
    header: string,
    content: string,
    type: MessageType,
    onClose?: React.MouseEventHandler
}

const Message = React.memo((props: MessageProp) => {
    return (
        <Modal show={true} onClose={props.onClose} className="modal--message">
            <h2>{props.type}</h2>
            <h3>{props.header}</h3>
            <p>{props.content}</p>
        </Modal>
    )
})

export const MessagePopups = () => {
    const dispatch = useDispatch()

    const messages = useSelector((state: RootState) => state.message.messages)
    const message = dictionaryToArray(messages)[0]

    if (message === undefined)
        return null

    const onClose = () => dispatch(actions.hide({ id: message.id }))

    return <Message
        header={message.header}
        content={message.content}
        type={message.type}
        onClose={onClose}
    />
}