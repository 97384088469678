import { InfoMessage, InfoMessagesState, InfoMessageDraft, InfoMessageReminderStatus } from "./info-message-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createInfoMessage, deleteInfoMessage, fetchInfoMessages, fetchInfoMessagesForChain, fetchInfoMessagesForOverview, putInfoMessage } from "./info-message-thunks";
import { track } from "mummet-core";
import { selectMessageOrNull } from "./info-message-selectors";
import { selectHasReminder } from "@features/info-message-box/info-message-box-selectors";
import { DateString } from "../../lib/date-string";
import { areTargetsEqual, Target } from "../../lib/targetable";

const INITIAL_STATE: InfoMessagesState = {
    messages: []
}

const slice = createSlice({
    name: 'infos',
    initialState: INITIAL_STATE,
    reducers: {
        clearAll: (state) => { state.messages = [] },

        /** Set text on existing message, or creates new draft with set text. */
        setText: (state, action: PayloadAction<{ text: string, target: Target, customerId: number }>) => {
            const { text, target, customerId } = action.payload;

            const message = createAndPushMessageIfNotExist(state, target, customerId);

            message.current = { ...message.current!, text };
        },

        /** Set reminder on existing message, or creates new draft with empty text and set reminder. */
        setReminder: (state, action: PayloadAction<{ date: DateString | null, target: Target, status?: InfoMessageReminderStatus, customerId: number }>) => {
            const { date, target, status, customerId } = action.payload;


            const reminder = date === null ? null : {
                date: date,
                status: status || InfoMessageReminderStatus.Open
            }

            const message = createAndPushMessageIfNotExist(state, target, customerId);

            message.current = { ...message.current!, reminder: reminder };
        },

        /** Toggles the status of a reminder on existing message, or creates new draft with a set reminder.
         * If the reminder exists, its status will be toggled.
         * If the reminder doesn't exist, the underlying reminder will be used if it exists,
         * otherwise a new reminder is created with the fallbackDate param. */
        toggleReminderStatus: (state, action: PayloadAction<{ target: Target, fallbackDate: DateString, customerId: number }>) => {
            const { target, fallbackDate, customerId } = action.payload;

            const fallbackReminder = {
                date: fallbackDate,
                status: InfoMessageReminderStatus.Open
            }

            let message = selectMessageOrNull(state.messages, target);
            
            if (message == null) {
                message = track({ text: "", target: target, customerId: customerId, reminder: null });
                message.current = { ...message.current!, reminder: fallbackReminder };
                state.messages.push(message);
                return;
            }

            if (selectHasReminder(message)) {
                const newStatus = message.current?.reminder?.status === InfoMessageReminderStatus.Open
                    ? InfoMessageReminderStatus.Closed
                    : InfoMessageReminderStatus.Open;

                message.current!.reminder = { ...message.current!.reminder!, status: newStatus };
            } else if (message.underlying!.reminder !== null) {
                message.current!.reminder = message.underlying!.reminder;
            } else {
                message.current!.reminder = fallbackReminder;
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(createInfoMessage.fulfilled, (state, action: PayloadAction<{ created: boolean, id?: number, target: Target }>) => {
                const { created, id, target } = action.payload;

                if (!created)
                    return;

                const message = selectMessageOrNull(state.messages, target);

                if (message != null) {
                    message.underlying = { ...message.current!, id: id };
                }
            })
            .addCase(putInfoMessage.fulfilled, (state, action: PayloadAction<{ updated: boolean, target: Target }>) => {
                const { updated, target } = action.payload;

                if (!updated)
                    return;

                const message = selectMessageOrNull(state.messages, target);

                if (message)
                    message.underlying = { ...message.current!, id: message.underlying!.id }
            })
            .addCase(deleteInfoMessage.fulfilled, (state, action: PayloadAction<{ deleted: boolean, target: Target }>) => {
                if (action.payload.deleted) {
                    state.messages = state.messages.filter(msg => !areTargetsEqual(msg, action.payload));
                }
            })
            .addCase(fetchInfoMessages.fulfilled, (state, action: PayloadAction<InfoMessage[]>) => {
                action.payload.forEach(incomingMessage => {

                    const message = selectMessageOrNull(state.messages, incomingMessage.target);

                    if (message != null) {
                        message.underlying = incomingMessage;
                    } else {
                        state.messages.push(track(incomingMessage));
                    }
                });
            })
            .addCase(fetchInfoMessagesForChain.fulfilled, (state, action: PayloadAction<InfoMessage[]>) => {
                action.payload.forEach(incomingMessage => {

                    const message = selectMessageOrNull(state.messages, incomingMessage.target);

                    if (message != null) {
                        message.underlying = incomingMessage;
                    } else {
                        state.messages.push(track(incomingMessage));
                    }
                });
            })
            .addCase(fetchInfoMessagesForOverview.fulfilled, (state, action: PayloadAction<InfoMessage[]>) => {
                action.payload.forEach(incomingMessage => {

                    const message = selectMessageOrNull(state.messages, incomingMessage.target);

                    if (message != null) {
                        message.underlying = incomingMessage;
                    } else {
                        state.messages.push(track(incomingMessage));
                    }
                });
            })
    }
})

function createAndPushMessageIfNotExist(state: InfoMessagesState, target: Target, customerId: number ) {
    let message = selectMessageOrNull(state.messages, target);
    if (message == null) {
        message = track<InfoMessageDraft>({ text: "", reminder: null, target: target, customerId: customerId });
        state.messages.push(message);
    }
    return message;
}

export const actions = slice.actions;
export const reducer = slice.reducer;