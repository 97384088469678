import { RootState } from "../state"
import { Role, UserState } from "./user-types"
import { emptyUser } from "./user-util"

export const selectUsers = (state: UserState) => state.all
export const selectUser = (state: UserState, id: number) => selectUsers(state)[id] ?? emptyUser()

export const selectCurrentUser = (state: UserState) => selectUser(state, state.current)
export const selectCurrentUserRoles = (state: UserState) => selectCurrentUser(state).roles
export const selectCurrentUserName = (state: UserState) => selectCurrentUser(state).userName

export const selectUserName = (state: UserState, id: number) => selectUser(state, id).userName

export const selectCurrentUserHasRole = (state: UserState, filter: Role) =>
    selectHasRole(selectCurrentUserRoles(state), filter)

export const selectCurrentUserHasAnyRole = (state: UserState, ...filters: Role[]) =>
    selectHasAnyRole(selectCurrentUserRoles(state), ...filters)

export const selectHasRole = (roles: readonly Role[], filter: Role) => roles.includes(filter);
export const selectHasAnyRole = (roles: readonly Role[], ...filters: Role[]) => roles.some(r => filters.includes(r))

export const selectChainName = (state: RootState) => {
    const id = state.customerTree.selectedChainId;
    const customer = state.customer[id];
    return customer?.name || '';
}