import { alertGenericError, alertGenericErrorAndReloadPage } from "@src/error-handling"

export default class FlowPlanner {
    public static openPlayer(customerId: number, playerId: string) {
        return FlowPlanner.open(customerId, playerId, "FlowPlayer");
    }

    public static openChannel(customerId: number, channelId: number) {
        return FlowPlanner.open(customerId, channelId, "Channel");
    }

    private static open(customerId: number, containerId: string | number, containerType: string) {
        return $.ajax({
            url: '/Main/GetGoToFlowPlannerUrl',
            type: "POST",
            dataType: "text",
            data: { customerId, containerId, containerType },
            success: function (result, textStatus, xhr) {
                if (result !== '' && textStatus === "success") {
                    window.open(result);
                } else {
                    alertGenericErrorAndReloadPage()
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alertGenericError()
            }
        });
    }
}