import CustomerTree from "@features/customer-tree/customer-tree"
import { selectCustomerId } from "@features/customer-tree/customer-tree-selectors"
import { DeviceDetails } from "@features/device-details/device-details"
import directorDetails from "@features/director-details/director-details-legacy"
import MainPage from "@features/main-page"
import { createSelector, Dictionary, unwrapResult } from "@reduxjs/toolkit"
import { alertError, alertGenericError } from "@src/error-handling"
import Screenshot, { ResultCode } from "@src/lib/screenshot"
import { memoizedActiveDevices } from "@states/device-overview/device-overview-selectors"
import { selectLoadedDirector } from "@states/director/director-selectors"
import { setDirectorBroken } from "@states/director/director-thunks"
import { Director } from "@states/director/director-types"
import { isDirector } from "@states/director/director-util"
import { actions as projectorActions, Projector } from "@states/projector"
import { actions as messageActions } from "@states/message/message-slice"
import { selectActiveScreenshot, selectLoadedProjector } from "@states/projector/projector-selectors"
import { fetchProjector, setProjectorBroken } from "@states/projector/projector-thunks"
import { projectorScreenshotFromUrl } from "@states/projector/projector-util"
import { RootState } from "@states/state"
import store from "@states/store"
import * as React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"

const deviceDetails = {
    SCREENSHOT_POPUP: 0,
    SCREENSHOT_GALLARY: 1,
    SCREENSHOT_TIME_READY: 0,
    SCREENSHOT_TIME_ABORTED: -3,
    MAX_SCREENSHOT_WIDTH: 768,

    initialize: function () {
        store.subscribe(() => {
            renderDeviceDetails(store.getState())
        })
    },
    checkIsHex: function (str: string) {
        // check that all characters in string are numbers 0 to 9, letters a to f or A to F.
        return /^[0-9a-fA-F]+$/.test(str)
    },
    displayRestartSelectedDevicesDialog: function () {
        $("#device-restart-dialog").dialog({
            resizable: false,
            draggable: false,
            height: "auto",
            width: 450,
            modal: true,
            buttons: {
                "Ok": function () {
                    $(this).dialog("destroy")
                    const affectedDevices = deviceDetails.getSelectedDevices()
                    const customerId = selectCustomerId(store.getState())
                    deviceDetails.restartDevices(customerId, affectedDevices)
                },
                Cancel: function () {
                    $(this).dialog("destroy")
                }
            }
        })
    },
    displayFactoryResetSelectedDevicesDialog: function() {
        $("#device-factory-reset-dialog").dialog({
            resizable: false,
            draggable: false,
            height: "auto",
            width: 470,
            modal: true,
            buttons: {
                "Ok": function () {
                    $(this).dialog("destroy")
                    const affectedDevices = deviceDetails.getSelectedDevices()
                    const customerId = selectCustomerId(store.getState())
                    deviceDetails.factoryResetDevices(customerId, affectedDevices)
                },
                Cancel: function () {
                    $(this).dialog("destroy")
                }
            }
        })
    },
    displayMarkSelectedDevicesBrokenDialog: function(customerId: number, devices: Director | Projector[]) {
        if (customerId < 0)
            return

        const $dialog = $("#device-mark-broken-dialog")

        const dialogOptions = {
            resizable: false,
            draggable: false,
            height: "auto",
            modal: true,
            width: 500,
        }

        if (isDirector(devices)) {
            const $inputElem = $('#device-mark-broken-replacement-mac')
            $inputElem.val($inputElem.attr('data-current-mac') as string)
            $dialog.dialog({
                ...dialogOptions,
                width: 450,
                buttons: {
                    "Mark Broken": function() {
                        const replacementMac = $('#device-mark-broken-replacement-mac').val() as string
                        const reason = $('#device-mark-broken-reason').val() as string

                        $(this).dialog("destroy")

                        if (!isReplacementMacValid(replacementMac)) {
                            store.dispatch(messageActions.showError({
                                header: "Invalid replacement mac.",
                                content: "The replacement mac must be a 12 character hex string."
                            }))
                            return
                        }

                        const director = selectLoadedDirector(store.getState(), customerId)
                        if (!director) return

                        store.dispatch(setDirectorBroken({
                            director,
                            broken: true,
                            replacementMac,
                            reason,
                        }))
                    },
                    Cancel: function () { $(this).dialog("destroy") }
                }
            })
        } else if (devices.length === 1) {
            $dialog.dialog({
                ...dialogOptions,
                buttons: {
                    "Mark Broken": function() {
                        $(this).dialog("destroy")
                        const reason = $('#device-mark-broken-reason').val() as string

                        store.dispatch(setProjectorBroken({
                            projector: devices[0],
                            broken: true,
                            reason
                        }))
                    },
                    Cancel: function() { $(this).dialog("destroy") }
                }
            })
        } else if (devices.length > 1) {
            $dialog.dialog({
                ...dialogOptions,
                buttons: {
                    "Mark Broken": function() {
                        $(this).dialog("destroy")
                        const reason = $('#device-mark-broken-reason').val() as string

                        for (const projector of devices) {
                            store.dispatch(setProjectorBroken({
                                projector,
                                broken: true,
                                reason
                            }))
                        }
                    },
                    Cancel: function() { $(this).dialog("destroy") }
                }
            })
        }
    },
    displayDeleteSelectedDirectorDialog: function() {
        $('#delete-director-dialog').dialog({
            resizable: false,
            draggable: false,
            height: 'auto',
            width: 450,
            modal: true,
            buttons: {
                'Ok': function () {
                    $(this).dialog('destroy')
                    const customerId = selectCustomerId(store.getState())
                    directorDetails.deleteDirector(customerId)
                },
                Cancel: function () {
                    $(this).dialog('destroy')
                }
            }
        })
    },
    getSelectedDevices: function (): string[] {
        const state = store.getState()

        const devices = memoizedActiveDevices(state)

        if (Array.isArray(devices)) {
            return devices.map(d => d.projectorMac)
        }

        if (devices === 'director') {
            const director = selectLoadedDirector(state, selectCustomerId(state)) ?? null
            return director !== null ? [director.id] : []
        }

        return []
    },
    factoryResetDevices: function (customerId: number, deviceIds: string[]) {
        $.ajax({
            url: '/Main/factoryResetDevices',
            type: "POST",
            dataType: "text",
            data: { 'customerId': customerId, 'deviceIds': deviceIds },
            success: function (result) {
                if (result === "True") {
                    return
                }
                if (result === "False") {
                    alertError('Failed to factory reset devices.')
                }
                else {
                    window.location.reload()
                }
            },
            error: function () {
                alertError('Failed to factory reset devices.')
            }
        })
    },
    restartDevices: function (customerId: number, deviceIds: string[]) {
        $.ajax({
            url: '/Main/RestartDevices',
            type: "POST",
            dataType: "text",
            data: { 'customerId': customerId, 'deviceIds': deviceIds },
            success: function (result) {
                if (result === "True") {
                    return
                }
                if (result === "False") {
                    alertError('Failed to restart devices.')
                }
                else {
                    window.location.reload()
                }
            },
            error: function () {
                alertError('Failed to restart devices.')
            }
        })
    },
    getScreenshotsForAllProjectors: function(customerId: number) {
        $.ajax({
            url: '/Main/GetAllProjectors',
            type: "POST",
            dataType: "json",
            data: { 'customerId': customerId },
            success: function (projectorsRaw: string) {
                const projectors = JSON.parse(projectorsRaw) as { name: string, index: number, id: string }[]

                if (projectors.length <= 0) {
                    alertError("No projectors found.")
                    return
                }

                const players: Dictionary<string> = {}
                for (const projector of projectors) {
                    players[projector.id] = projector.name || '#' + projector.index
                }

                deviceDetails.loadScreenAblumPopup(players)

                for (const projector of projectors) {
                    deviceDetails.getScreenshotForProjector(
                        projector.id,
                        customerId,
                        deviceDetails.SCREENSHOT_GALLARY,
                        deviceDetails.MAX_SCREENSHOT_WIDTH)
                }
            },
            error: function () {
                alertGenericError()
            }
        })
    },
    getScreenshotForProjector: async function (projectorId: string, customerId: number, displayMode: number, maxImageWidth: number) {
        const projector = await selectOrFetchProjector(projectorId)

        if (projector === null) {
            alertError("Failed to get screendump for the projector.")
            return
        }

        const state = store.getState()
        const screenshot = selectActiveScreenshot(state, projectorId)

        const showImageNow = displayMode === deviceDetails.SCREENSHOT_POPUP && screenshot !== null

        const isWaitingForScreenshot = projector.screenshot === 'loading'

        if ((displayMode === deviceDetails.SCREENSHOT_POPUP && !isWaitingForScreenshot && !showImageNow)
            || displayMode === deviceDetails.SCREENSHOT_GALLARY) {

            store.dispatch(projectorActions.setScreenshot({
                projectorMac: projectorId,
                screenshot: 'loading'
            }))

            // Cancel the screenshot request if projector is no longer loaded
            const checkCancel = () => {
                const state = store.getState()
                return selectLoadedProjector(state, projectorId) === null
            }

            const resultCode = await Screenshot.checkAvailable(projectorId, customerId, maxImageWidth, checkCancel)
            switch (resultCode) {
                case ResultCode.Ready:
                    deviceDetails.onGetProjectorScreenshotHasSucceeded(projectorId, customerId, displayMode, maxImageWidth)
                    break
                case ResultCode.Aborted:
                    deviceDetails.onGetProjectorScreenshotHasFailed(projectorId, displayMode)
                    break
                case ResultCode.CancelledByUser:
                    store.dispatch(projectorActions.setScreenshot({
                        projectorMac: projectorId,
                        screenshot: null
                    }))
                    break
                default:
                    console.warn("Screenshot.checkAvailable() returned an unexpected resultCode: " + resultCode)
                    alertGenericError()
                    break
            }
        }
        else if (showImageNow) {
            if (screenshot !== null) {
                const image = document.createElement("img")
                image.src = screenshot.url
                image.width = maxImageWidth
                $.featherlight($(image), { 'variant': 'sunflow-light-featherlight' })
            } else {
                console.error("No screenshot is available even though one is expected to be.")
                alertGenericError()
            }
        }
    },
    loadScreenAblumPopup: function (players: Dictionary<string>) {
        $.ajax({
            url: '/Main/LoadScreenAblumPopup/',
            type: "POST",
            data: JSON.stringify({ 'players': players }),
            dataType: "html",
            contentType: "application/json charset=utf-8",
            success: function (result) {
                const $result = $(result)
                MainPage.checkIfLoggedInAfterAjax($result)
                $.featherlight($result, { 'variant': 'sunflow-light-featherlight' })
            },
            error: function () {
                alertGenericError()
            }
        })
    },
    queueGetProjectorScreenshot: function (delayInSeconds: number, projectorId: string, customerId: number, displayMode: number, maxImageWidth: number) {
        if (delayInSeconds > 0) {
            setTimeout(function () {
                deviceDetails.checkProjectorScreenshotAvailable(projectorId, customerId, displayMode, maxImageWidth)
            }, 1000 * delayInSeconds)
        }
        else if (delayInSeconds === deviceDetails.SCREENSHOT_TIME_READY) {
            deviceDetails.onGetProjectorScreenshotHasSucceeded(projectorId, customerId, displayMode, maxImageWidth)
        }
        else if (delayInSeconds === deviceDetails.SCREENSHOT_TIME_ABORTED) {
            deviceDetails.onGetProjectorScreenshotHasFailed(projectorId, displayMode)
        }
        else {
            console.error("Invalid arguments for 'queueGetProjectorScreenshot'")
            alertGenericError()
        }
    },
    checkProjectorScreenshotAvailable: async function (projectorId: string, customerId: number, displayMode: number, maxImageWidth: number) {
        if (CustomerTree.getSelectedCustomerId() !== customerId)
            return

        const state = store.getState()
        const projector = selectLoadedProjector(state, projectorId)

        if (projector === null)
            return

        if (displayMode === deviceDetails.SCREENSHOT_POPUP)
            return

        let timeToPicture = await Screenshot.load(customerId, projectorId, maxImageWidth)
        deviceDetails.queueGetProjectorScreenshot(timeToPicture, projectorId, customerId, displayMode, maxImageWidth)
    },
    onGetProjectorScreenshotHasSucceeded: function (projectorId: string, customerId: number, displayMode: number, maxImageWidth: number) {
        if (displayMode === deviceDetails.SCREENSHOT_POPUP) {
            deviceDetails.updateScreenshotForPopup(projectorId, customerId, maxImageWidth)
        }
        else if (displayMode === deviceDetails.SCREENSHOT_GALLARY) {
            deviceDetails.updateScreenshotForGallary(projectorId, customerId, maxImageWidth)
        }
        else {
            console.error("Unexpected value for displayMode. Value: " + displayMode)
            alertGenericError()
        }
    },
    onGetProjectorScreenshotHasFailed: function (projectorId: string, displayMode: number) {
        switch (displayMode) {
            case deviceDetails.SCREENSHOT_POPUP:
                deviceDetails.screenshotFailedForPopup(projectorId)
                break

            case deviceDetails.SCREENSHOT_GALLARY:
                deviceDetails.screenshotFailedForGallary(projectorId)
                break

            default:
                console.error("Unexpected value for displayMode. Value: " + displayMode)
                alertError("Failed to get screendumps.")
        }
    },
    updateScreenshotForPopup: function (projectorId: string, customerId: number, maxImageWidth: number) {
        const screenshotUrl = `/Projector/GetScreenshot?projectorId=${projectorId}&customerId=${customerId}&maxImageWidth=${maxImageWidth}`

        store.dispatch(projectorActions.setScreenshot({
            projectorMac: projectorId,
            screenshot: projectorScreenshotFromUrl(screenshotUrl)
        }))
    },
    screenshotFailedForPopup: function (projectorId: string) {
        store.dispatch(projectorActions.setScreenshot({
            projectorMac: projectorId,
            screenshot: null
        }))

        alertError("Failed to get screendumps.")
    },
    updateScreenshotForGallary: function (projectorId: string, customerId: number, maxImageWidth: number) {
        const screenshotUrl = `/Projector/GetScreenshot?projectorId=${projectorId}&customerId=${customerId}&maxImageWidth=${maxImageWidth}`

        const $thumbToBeReplaced = $('#album-' + projectorId)
        $thumbToBeReplaced.attr('src', screenshotUrl)
        $thumbToBeReplaced.parent().children(".fas").remove()

        store.dispatch(projectorActions.setScreenshot({
            projectorMac: projectorId,
            screenshot: projectorScreenshotFromUrl(screenshotUrl)
        }))
    },
    screenshotFailedForGallary: function (projectorId: string) {
        const $thumbToBeReplaced = $('#album-' + projectorId)
        $thumbToBeReplaced.attr('src', '/Content/images/Critical.png')
        $thumbToBeReplaced.parent().children(".fas").remove()

        store.dispatch(projectorActions.setScreenshot({
            projectorMac: projectorId,
            screenshot: null
        }))
    }
}

export default deviceDetails

export const renderDeviceDetailsNow = (state: RootState) => {
    const devices = memoizedActiveDevices(state)
    renderDeviceDetailsInternal(devices)
}

const renderDeviceDetails = createSelector(
    [memoizedActiveDevices],
    (devices) => renderDeviceDetailsInternal(devices)
)

const renderDeviceDetailsInternal = (devices: 'director' | {projectorMac: string}[] | null) => {
    if (devices === null)
        return

    let root = document.getElementById("device-details-root")

    if (root === null) {
        const parent = document.getElementById("details-box")

        if (parent === null)
            return

        const range = document.createRange()
        range.selectNodeContents(parent)
        range.deleteContents()

        root = document.createElement("div")
        root.id = "device-details-root"
        parent.appendChild(root)
    }

    render(
        <Provider store={store}>
            <DeviceDetails />
        </Provider>,
        root
    )
}

const isReplacementMacValid = (replacementMac: string): boolean =>
    replacementMac.length == 12 &&
    deviceDetails.checkIsHex(replacementMac)

const selectOrFetchProjector = async (projectorMac: string): Promise<Projector | null> => {
    const projector = selectLoadedProjector(store.getState(), projectorMac)

    if (projector !== null)
        return projector

    return await store
        .dispatch(fetchProjector({projectorMac: projectorMac}))
        .then(unwrapResult)
        .catch(_ => null)
}