import { createAsyncThunk } from "@reduxjs/toolkit";
import { InfoMessage, InfoMessageReminder, ServerInfoMessage } from "./info-message-types";
import { RootState } from "@states/state";
import { AppDispatch } from "../store";
import xhr from '../../lib/xhr'
import { selectMessageOrEmpty, selectIsMessageEmpty, selectMessageExistsOnServer } from "./info-message-selectors";
import { selectCustomerId } from "../../features/customer-tree/customer-tree-selectors";
import { fetchMissingCustomerIds } from "../customer/customer-thunks";
import { toDate } from "../../lib/date-string";
import { ThunkArgs } from "../state-types";
import { Target } from "../../lib/targetable";

const mapReminder = (model: ServerInfoMessage): InfoMessageReminder | null => {
    if (model.reminderDate === null || model.status === null)
        return null;

    return {
        date: toDate(model.reminderDate),
        status: model.status
    }
}

const mapInfoMessage = (model: ServerInfoMessage): InfoMessage => ({
    id: model.id,
    text: model.text,
    customerId: model.customerId,
    reminder: mapReminder(model),
    target: {
        id: model.targetId,
        type: model.targetType
    }
})

export const handleSave = createAsyncThunk<void, Target, ThunkArgs>
    ('infoMessage/handleSave',
    async (target: Target, thunkApi) => {
        const customerId = selectCustomerId(thunkApi.getState());
        const draft = selectMessageOrEmpty(thunkApi.getState().infoMessage.messages, target, customerId);

        const isEmpty = selectIsMessageEmpty(draft);
        const existsOnServer = selectMessageExistsOnServer(draft);

        if (isEmpty) {
            if (existsOnServer) {
                await thunkApi.dispatch(deleteInfoMessage(target));
            }
        } else if (existsOnServer)
            await thunkApi.dispatch(putInfoMessage(target));
        else
            await thunkApi.dispatch(createInfoMessage(target));
    });

export const deleteInfoMessage = createAsyncThunk<{ deleted: boolean, target: Target }, Target, ThunkArgs>
    ('infoMessage/delete',
    async (target: Target, thunkApi) => {
        const customerId = selectCustomerId(thunkApi.getState());
        const messageId = selectMessageOrEmpty(thunkApi.getState().infoMessage.messages, target, customerId).underlying!.id;

        try {
            await xhr.delete(`/InfoMessage/${customerId}/${messageId}`);
            return (await { deleted: true, target: target });
        } catch (e) {
            console.error("InfoMessage Delete failed: ", e);
            return (await { deleted: false, target: target });
        }
    });

export const putInfoMessage = createAsyncThunk<{ updated: boolean, target: Target }, Target, ThunkArgs>
    ('infoMessage/put',
    async (target: Target, thunkApi) => {
        const customerId = selectCustomerId(thunkApi.getState());
        const draft = selectMessageOrEmpty(thunkApi.getState().infoMessage.messages, target, customerId);

        try {
            await xhr.put<boolean>('/InfoMessage', {
                infoMessage: { ...draft.current, id: draft.underlying!.id }
            });

            return (await { updated: true, target: target });
        } catch (e) {
            console.error("InfoMessage Put failed: ", e);
            return (await { updated: false, target: target });
        }
    });

export const createInfoMessage = createAsyncThunk<{ created: boolean, id?: number, target: Target }, Target, ThunkArgs>
    ('infoMessage/create',
    async (target: Target, thunkApi) => {
        const customerId = selectCustomerId(thunkApi.getState());
        const draft = selectMessageOrEmpty(thunkApi.getState().infoMessage.messages, target, customerId);

        try {
            const response = await xhr.post<number>('/InfoMessage', { infoMessage: draft.current });
            return (await { created: true, id: response.data as number, target: target });
        } catch (e) {
            console.error("InfoMessage Create failed: ", e);
            return (await { created: false, target: target });
        }
    })

export const fetchInfoMessages = createAsyncThunk<InfoMessage[], number>
    ('infoMessage/get',
    async (customerId: number) => {
        const { data: models } = await xhr.get<ServerInfoMessage[]>(`/InfoMessage/Customer/${customerId}`);

        return models.map(mapInfoMessage);
    })

export const fetchInfoMessagesForChain = createAsyncThunk<InfoMessage[], number>
    ('infoMessage/getChain',
    async (chainId: number) => {
        const { data: items } = await xhr.get<ServerInfoMessage[]>(`/InfoMessage/Chain/${chainId}`);
        return items.map(mapInfoMessage);
            })

const count = 100;
export const fetchInfoMessagesForOverview = createAsyncThunk<InfoMessage[], void, ThunkArgs>
    ('infoMessage/getOverview',
    async (_, thunkApi) => {
        const { data: items } = await xhr.get<ServerInfoMessage[]>(`/InfoMessage?count=${count}`);

        var uniqueIds = Array.from(new Set(items.map(item => item.customerId)));

        const state = thunkApi.getState();
        thunkApi.dispatch(fetchMissingCustomerIds(uniqueIds.filter(id => !(id in state.customer))));

        return items.map(mapInfoMessage);
    })
