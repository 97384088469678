import { createTimeStamp, isExpired } from "@src/lib/time-stamp"
import { Projector } from "@states/projector"
import { track } from "mummet-core"

export const SCREENSHOT_MAX_AGE_IN_SECONDS = 60

export const PLAYLIST_MAX_AGE = 1000 * 60
export const PLAYLIST_FETCH_TIMEOUT = 1000 * 60 * 2
export const PLAYLIST_FETCH_INTERVAL = 1000 * 10

export function isProjector(projector: any): projector is Projector {
    return projector?.type === "projector"
}

export const projectorScreenshotFromUrl = (url: string | null) => {
    return url === null
        ? null
        : {url, ...createTimeStamp(SCREENSHOT_MAX_AGE_IN_SECONDS * 1000)}
}

export const projectorPlaylistFromGuid = (guid: string | null) => {
    return guid === null
        ? null
        : {guid, ...createTimeStamp(PLAYLIST_MAX_AGE)}
}

export const getActiveScreenshot = (projector: Projector) => {
    if (!projector.screenshot || projector.screenshot === 'loading')
        return null

    return !isExpired(projector.screenshot)
        ? projector.screenshot
        : null
}

export const getActivePlaylist = (projector: Projector) => {
    if (!projector.playlist || projector.playlist === 'loading')
        return null

    return !isExpired(projector.playlist)
        ? projector.playlist
        : null
}

export const playPlaylistPreview = (guid: string) =>
    window.open(`shoppa://playlist/play/${guid}`, '_blank')

export const formatChannelNames = (projector: Projector | null): string => {
    return projector?.channels.join(" > ") ?? ""
}

    /**
     * Maps a ProjectorDetailsViewModel from the server to a {@link Projector}.
     */
    export const projectorFromServerModel = (model: any): Projector | null => {
        if (model === null || model === undefined)
            return null

        if (Object.keys(model).length === 0)
            return null

        return {
            type: "projector",

            name: model.name,
            numericalId: model.numericalId,
            index: model.index,

            ip: model.ip,
            serialNumber: model.serialNumber,

            lastSeen: model.lastSeen,
            installedVersion: model.installedVersion,
            hasOfflineAlarm: model.hasOfflineAlarm,

            channels: model.channels,
            orientation: model.orientation,

            broken: track(model.isBroken ? {reason: model.brokenReason} : null),

            screenshot: null,
            playlist: null
        }
    }