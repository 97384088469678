import { InfoMessageBox } from "@features/info-message-box/info-message-box"
import { deviceToTarget } from "@src/lib/device/device-util"
import { useAppSelector } from "@states/hooks"
import { selectLoadedProjectors } from "@states/projector/projector-selectors"
import * as React from "react"

interface Props {
    projectorMacs: string[]
}

export const MultiProjectorInfo = (props: Props) => {
    const projectors = useAppSelector(selectLoadedProjectors)

    return <>
        <h3>{props.projectorMacs.length} Projectors</h3>
        {props.projectorMacs.map(projectorMac => {
            const projector = projectors[projectorMac] ?? null
            if (projector === null) return null

            const target = deviceToTarget(projector)
            if (target === null) return null

            return <div key={projector.numericalId}>
                <h2>Projector: {projector.name}</h2>
                <InfoMessageBox target={target}/>
            </div>
        })}
    </>
}