import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "@reduxjs/toolkit";
import { Modal } from "../../components/modal";
import { selectChains, selectCustomers } from "./chain-tree-selectors";
import { setChainWithSideEffects } from "../customer-tree";
import { actions } from "@features/customer-tree/customer-tree-slice"
import { ChainItem } from "./components/chain-item";
import { RootState } from "../../states/state";

const memoizedChains = createSelector([selectCustomers], selectChains)
//const memoizedStatus = createSelector([memoizedChains], aggregatedStatus)

export const ChainDialog = () => {
    const show = useSelector((state: RootState) => state.customerTree.showChainDialog)
    let chains = useSelector(memoizedChains)
    const selectedChainId = useSelector((state: RootState) => state.customerTree.selectedChainId)
    //const loadingStatus = useSelector(memoizedStatus)

    const dispatch = useDispatch()
    const [filter, setFilter] = React.useState("")
    const [activeChainId, setActiveChainId] = React.useState<number | null>(null)

    const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFilter(e.target.value)
    }

    const onChainSelect = (id: number) => dispatch(setChainWithSideEffects(id));
    const onDialogClose = () => dispatch(actions.showChainDialog(false))

    const inputRef = React.useRef<HTMLInputElement | null>(null)
    const setInputRef = React.useCallback((input: HTMLInputElement) => {
        if (input) {
            setActiveChainId(selectedChainId)
            input.focus()
            input.select()
        }

        inputRef.current = input
    }, [])
    
    if (filter !== "") {
        const lowerCaseFilter = filter.toLowerCase();
        chains = chains.filter(c => c.customerId === selectedChainId || c.name.toLowerCase().indexOf(lowerCaseFilter) !== -1)

        if (chains.every(c => c.customerId !== activeChainId)) {
            setActiveChainId(selectedChainId)
        }
    }

    const onFilterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === "ArrowUp") {
            e.preventDefault()
            if (chains.length > 1) {
                let index = chains.findIndex(c => c.customerId === activeChainId) - 1
                if (index < 0) index = chains.length - 1
                setActiveChainId(chains[index].customerId)
            }
        }
        else if (e.key === "ArrowDown") {
            e.preventDefault()
            if (chains.length > 1) {
                let index = chains.findIndex(c => c.customerId === activeChainId) + 1
                if (index === chains.length) index = 0
                setActiveChainId(chains[index].customerId)
            }
        }
        else if (e.key === "Enter") {
            e.preventDefault()
            if (activeChainId && activeChainId !== selectedChainId) {
                onChainSelect(activeChainId)
            }
        }
    }

    return (
        <Modal show={show} onClose={onDialogClose} className="modal--chain-list">
            <label>Filter</label>
            <input type="text" ref={setInputRef} value={filter} onChange={onFilterChange} onKeyDown={onFilterKeyDown} placeholder="Filter chains..." />

            <h3>Chains</h3>
            <div className="chain-list">
                {chains.map(c =>
                    <ChainItem key={c.customerId}
                        customer={c}
                        onClick={onChainSelect}
                        isSelected={c.customerId === selectedChainId}
                        isActive={c.customerId === activeChainId}
                    />
                )}
            </div>
        </Modal>
    )
}
