import { Dictionary } from "@reduxjs/toolkit"
import { Device } from "@src/lib/device/device-types"
import { TimeStamp } from "@src/lib/time-stamp"
import { Tracked } from "mummet-core/dist/types"

// key is projector mac
export interface ProjectorsState {
    loaded: Dictionary<Projector>,
    fetching: string[]
}

export type TimeStampedUrl = {url: string} & TimeStamp
export type TimeStampedGuid = {guid: string} & TimeStamp

export interface Projector extends Device {
    type: "projector",

    name: string,
    index: number,

    channels: string[],
    orientation: Orientation,

    screenshot: null | 'loading' | TimeStampedUrl,
    playlist: null | 'loading' | TimeStampedGuid
}

export enum Orientation {
    Landscape = 1,
    LandscapeInverted = 2,
    Portrait = 3,
    PortraitInverted = 4
}