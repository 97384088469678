import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CustomerDeviceOverviewState, DeviceOverviewState } from "@states/device-overview/device-overview-types"

const INITIAL_STATE: DeviceOverviewState = {}

const slice = createSlice({
    name: 'device',
    initialState: INITIAL_STATE,
    reducers: {
        setSelectedDevice: (state, action: PayloadAction<
            {customerId: number, selected: null | 'director' | {projectorMac: string}}>) => {

            const customerId = action.payload.customerId
            const selected = action.payload.selected

            const overview = getOrCreateOverviewForCustomer(state, customerId)
            overview.selected = selected
        },
        setProjectorChecked: (state, action: PayloadAction<
            {customerId: number, projectorMac: string, checked: boolean}>) => {

            const customerId = action.payload.customerId
            const projectorMac = action.payload.projectorMac
            const checked = action.payload.checked

            const overview = getOrCreateOverviewForCustomer(state, customerId)

            if (checked) {
                if (!overview.checkedProjectorMacs.includes(projectorMac))
                    overview.checkedProjectorMacs = [...overview.checkedProjectorMacs, projectorMac]
            } else {
                if (overview.checkedProjectorMacs.includes(projectorMac))
                    overview.checkedProjectorMacs = overview.checkedProjectorMacs.filter(mac => mac !== projectorMac)
            }
        }
    }
})

function getOrCreateOverviewForCustomer(state: DeviceOverviewState, customerId: number): CustomerDeviceOverviewState {
    const existing = state[customerId] ?? null

    if (existing !== null)
        return existing

    return state[customerId] = {
        selected: null,
        checkedProjectorMacs: []
    }
}

export const actions = slice.actions
export const reducer = slice.reducer