import * as React from "react"
import { connect } from "react-redux"
import { RootState } from "@states/state";
//import { BrightTimesSettings } from "./brighttimes-settings";
//import { Menu } from "../../components/menu";

//const items = [{ id: 'Brighttimes', name: 'Brighttimes', icon: 'lightbulb' }]

const mapStateToProps = (state: RootState) => ({
    isDisplayed: state.customerTree.selectedPageTab === "Dashboard",
    //items,
    //selected: items[0],
    //chainId: state.customerTree.selectedChainId
})

type Props = ReturnType<typeof mapStateToProps>

const DashboardPage = (props: Props) => {
    if (!props.isDisplayed)
        return null;

    return (
        <div className="page page--dashboard">
            <h1>Dashboard</h1>
            <div className="container">
                <section className="container__left">
                    <h2>Dashboard level 2</h2>
                    {/*<Menu items={props.items} selected={props.selected} />*/}
                </section>
                <section className="container__right">
                    {/*<BrightTimesSettings chainId={props.chainId} />*/}
                    <p>Dashboard information here</p>
                </section>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(DashboardPage)
