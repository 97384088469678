import { Target } from "@src/lib/targetable"
import { RootState } from "@states/state"
import * as React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import { Store } from "redux"
import FA from "../../components/fa"
import { InfoMessageStatusIcon } from "../info-message-status-icon"
import { AlarmIcon } from "./alarm-icon"

interface Props {
    target: Target,
    customerId: number,
    displayScenesIcon: boolean
}

export const DeviceStatusIcons = (props: Props) => {
    const scenesIconClassModifier = props.displayScenesIcon ? "" : " disabled"

    return <>
        <InfoMessageStatusIcon target={props.target} />
        <AlarmIcon customerId={props.customerId} target={props.target} />
        <FA className={`dt-status__icon__scenes${scenesIconClassModifier}`} icon={"film"} fixedWidth={true} />
    </>
}

export function renderDeviceStatusIcons(store: Store<RootState>, root: Element | null, props: Props) {
    if (!root) return void 0
    return render(
        <Provider store={store}>
            <DeviceStatusIcons target={props.target} customerId={props.customerId} displayScenesIcon={props.displayScenesIcon} />
        </Provider>,
        root
    )
}