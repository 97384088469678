import { Tracked } from "mummet-core/dist/types";
import { DateString } from "../../lib/date-string/date";
import { Target, TargetType } from "../../lib/targetable";

export type ServerInfoMessage = {
    customerId: number,
    hasContent: boolean,
    hasReminder: boolean,
    hasText: boolean,
    id: number,
    reminderDate: string,
    status: InfoMessageReminderStatus,
    targetId: number,
    targetType: TargetType,
    text: string
}

export interface InfoMessagesState {
    messages: Tracked<InfoMessage>[]
}

export interface InfoMessageDraft {
    text: string,
    target: Target,
    reminder: InfoMessageReminder | null,
    customerId: number
}

export interface InfoMessage extends InfoMessageDraft {
    /** Id of message, undefined if the message is a draft. */
    id?: number
}

export interface InfoMessageReminder {
    date: DateString,
    status: InfoMessageReminderStatus
}

export enum InfoMessageReminderStatus {
    Open = 1,
    Closed = 2
}