import MainPage from "./main-page";
import DevicesRepairCentre from "./devices-repair-centre";
import CommandCreator from "./command-creator";
import Logs from "./logs";

var RunningCommands = {
    knownRunningCommands: <number[]>[],
    intervalId: null as number | null,
    updateRunningCommandsView: function () {
        var $target = $('#repair-commands-list');
        $target.empty();
        $.ajax({
            url: "/Main/GetRunningCommandsView",
            type: "POST",
            data: JSON.stringify({ 'selectedDevices': DevicesRepairCentre.selectedDevices, 'customerId': DevicesRepairCentre.loadedCustomerId }),
            dataType: "html",
            contentType: "application/json; charset=utf-8",
            success: function (result) {
                $target.html(result);
                MainPage.checkIfLoggedInAfterAjax($target);
                RunningCommands.updateKnownRunningCommandsList();
            },
            error: function () {
                alert("Error in: GetRunningCommandsView()");
            }
        });
    },
    updateKnownRunningCommandsList: function () {
        var newList = <number[]>[];
        $('.repair-running-command-item').each(function (index, value) {
            var $curElem = $(value);
            var cmdId = parseInt($curElem.attr('data-id')!);
            newList.push(cmdId);
        });

        if (RunningCommands.knownRunningCommands.length > 0) {
            var listsAreEqual = $(newList).not(<any>RunningCommands.knownRunningCommands).length === 0 && $(RunningCommands.knownRunningCommands).not(<any>newList).length === 0; //MAAS: .not() does not support 'number[]' arguments
            if (!listsAreEqual) {
                if (DevicesRepairCentre.selectedTab === 'commands') {
                    if (CommandCreator.isViewingCommandData) {
                        CommandCreator.shouldReloadCommandViewAfterDialog = true;
                    }
                    else {
                        CommandCreator.reloadCommandLog();
                    }
                }
                else if (DevicesRepairCentre.selectedTab === 'logs') {
                    //alert('reload log view');
                    if ($('.log-file-info').length || $('.device-log-content').length) {
                        Logs.shouldReloadLogViewAfterDialog = true;
                    }
                    else {
                        Logs.doSearch();
                    }
                }
            }
        }
        RunningCommands.knownRunningCommands = newList;
        if (RunningCommands.knownRunningCommands.length > 0) {
            // start loop
            if (RunningCommands.intervalId == null) {
                RunningCommands.startTicTockLoop();
            }
        }
        else if (RunningCommands.intervalId != null) {
            RunningCommands.stopTickTockLoopAndClearId();
        }
    },
    // Set a timeout waiting for commands to finish and update the gui with info
    startTicTockLoop: function () {
        var timerTimeout = 1000; // 1 Second
        RunningCommands.intervalId = window.setInterval(function () {
            var timeIsZero = false;

            $('.repair-command-sub-item-time').each(function (index, value) {
                var $curElem = $(value);
                var time = parseInt($curElem.text());
                time -= 1;
                if (time <= -1)
                    timeIsZero = true;
                $curElem.text(time);
            });

            if (timeIsZero) {
                RunningCommands.stopTickTockLoopAndClearId();
                RunningCommands.updateRunningCommandsView();
            }
        }, timerTimeout);
    },
    stopTickTockLoopAndClearId: function () {
        clearInterval(RunningCommands.intervalId!);
        RunningCommands.intervalId = null;

    },
    clear: function () {
        RunningCommands.knownRunningCommands = [];
        if (RunningCommands.intervalId != null) {
            RunningCommands.stopTickTockLoopAndClearId();
        }
    }
};

export default RunningCommands;
