import { selectCustomerId } from "@features/customer-tree/customer-tree-selectors"
import { DeviceButtonList } from "@features/device-details/components/buttons/device-button-list"
import { DeviceInfo } from "@features/device-details/components/device-info/device-info"
import { InfoMessageBox } from "@features/info-message-box/info-message-box"
import { TargetType } from "@src/lib/targetable"
import { memoizedActiveDevices, memoizedActiveLoadedDevices } from "@states/device-overview/device-overview-selectors"
import { Director } from "@states/director/director-types"
import { isDirector } from "@states/director/director-util"
import { useAppSelector } from "@states/hooks"
import { Projector } from "@states/projector"
import { isProjector } from "@states/projector/projector-util"
import { selectCurrentUserRoles } from "@states/user"
import * as React from "react"
import { useMemo } from "react"

export const DeviceDetails = () => {
    const activeDevices = useAppSelector(memoizedActiveDevices)
    const activeLoadedDevices = useAppSelector(memoizedActiveLoadedDevices)
    const customerId = useAppSelector(selectCustomerId)
    const userRoles = useAppSelector(state => selectCurrentUserRoles(state.user))

    const storeTarget = { id: customerId, type: TargetType.Customer }
    const jqueryDialogs = useMemo(() => createJqueryDialogs(activeLoadedDevices), [activeLoadedDevices])

    if (activeDevices === null)
        return null

    return <>
        <div className="row">
            <div className="column columns-8">
                <InfoMessageBox target={storeTarget}/>
                <DeviceInfo device={activeDevices} customerId={customerId}/>
            </div>

            <div className="column columns-4">
                <DeviceButtonList
                    device={activeLoadedDevices}
                    customerId={customerId}
                    userRoles={userRoles}/>
            </div>
        </div>
        {jqueryDialogs}
    </>
}

const createJqueryDialogs = (devices: Director | Projector[] | null) => {
    if (devices === null)
        return null

    const director = isDirector(devices) ? devices : null
    const singleProjector = Array.isArray(devices) && devices.length === 1 && isProjector(devices[0]) ? devices[0] : null
    const multiProjectors = Array.isArray(devices) && devices.length > 1 ? devices : null

    let content: JSX.Element

    if (director !== null)
        content = createDirectorJqueryDialogs(director)
    else if (singleProjector !== null)
        content = createProjectorJqueryDialogs(singleProjector)
    else if (multiProjectors !== null)
        content = createMultiProjectorJqueryDialogs(multiProjectors)
    else
        return null

    return <>
        {createSharedJqueryDialogs()}
        {content}
    </>
}

const createSharedJqueryDialogs = () => <>
    <div id="device-acknowledge-action-dialog" title="Acknowledge Error" style={{ display: "none" }}>
        <p>Are you sure you wish to acknowledge the following:</p>
        <p id="device-acknowledge-action-text"/>
        <p>By aknowledging the error you say you are aware of the error but have reasons not to adress it. The specific error will no longer be shown in SunFlow.</p>
    </div>
    <div id="device-goto-action-dialog" title="Action is necessary" style={{display: "none"}}/>
</>

const createDirectorJqueryDialogs = (director: Director) => <>
    <div id="device-mark-broken-dialog" title="Mark Broken" style={{ display: "none" }}>
        <p>Please enter the MAC of the replacement Director.</p>
        <label htmlFor="device-mark-broken-replacement-mac">MAC: </label>
        <input id="device-mark-broken-replacement-mac" type="text" maxLength={12} data-current-mac={director.replacementMac} />
        <br/>
        <label htmlFor="device-mark-broken-reason">Reason: </label>
        <input id="device-mark-broken-reason" type="text" maxLength={200} />
    </div>

    <div id="device-restart-dialog" title="Confirm Restart" style={{display: "none"}}>
        <p id="device-restart-director-text">Are you sure you want to restart the Director?</p>
    </div>

    <div id="device-factory-reset-dialog" title="Confirm Factory Reset" style={{display: "none"}}>
        <p>Are you sure you want to factory reset the Director?</p>
    </div>

    <div id="delete-director-dialog" title="Confirm Delete" style={{display: "none"}}>
        <p>Are you sure you want to delete the Director?</p>
    </div>
</>

const createProjectorJqueryDialogs = (projector: Projector) => <>
    <div id="device-mark-broken-dialog" title="Mark Broken" style={{ display: "none" }}>
        <p>Are you sure you want to mark {projector.name} as broken?</p>
        <label>Reason: <input id="device-mark-broken-reason" type="text" maxLength={200}/></label>
    </div>

    <div id="device-restart-dialog" title="Confirm Restart" style={{display: "none"}}>
        <p id="device-restart-projector-text">Are you sure you want to restart {projector.name}?</p>
    </div>
</>

const createMultiProjectorJqueryDialogs = (projectors: Projector[]) => <>
    <div id="device-mark-broken-dialog" title="Mark Broken" style={{ display: "none" }}>
        <p id="device-mark-broken-projector-text">{`Are you sure you want to mark all ${projectors.length} selected projectors as broken?`}</p>
        <label>Reason: <input id="device-mark-broken-reason" type="text" maxLength={200} /></label>
    </div>

    <div id="device-restart-dialog" title="Confirm Restart" style={{display: "none"}}>
        <p id="device-restart-projector-text">{`Are you sure you want to restart all ${projectors.length} selected projectors?`}</p>
    </div>
</>