import * as React from "react"
import { connect } from "react-redux"
import { RootState } from "@states/state";
import ChannelRow from "./channel-row";
import { selectChannels, selectTopLevelCustomerChannels } from "../../states/channel/channel-selectors";
import { createSelector } from "@reduxjs/toolkit";
import { selectCustomerId } from "../../features/customer-tree/customer-tree-selectors";

const memoizedCurrentCustomerChannels = createSelector(
    [selectChannels, selectCustomerId],
    selectTopLevelCustomerChannels
)

const mapStateToProps = (state: RootState) => ({
    channels: memoizedCurrentCustomerChannels(state)
})

type Props = ReturnType<typeof mapStateToProps>

const ChannelTable = (props: Props) => {
    return (
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th className="text-align-left">Channel name</th>
                    <th className="text-align-left">Content status</th>
                    <th className="text-align-left">Fallback playlist</th>
                    <th className="text-align-left">Brighttime</th>
                    <th>Lock</th>
                </tr>
            </thead>
            <tbody>
                {props.channels.map(channel => <ChannelRow key={channel.id} channel={channel} parents={[]} />)}
            </tbody>
        </table>
    )
}

export default connect(mapStateToProps)(ChannelTable)
