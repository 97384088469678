import CustomerTree from "@features/customer-tree/customer-tree"
import DirectorOverview from "@features/director-overview/director-overview"
import { alertGenericErrorAndReloadPage } from "@src/error-handling"
import Broken from "./broken"
import CommandCreator from "./command-creator"
import Logs from "./logs"
import MainPage from "./main-page"
import RunningCommands from "./running-commands"
import SunFlowMain from "./sunflow-main"

var DevicesRepairCentre = {
    loadedCustomerId: -1,
    selectedDevices: null as string[] | null,
    clickingOnTab: false,
    selectedTab: '',
    loadedTab: '',
    workMode: '',
    requestViewChange: function (newCustomerId: number, selectedDevices: string[], targetView: string, workMode: string) {
        if (newCustomerId != DevicesRepairCentre.loadedCustomerId) {

            var $newOverlay = $('<div class="repair-centre-overlay hidden"></div>');
            $newOverlay.appendTo($('body'));
            DevicesRepairCentre.loadedCustomerId = newCustomerId;
            DevicesRepairCentre.selectedDevices = selectedDevices;
            DevicesRepairCentre.workMode = workMode;
            $newOverlay = $(".repair-centre-overlay");

            MainPage.fadeInOverlay();
            $.ajax({
                url: "/Main/DevicesRepairCentre",
                type: "POST",
                data: JSON.stringify({ 'customerId': newCustomerId, 'customerName': CustomerTree.getSelectedCustomerName(), 'selectedDevices': selectedDevices, 'targetView': targetView, 'workMode': workMode }),
                dataType: "html",
                contentType: "application/json; charset=utf-8",
                success: function (result) {
                    DevicesRepairCentre.placeOverlayWindow();
                    $newOverlay.fadeIn(100);
                    $newOverlay.html(result);
                    DevicesRepairCentre.loadedTab =
                        DevicesRepairCentre.selectedTab = $('.repair-menu-item-selected').attr('data-tab') as string;
                    if (DevicesRepairCentre.selectedTab === 'commands') {
                        CommandCreator.initializeAndBindEvents();
                    }
                    else if (DevicesRepairCentre.selectedTab === 'logs') {
                        Logs.initializeAndBindEvents();
                    }
                    MainPage.checkIfLoggedInAfterAjax($newOverlay);
                    DevicesRepairCentre.bindEvents();
                    MainPage.registerResizeCallback('repair-overlay-resize', DevicesRepairCentre.placeOverlayWindow);
                    MainPage.registerOutsideClickCallback('repair-overlay-click-outside', DevicesRepairCentre.clickOutside);
                    RunningCommands.updateKnownRunningCommandsList();
                },
                error: function () {
                    $newOverlay.remove();
                    alertGenericErrorAndReloadPage()
                },
                complete: function () {
                    MainPage.fadeOutOverlay();
                }
            });
        }
    },
    loadNewTab: function (tabToLoad: string) {
        if (tabToLoad != DevicesRepairCentre.loadedTab) {
            var $target = $('#repair-main-view');

            var initDelegate = null as Function | null;
            var urlToLoad = null, dataToUse = null;
            if (tabToLoad == 'commands') {
                urlToLoad = '/Main/DeviceRepairCommands';
                Logs.clear();
                Logs.unbindEvents();
                initDelegate = CommandCreator.initializeAndBindEvents as Function;
                dataToUse = { 'selectedDevices': DevicesRepairCentre.selectedDevices, 'customerId': DevicesRepairCentre.loadedCustomerId };
            }
            else if (tabToLoad == 'logs') {
                var urlToLoad;
                if (DevicesRepairCentre.workMode == 'live') {
                    urlToLoad = '/Main/DeviceRepairLogs';
                }
                else if (DevicesRepairCentre.workMode == 'broken') {
                    urlToLoad = '/Main/DeviceRepairLogHistory';
                }

                CommandCreator.clear();
                CommandCreator.unbindEvents();
                initDelegate = Logs.initializeAndBindEvents as Function;
                dataToUse = { 'selectedDevices': DevicesRepairCentre.selectedDevices, 'customerId': DevicesRepairCentre.loadedCustomerId };
            }

            if (urlToLoad != null) {

                $('#repair-container').toggleClass('loading', true);
                $.ajax({
                    url: urlToLoad,
                    type: "POST",
                    dataType: "html",
                    data: JSON.stringify(dataToUse),
                    contentType: "application/json; charset=utf-8",
                    success: function (result) {
                        $target.html(result);
                        MainPage.checkIfLoggedInAfterAjax($target);
                        DevicesRepairCentre.loadedTab = tabToLoad;
                        if (initDelegate != null) {
                            initDelegate();
                        }
                    },
                    error: function () {
                        alert("An error has occured!!!");
                    },
                    complete: function () {
                        $('#repair-container').toggleClass('loading', false);
                    }
                });
            }
        }
    },
    bindEvents: function () {
        $('.repair-menu-item').on('mousedown', function (e) {
            if (e.which == 1) {
                var $tab = $(this);
                if ($tab.hasClass('repair-menu-item-selected')) {
                    return;
                }
                else {
                    e.stopPropagation();
                    e.preventDefault();
                    DevicesRepairCentre.clickingOnTab = true;
                    $tab.removeClass('repair-menu-item-normal');
                    $tab.addClass('repair-menu-item-clicking');
                }
            }
        });
        $('.repair-menu-item').on('mouseup', function (e) {
            if (DevicesRepairCentre.clickingOnTab) {
                DevicesRepairCentre.clickingOnTab = false;

                var $oldTab = $('.repair-menu-item-selected');
                if ($oldTab.length) {
                    $oldTab.removeClass('repair-menu-item-selected');
                    $oldTab.addClass('repair-menu-item-normal');
                }
                var $tab = $(this);
                $tab.removeClass('repair-menu-item-clicking');
                $tab.addClass('repair-menu-item-selected');
                DevicesRepairCentre.selectedTab = $tab.attr('data-tab') as string;
                DevicesRepairCentre.loadNewTab(DevicesRepairCentre.selectedTab);
            }
        });
        $('.repair-menu-item').on('mouseleave', function (e) {
            if (DevicesRepairCentre.clickingOnTab) {
                DevicesRepairCentre.clickingOnTab = false;
                var $tab = $(this);
                $tab.removeClass('repair-menu-item-clicking');
                $tab.addClass('repair-menu-item-normal');
            }
        });
        $('.overlay-close-button').on('click', function (e) {
            DevicesRepairCentre.leaveRepairCentre();
        });
        $('#repair-go-back').on('click', function (e) {
            DevicesRepairCentre.leaveRepairCentre();
        });
        $('.repair-side-item').on('click', function (e) {
            var $curItem = $(this);
            if ($curItem.parents('#repair-actions-view').length) {
                var hash = $curItem.attr('data-action-hash') as string;
                var type = $curItem.attr('data-action-type') as string;
                var devices = $curItem.data('action-devices');
                DevicesRepairCentre.goToAction(devices, type, hash);
            }
            else if ($curItem.parents('#repair-devices-view').length) {
                var device = $curItem.attr('data-device-id') as string;
                DevicesRepairCentre.goToDevice(device);
            }
        });
    },
    goToAction: function (devices: string[], actionType: string, actionhash: string) {
        switch (actionType) {
            case '1':
            case '3':
            case '2':
            case '6':
            case '7':
                DevicesRepairCentre.highlightDevices(devices);
                break;
            case '5':
                DevicesRepairCentre.highLightErrorLogs(devices);
                break;
            case '8':
                DevicesRepairCentre.showDirectorIsOfflineDialog(actionType);
                break;
            case '4':
            case '0':
            default:
                break;

        }
    },
    showDirectorIsOfflineDialog: function (actionType: string) {
        $.ajax({
            url: '/Main/GetActionInfo/',
            type: "POST",
            data: JSON.stringify({ 'actionType': actionType }),
            dataType: "html",
            contentType: "application/json; charset=utf-8",
            success: function (result) {
                var $result = $(result);
                MainPage.checkIfLoggedInAfterAjax($result);
                var $dialog = $('#device-goto-action-dialog');
                $dialog.html(result);

                $dialog.dialog({
                    appendTo: '#repair-container',
                    resizable: false,
                    draggable: false,
                    height: 'auto',
                    width: 600,
                    modal: true,
                    buttons: [{
                        text: 'Leave ' + DevicesRepairCentre.loadedTab,
                        click: function () {
                            $(this).dialog('destroy');
                            DevicesRepairCentre.leaveRepairCentre();
                        }
                    },
                    {
                        text: 'Cancel',
                        click: function () {
                            $(this).dialog('destroy');
                        }
                    }]
                });

            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert("An error has occured!!!");
            }
        });
    },
    highLightErrorLogs: function (devices: string[]) {
        if (DevicesRepairCentre.loadedTab !== 'logs') {

            var $oldTab = $('.repair-menu-item-selected');
            var $tab = $('.repair-menu-item-normal');
            if ($oldTab.length) {
                $oldTab.removeClass('repair-menu-item-selected');
                $oldTab.addClass('repair-menu-item-normal');
            }
            $tab.removeClass('repair-menu-item-normal');
            $tab.addClass('repair-menu-item-selected');
            DevicesRepairCentre.selectedTab = 'logs';
            DevicesRepairCentre.loadNewTab('logs');
        }
        Logs.highLightErrorLogs(devices);
    },
    goToDevice: function (device: string) {
        var devices = [];
        devices.push(device);
        DevicesRepairCentre.highlightDevices(devices);
    },
    highlightDevices: function (devices: string[]) {
        if (DevicesRepairCentre.loadedTab === 'logs') {
            for (var i = 0; i < devices.length; i++) {
                var $divToHighlight = $(".repair-log-item[data-device-id='" + devices[i] + "']");
                if (i == 0) {
                    SunFlowMain.scrollToSelectedItem($('#repair-log-logs'), $divToHighlight);
                }
                $divToHighlight.delay(300).effect('highlight', 800);
            }

        }
        else if (DevicesRepairCentre.loadedTab === 'commands') {
            for (var i = 0; i < devices.length; i++) {
                var $divToHighlight = $(".repair-command-log-item[data-device-id='" + devices[i] + "']");
                if (i == 0) {
                    SunFlowMain.scrollToSelectedItem($('#repair-command-log-container'), $divToHighlight);
                }
                $divToHighlight.delay(300).effect('highlight', 800);
            }
        }
    },
    unbindEvents: function () {
        $('.repair-menu-item').unbind('mousedown');
        $('.repair-menu-item').unbind('mouseup');
        $('.repair-menu-item').unbind('mouseleave');
        $('.overlay-close-button').unbind('click');
        $('#repair-go-back').unbind('click');
        $('.repair-side-item').unbind('click');
    },
    placeOverlayWindow: function () {
        var $overlay = $('.repair-centre-overlay')
        var $parent = $('#inner-content');
        var $title = $('#title');
        var inset = 4;
        $overlay.height(($parent.height()! - $title.height()! - inset * 2));
        $overlay.width($parent.width()! - inset * 2);
        var offset = $parent.offset();
        $overlay.css({ left: offset!.left + inset, top: offset!.top + $title.height()! + inset })
    },
    clickOutside: function (compareUpTarget: HTMLElement, compareDownTarget: HTMLElement) {
        var $overlay = $('.repair-centre-overlay');
        if (MainPage.isTargetOutsideElement(compareUpTarget, $overlay) && MainPage.isTargetOutsideElement(compareDownTarget, $overlay)) {
            DevicesRepairCentre.leaveRepairCentre();
        }
    },
    leaveRepairCentre: function () {
        var workMode = DevicesRepairCentre.workMode;

        DevicesRepairCentre.unbindEvents();
        DevicesRepairCentre.clear();

        MainPage.unregisterResizeCallback('repair-overlay-resize');
        MainPage.unregisterOutsideClickCallback('repair-overlay-click-outside');

        CommandCreator.unbindEvents();
        CommandCreator.clear();
        RunningCommands.clear();
        Logs.unbindEvents();
        Logs.clear();

        var $overlay = $('.repair-centre-overlay')
        $overlay.fadeOut(100, function () {
            $overlay.remove();
        });

        if (workMode === 'live') {
            DirectorOverview.refreshView();
        }
        else if (workMode === 'broken') {
            Broken.refreshView();
        }
    },
    clear: function () {
        DevicesRepairCentre.loadedCustomerId = -1;
        DevicesRepairCentre.selectedDevices = null;
        DevicesRepairCentre.clickingOnTab = false;
        DevicesRepairCentre.selectedTab = '';
        DevicesRepairCentre.loadedTab = '';
        DevicesRepairCentre.workMode = '';
    }
};

export default DevicesRepairCentre;