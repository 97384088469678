export interface Channel {
    id: number
    parentId: number | null
    children: number[]
    customerId: number
    name: string
    brightTimeId?: number
    locked: boolean
    local: boolean
    materialStatus: MaterialStatus
    fallbackPlaylistName: string
}

export enum MaterialStatus {
    Unknown = 0,
    GotMaterial = 1,
    SoonEmpty = 2,
    RecentlyEmpty = 3,
    Empty = 4
}

export const MaterialStatusTexts = [
	'Unknown', 
	'Got material', 
	'Soon empty', 
	'Recently empty', 
	'Empty'
];

export type ServerChannel = {
    id: number
    name: string
    customerId: number
    guid: string
    parentGuid: string
    changed: string
    depricated: boolean
    countryCode: string
    local: boolean
    disablePublication: boolean
    brightTimeId?: number
    materialStatus: MaterialStatus
    fallbackPlaylistName: string
}
