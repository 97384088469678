import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@states/state";
import { track } from "mummet-core";
import { Tracked } from "mummet-core/dist/types";
import { isPresent } from "ts-is-present";
import { areTargetsEqual, Target, TargetType } from "../../lib/targetable";
import { InfoMessage, InfoMessageReminderStatus } from "./info-message-types";

export const selectMessages = (state: RootState) => state.infoMessage.messages;

export const selectMessagesAsList = (state: RootState) => Object.keys(state.infoMessage.messages).map(id => state.infoMessage.messages[+id].current);

export const selectMessageOrNullFromId = (messages: Tracked<InfoMessage>[], id: number): Tracked<InfoMessage> | null =>
    messages.find(msg => msg.current && msg.current.id === id) || null;

export const selectMessageOrNull = (messages: Tracked<InfoMessage>[], target: Target): Tracked<InfoMessage> | null =>
    messages.find(msg => areTargetsEqual(msg, target)) || null;

export const selectMessageOrEmpty = (messages: Tracked<InfoMessage>[], target: Target, customerId: number) =>
    selectMessageOrNull(messages, target) || track(createEmptyMessage(target, customerId));

/** Selects whether the reminder is due on the same day as the current date. */
export const selectIsReminderDue = (message: Tracked<InfoMessage>) => {
    if (!message.current?.reminder)
        return false;

    const reminderDate = new Date(message.current.reminder.date);
    reminderDate.setHours(0, 0, 0, 0);

    const currentDate = new Date();
    currentDate.setHours(0, 0);

    return currentDate >= reminderDate;
}

export const selectIsReminderDirty = (message: Tracked<InfoMessage>) => {
    const currentHasReminder = message.current !== null && message.current.reminder !== null;
    const underlyingHasReminder = message.underlying !== null && message.underlying.reminder !== null;

    if (!currentHasReminder || !underlyingHasReminder)
        return currentHasReminder !== underlyingHasReminder;

    return message.current!.reminder!.date !== message.underlying!.reminder!.date ||
        message.current!.reminder!.status !== message.underlying!.reminder!.status;
}

export const selectHasOpenReminder = (message: Tracked<InfoMessage>) =>
    message.current?.reminder?.status === InfoMessageReminderStatus.Open

export const selectIsTextDirty = (message: Tracked<InfoMessage>) => {
    return message.current?.text !== message.underlying?.text;
}

export const selectIsMessageEmpty = (message: Tracked<InfoMessage>) =>
    (message.current?.text.length ?? 0) === 0 && !message.current?.reminder;

export const selectMessageExistsOnServer = (message: Tracked<InfoMessage>) =>
    message.underlying!.id !== undefined;

const infoMessageSorter = (a: InfoMessage, b: InfoMessage) => {
    if (!a.reminder || !b.reminder)
        return 0;

    const reminderDateA = new Date(a.reminder.date);
    const reminderDateB = new Date(b.reminder.date);

    return reminderDateA > reminderDateB ? 1 : -1;
}

export const selectFormattedDate = (xmlDate: string) => {

    const date = new Date(xmlDate);

    const curDate = new Date();
    curDate.setHours(23, 59, 59);
    const diffDays = Math.ceil((date.getTime() - curDate.getTime()) / (1000 * 60 * 60 * 24));

    if (Math.abs(diffDays) > 7) {
        return date.toDateString();
    }
    else if (diffDays === 0) {
        return "Today";
    }
    else if (diffDays === -1) {
        return "Yesterday";
    }
    else if (diffDays === 1) {
        return "Tomorrow";
    }
    else {
        let formattedDate = "";
        if (diffDays > 0) {
            formattedDate = "In ";
        }

        formattedDate += Math.abs(diffDays) + " days";
        if (diffDays < 0) {
            formattedDate += " ago";
        }
        return formattedDate;
    }
}

export const selectDirtyMessages = (messages: Tracked<InfoMessage>[]) =>
    messages.filter(msg => selectIsReminderDirty(msg) || selectIsTextDirty(msg));

export const memoizedRemindersAsList = createSelector(
    [selectMessages],
    (messages) => messages
        .filter(msg => selectHasOpenReminder(msg))
        .map(im => im.current!)
        .sort(infoMessageSorter))

export const memoizedReminderCount = createSelector(
    [selectMessages],
    (messages) => messages
        .filter(msg => selectIsReminderDue(msg))
        .filter(msg => msg.current?.reminder?.status === InfoMessageReminderStatus.Open)
        .length)

export const memoizedInfoMessagesForScreens = createSelector(
    [selectMessages],
    (messages) => messages
        .map(msg => msg.underlying)
        .filter(isPresent)
        .filter(msg => msg.target.type === TargetType.Screen)
)

export const memoizedInfoMessagesForEngines = createSelector(
    [selectMessages],
    (messages) => messages
        .map(msg => msg.underlying)
        .filter(isPresent)
        .filter(msg => msg.target.type === TargetType.RenderEngine)
)

const createEmptyMessage = (target: Target, customerId: number): InfoMessage => ({
    target: target,
    text: "",
    customerId: customerId,
    reminder: null
});