import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { commit, rollback, track, update, updateProperty } from "mummet-core"
import { Dictionary, Tracked } from "mummet-core/dist/types"
import { actions as customerChainActions } from "../customer-chain/customer-chain-slice"
import { handleSave } from "./channel-thunks"
import { Channel } from "./channel-types"

const INITIAL_STATE: Dictionary<Tracked<Channel>> = {}

const slice = createSlice({
    name: 'channels',
    initialState: INITIAL_STATE,
    reducers: {
        setBrightTime: (state, action: PayloadAction<{ channel: number, brightTime: number }>) => {
            const { channel, brightTime } = action.payload
            return updateProperty(state, channel, "brightTimeId", brightTime)
        },
        toggleLock: (state, action: PayloadAction<{ id: number }>) => {
            return update(state, action.payload.id, channel => ({ ...channel!, locked: !channel!.locked }))
        },
        toggleLocal: (state, action: PayloadAction<{ id: number }>) => {
            return update(state, action.payload.id, channel => ({ ...channel!, local: !channel!.local }))
        },
        commit: (state, action: PayloadAction<number[]>) => {
            return commit(state, action.payload); //rollback on error is handled by "handleSave.rejected" below
        }
    },
    extraReducers: builder => {
        builder
            .addCase(customerChainActions.loaded, (state, action) => {
                const { id: chainId, channels: serverChannels } = action.payload;

                for (const ch of serverChannels) {
                    const channel = {
                        customerId: ch.customerId,
                        id: ch.id,
                        local: ch.local,
                        locked: ch.disablePublication,
                        name: ch.name,
                        brightTimeId: ch.brightTimeId,
                        fallbackPlaylistName: ch.fallbackPlaylistName,
                        materialStatus: ch.materialStatus,
                        parentId: (serverChannels.find(x => x.guid === ch.parentGuid) || { id: null }).id,
                        children: serverChannels.filter(x => x.parentGuid === ch.guid).map(x => x.id)
                    }

                    state[channel.id] = track(channel)
                }
                return state;
            }).addCase(handleSave.rejected, (state, action) => {
                for (const ch of action.payload!)
                    state = rollback(state, ch.underlying!.id, ch.underlying);
                return state;
            })
    }
})

export const actions = slice.actions;
export const reducer = slice.reducer;
