import * as React from "react";
import { TabState } from "./tab-types";

const Tab = (props: { text: string, state: TabState, onClick?: React.MouseEventHandler }) => {
    switch (props.state) {
        case TabState.Hidden:
            return null;
        case TabState.Disabled:
            return <div className="main-page-menu-button tab-button-disabled">{props.text}</div>
        case TabState.Enabled:
            return <div className="main-page-menu-button tab-button" onClick={props.onClick}>{props.text}</div>
        case TabState.Selected:
            return <div className="main-page-menu-button tab-button-selected">{props.text}</div>

        default:
            console.error("Not rendering Tab with invalid TabState", props.text, props.state)
            return null
    }
}

export default Tab