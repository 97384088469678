import { selectCustomerId } from "@features/customer-tree/customer-tree-selectors"
import deviceDetails, { renderDeviceDetailsNow } from "@features/device-details/device-details-legacy"
import DevicesRepairCentre from "@features/devices-repair-centre"
import MainPage from "@features/main-page"
import { unwrapResult } from "@reduxjs/toolkit"
import { alertError, alertGenericError, alertGenericErrorAndReloadPage } from "@src/error-handling"
import { hasPermissionForRepairCenter } from "@src/lib/role-util"
import { Target, TargetType } from "@src/lib/targetable"
import { selectCheckedProjectorMacs, selectSelectedDevice } from "@states/device-overview/device-overview-selectors"
import { actions as deviceOverviewActions } from "@states/device-overview/device-overview-slice"
import { selectLoadedDirector } from "@states/director/director-selectors"
import { fetchDirectorForCustomer } from "@states/director/director-thunks"
import { RootState } from "@states/state"
import store from "@states/store"
import { selectCurrentUserRoles } from "@states/user"
import { renderDeviceStatusIcons } from "../device-status-icons/device-status-icons"

const DirectorOverview = {
    clickingOnProjector: false,
    clickingOnDirector: false,
    requestViewChange: function (newCustomerId: number, prevSelection: string | null | string[], forceRefresh: boolean) {
        MainPage.onViewChanging()
        const $target = $("#main-page-view")

        MainPage.fadeInOverlay()
        $.ajax({
            url: "/Main/DirectorStatus",
            type: "POST",
            data: { 'customerId': newCustomerId, 'selectedDevices': prevSelection },
            dataType: "html",
            success: async function (result) {
                const state = store.getState()

                const director = await selectOrFetchDirector(state, newCustomerId).catch(() => null)

                $target.html(result)
                MainPage.checkIfLoggedInAfterAjax($target)
                MainPage.fadeOutOverlay()

                const roles = selectCurrentUserRoles(state.user)

                if (!hasPermissionForRepairCenter(roles)) {
                    removeDeviceCheckboxes()
                }

                if (director) {
                    const directorStatus = document.getElementById("director-status")
                    const directorTarget = {id: director.numericalId, type: TargetType.RenderEngine}
                    const statusIconsRoot = directorStatus?.querySelector(".engine-status-icons-root") ?? null

                    renderDeviceStatusIcons(store, statusIconsRoot, {
                        target: directorTarget,
                        customerId: newCustomerId,
                        displayScenesIcon: false
                    })

                    const selectedDevice = selectSelectedDevice(state)
                    const checkedProjectorMacs = selectCheckedProjectorMacs(state)

                    if (selectedDevice === null) {
                        store.dispatch(deviceOverviewActions.setSelectedDevice({
                            customerId: newCustomerId,
                            selected: 'director'
                        }))
                    } else if (selectedDevice !== 'director') {
                        DirectorOverview.setSelectedProjector(selectedDevice.projectorMac)
                    }

                    DirectorOverview.setCheckedProjectors(checkedProjectorMacs)
                }

                renderScreenStatusIcons(newCustomerId)
                renderDeviceDetailsNow(state)

                DirectorOverview.bindEvents()
            },
            error: function () {
                MainPage.fadeOutOverlay()
                alertGenericError()
            }
        })
    },
    bindEvents: function () {
        const $projectorDeviceRow = $('.projector .device-row')
        const $directorStatus = $('#director-status')

        $projectorDeviceRow.on('mousedown', function (e) {
            if (e.which == 1) {
                const $tab = $(this)
                if ($tab.hasClass('device-row-selected')) {
                    return
                }
                else {
                    MainPage.clickingOnProjector = true
                    $tab.removeClass('device-row-normal')
                    $tab.addClass('device-row-clicking')
                }
            }
        })
        $projectorDeviceRow.on('mouseup', function (e) {
            if (MainPage.clickingOnProjector) {
                MainPage.clickingOnProjector = false

                DirectorOverview.clearSelected()
                const $tab = $(this)
                $tab.addClass('device-row-selected')
                $tab.removeClass('device-row-clicking')

                const projectorMac = $tab.attr('data-projector-id')!

                store.dispatch(deviceOverviewActions.setSelectedDevice({
                    customerId: selectCustomerId(store.getState()),
                    selected: {projectorMac: projectorMac}
                }))
            }
        })
        $projectorDeviceRow.on('mouseleave', function (e) {
            if (MainPage.clickingOnProjector) {
                MainPage.clickingOnProjector = false
                const $tab = $(this)
                $tab.addClass('device-row-normal')
                $tab.removeClass('device-row-clicking')
            }
        })
        $projectorDeviceRow.on('dblclick', function (e) {
            const $row = $(this)
            const $checkBox = $row.find("input[type='checkbox']")
            $checkBox.prop('checked', !$checkBox.prop('checked'))
            $checkBox.change()
        })

        $(".projector .device-row input[type='checkbox']").on('change', function (e) {
            const projectorMac = e.target.parentNode!.parentElement!.dataset["projectorId"]!
            const isChecked = $(e.target).is(":checked")

            store.dispatch(deviceOverviewActions.setProjectorChecked({
                customerId: selectCustomerId(store.getState()),
                projectorMac,
                checked: isChecked
            }))
        })

        $directorStatus.on('mousedown', function (e) {
            if (e.which == 1) {
                const $tab = $(this)
                if ($tab.hasClass('device-row-selected')) {
                    return
                }
                else {
                    MainPage.clickingOnDirector = true
                    $tab.removeClass('device-row-normal')
                    $tab.addClass('device-row-clicking')
                }
            }
        })
        $directorStatus.on('mouseup', function (e) {
            if (MainPage.clickingOnDirector) {
                MainPage.clickingOnDirector = false

                DirectorOverview.clearSelected()

                const $tab = $(this)
                $tab.addClass('device-row-selected')
                $tab.removeClass('device-row-clicking')

                const state = store.getState()
                const customerId = selectCustomerId(state)

                store.dispatch(deviceOverviewActions.setSelectedDevice({
                    customerId,
                    selected: 'director'
                }))
            }
        })
        $directorStatus.on('mouseleave', function (e) {
            if (MainPage.clickingOnDirector) {
                MainPage.clickingOnDirector = false
                const $tab = $(this)
                $tab.addClass('device-status-normal')
                $tab.removeClass('device-status-clicking')
            }
        })
        $('#no-director-go-to-setup').on('click', function (e) {
            MainPage.selectTab('Setup')
        })
        $('.dt-status__icon__scenes:not(.disabled)').on('click', function (e) {
            const $isLoading = $('.projector-cell-preview-loading')
            if ($isLoading.length) {
                $isLoading.removeClass('projector-cell-preview-loading')
                $isLoading.children('img').attr('src', '/Content/images/preview.png')
            }
            const $previewElem = $(this).parent()
            $previewElem.addClass('projector-cell-preview-loading')
            $previewElem.children('img').attr('src', '/Content/images/waiting.gif')
            const $rowElem = $previewElem.closest('.device-row')

            const customerId = selectCustomerId(store.getState())
            const projectorMac = $rowElem.attr('data-projector-id')
            $.ajax({
                url: "/Projector/GetCurrentScenes",
                type: "POST",
                data: { 'customerId': customerId, 'projecterMac': projectorMac },
                dataType: "html",
                success: function (result) {
                    if (~result.indexOf('id="login-form')) {
                        window.location.reload()
                    }
                    if ($previewElem.hasClass('projector-cell-preview-loading')) {
                        $previewElem.removeClass('projector-cell-preview-loading')
                        $previewElem.children('img').attr('src', '/Content/images/preview.png')
                        $.featherlight($(result), { 'variant': 'sunflow-dark-featherlight' })
                    }
                },
                error: function () {
                    if ($previewElem.hasClass('projector-cell-preview-loading')) {
                        $previewElem.removeClass('projector-cell-preview-loading')
                        $previewElem.children('img').attr('src', '/Content/images/preview.png')
                        alertError("There is currently no material on this projector.")
                    }
                }
            })
        })
        MainPage.registerUserIsIdleCallback('directorOverview', DirectorOverview.userIsIdle)
    },
    userIsIdle: function (isActive: boolean) {
        if (isActive && !$('#repair-container').length) {
            DirectorOverview.refreshView()
        }
    },
    loadRepairCentre: function (selectedDevices: string[], targetView: string) {
        const state = store.getState()
        const customerId = selectCustomerId(state)
        const director = selectLoadedDirector(state, customerId)

        if (director !== null) {
            DevicesRepairCentre.requestViewChange(customerId, selectedDevices, targetView, 'live')
        }
    },
    unbindEvents: function () {
        const $projectorDeviceRow = $('.projector .device-row')
        const $directorStatus = $('#director-status')

        $projectorDeviceRow.unbind('mousedown')
        $projectorDeviceRow.unbind('mouseup')
        $projectorDeviceRow.unbind('mouseleave')
        $projectorDeviceRow.unbind('dblclick')
        $(".projector .device-row input[type='checkbox']").unbind('change')
        $directorStatus.unbind('mousedown')
        $directorStatus.unbind('mouseup')
        $directorStatus.unbind('mouseleave')
        $('#no-director-go-to-setup').unbind('click')
        $('.projector .device-row .fa-eye').unbind('click')
        MainPage.unregisterUserIsIdleCallback('directorOverview')
    },
    setSelectedProjector: (selectedProjectorMac: string): boolean => {
        const selectedProjectorRow = document
            .querySelectorAll(`.device-table.projector .device-row[data-projector-id=${selectedProjectorMac}]`)
            .item(0)

        if (selectedProjectorRow === null)
            return false

        DirectorOverview.clearSelected()

        selectedProjectorRow.classList.toggle("device-row-selected", true)
        selectedProjectorRow.classList.toggle("device-row-normal", false)

        return true
    },
    setCheckedProjectors: (checkedProjectorMacs: string[]) => {
        const rows = document.querySelectorAll(".device-table.projector .device-row")

        rows.forEach(row => {
            const checkbox = row.querySelector("input[type='checkbox']") as HTMLInputElement | null
            const mac = row.getAttribute("data-projector-id")

            if (checkbox !== null) {
                checkbox.checked = mac !== null && checkedProjectorMacs.includes(mac)
            }
        })
    },
    clearSelected: function () {
        let $oldTab = $('.director .device-row-selected')
        if ($oldTab.length) {
            $oldTab.removeClass('device-row-selected')
            $oldTab.addClass('device-row-normal')
        }
        $oldTab = $('.projector .device-row-selected')
        if ($oldTab.length) {
            $oldTab.removeClass('device-row-selected')
            $oldTab.addClass('device-row-normal')
        }
    },
    refreshView: function () {
        console.warn('director-overview triggered refreshView(). Should refresh screen if director overview page is active')
        const isDirectorOverviewPage = store.getState().customerTree.selectedPageTab === "Overview"
        const customerId = selectCustomerId(store.getState())

        if (isDirectorOverviewPage) {
            DirectorOverview.requestViewChange(customerId, deviceDetails.getSelectedDevices()!, true)
        }
    }
}

const removeDeviceCheckboxes = () => {
    const checkboxes = document.getElementsByClassName("dt-checkbox")

    for (let i = 0; i < checkboxes.length; i++) {
        const element = checkboxes[i].getElementsByTagName("input")[0]
        element?.remove()
    }
}

function renderScreenStatusIcons(customerId: number) {
    const projectorRows = document.querySelectorAll(".projector .device-row") as NodeListOf<HTMLElement>

    projectorRows.forEach(element => {
        const target: Target = {
            id: +(element.dataset.projectorIdNumeric ?? -1),
            type: TargetType.Screen
        }

        const hasName = element.dataset.projectorHasName === "true"
        const root = element.querySelector(".screen-status-icons-root")

        renderDeviceStatusIcons(store, root, {target, customerId, displayScenesIcon: hasName})
    })
}

export default DirectorOverview

export const selectOrFetchDirector = async (state: RootState, customerId: number) => {
    const director = selectLoadedDirector(state, customerId)

    if (director !== null)
        return director

    return await store
        .dispatch(fetchDirectorForCustomer({customerId}))
        .then(unwrapResult)
}