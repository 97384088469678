import * as React from "react";
import { FaStyle, FaSize } from "./fa-types"

type Props = {
    icon: string,
    style?: FaStyle | null
    size?: FaSize | null
    spin?: boolean,
    fixedWidth?: boolean,
    title?: string,
    className?: string,
    onClick?: React.MouseEventHandler<HTMLElement>,
    onTouchStart?: React.TouchEventHandler<HTMLElement>,
    onTouchEnd?: React.TouchEventHandler<HTMLElement>
}

const FA = ({ icon, style, size, spin = false, fixedWidth = false, className = '', title, onClick, onTouchStart, onTouchEnd }: Props) => {
    const faClassName = () => {
        let fa = "fa";
        if (style) fa += style;
        fa += " fa-" + icon;
        if (spin) fa += " fa-spin";
        if (fixedWidth) fa += " fa-fw";
        if (size) fa += " fa-" + size;
        return fa;
    }

    className = faClassName() + (className.length > 0 ? (" " + className) : "");

    return (
        <i className={className} aria-hidden="true" title={title}
            onClick={onClick}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd} />
    );
}

export default FA;
