import * as React from "react"

type Props = {
    show: boolean,
    onClose?: React.MouseEventHandler,
    className?: string,
    children: any
};

export const Modal = (props: Props) => {
    if (!props.show)
        return null;

    const className = "modal " + (props.className||"")

    return (
        <div className={className}>
            <div className="modal__content">
                <div className="modal__body">
                    {props.children}
                </div>
                {props.onClose && <span className="modal__close" onClick={props.onClose}>&times;</span>}
            </div>
        </div>
    )
}
