import SunFlowMain from "./sunflow-main";
import MainPage from "./main-page";

var EmailMonitor = {
    loadedCustomerId: -1,
    loadedChainCustomerId: -1,
    requestViewChange: function (customerIdToLoad: number, chainCustomerIdToLoad: number, forceReload: boolean) {
        if (customerIdToLoad !== EmailMonitor.loadedCustomerId
            || chainCustomerIdToLoad !== EmailMonitor.loadedChainCustomerId || forceReload) {

            EmailMonitor.unbindEvents();
            var $newOverlay = $('.email-monitor-overlay');
            var firstTime = false;
            if (!$newOverlay.length) {
                $newOverlay = $('<div class="email-monitor-overlay hidden"></div>');
                $newOverlay.appendTo($('body'));
                firstTime = true;
            }
            EmailMonitor.loadedCustomerId = customerIdToLoad;
            EmailMonitor.loadedChainCustomerId = chainCustomerIdToLoad;

            MainPage.fadeInOverlay();
            $.ajax({
                url: "/Settings/EmailMonitor",
                type: "POST",
                data: SunFlowMain.addAntiForgeryToken({ 'customerId': customerIdToLoad, 'chainCustomerId': chainCustomerIdToLoad }),
                dataType: "html",
                success: function (result) {
                    EmailMonitor.placeOverlayWindow();
                    if (firstTime) {
                        $newOverlay.fadeIn(100);
                    }
                    else {
                        $newOverlay.show();
                    }
                    $newOverlay.html(result);

                    MainPage.checkIfLoggedInAfterAjax($newOverlay);
                    EmailMonitor.bindEvents();
                    MainPage.registerResizeCallback('email-monitor-resize', EmailMonitor.placeOverlayWindow);
                    MainPage.registerOutsideClickCallback('email-monitor-click-outside', EmailMonitor.clickOutside);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    alert("An error has occured!!!");
                    $newOverlay.remove();
                },
                complete: function () {
                    MainPage.fadeOutOverlay();
                }
            });
        }
    },
    bindEvents: function () {
        $('#email-monitor-header .overlay-close-button').on('click', function (e) {
            EmailMonitor.leaveEmailMonitor();
        });
        $('#email-monitor-go-back').on('click', function (e) {
            EmailMonitor.leaveEmailMonitor();
        });
        $('#email-monitor-input-add-email').on('click', function (e) {

            var $emailInput = $('#email-monitor-input-email');
            var subscriptionMode = $('input[name=subscription-mode]:checked').val() as string;
            var emailAddress = $emailInput.val() as string;
            if (SunFlowMain.isNullOrWhitespace(emailAddress)) {
                $emailInput.effect('highlight');
                $emailInput.focus();
            }
            else if (SunFlowMain.isNullOrWhitespace(subscriptionMode)) {
                $('input[name=subscription-mode]').effect('highlight');
            }
            else {
                EmailMonitor.saveEmail(emailAddress, subscriptionMode);
            }
        });
        $('.email-monitor-input-remove-email').on('click', function (e) {
            var $curRow = $(this),
                $paragraphMultiple = $('#email-monitor-email-dialog-multiple-customers'),
                curId = $curRow.attr('data-row-id'),
                affectsMultiple = $curRow.attr('data-row-multiple');
            if (affectsMultiple === 'True') {
                $paragraphMultiple.removeClass('hidden');
            }
            else {
                $paragraphMultiple.addClass('hidden');
            }
            $('#email-monitor-delete-dialog-email').text($curRow.attr('data-row-email') as string);
            $("#email-monitor-delete-dialog").dialog({
                resizable: false,
                draggable: false,
                height: "auto",
                width: 400,
                modal: true,
                buttons: {
                    "Remove Email Subscription": function () {
                        $(this).dialog("destroy");
                        EmailMonitor.removeEmail(curId);
                    },
                    Cancel: function () {
                        $(this).dialog("destroy");
                    }
                }
            });
        });
    },
    unbindEvents: function () {
        $('#email-monitor-header .overlay-close-button').unbind('click');
        $('#email-monitor-go-back').unbind('click');
        $('#email-monitor-input-add-email').unbind('click');
        $('.email-monitor-input-remove-email').unbind('click');
    },
    clear: function () {
        EmailMonitor.loadedCustomerId = -1;
        EmailMonitor.loadedChainCustomerId = -1;
    },
    placeOverlayWindow: function () {
        var $overlay = $('.email-monitor-overlay')
        var $parent = $('#inner-content');
        var $title = $('#title');
        var inset = 4;
        $overlay.height($parent.height()! - $title.height()! - inset * 2);
        $overlay.width($parent.width()! - inset * 2);
        var offset = $parent.offset();
        $overlay.css({ left: offset!.left + inset, top: offset!.top + $title.height()! + inset })
    },
    leaveEmailMonitor: function () {
        EmailMonitor.unbindEvents();
        EmailMonitor.clear();
        var $overlay = $('.email-monitor-overlay')
        $overlay.fadeOut(100, function () {
            $overlay.remove();
        });
        MainPage.unregisterResizeCallback('email-monitor-resize');
        MainPage.unregisterOutsideClickCallback('email-monitor-click-outside');
    },
    clickOutside: function (compareUpTarget: HTMLElement, compareDownTarget: HTMLElement) {
        var $overlay = $('#email-monitor-inner-border')
        if (MainPage.isTargetOutsideElement(compareUpTarget, $overlay) && MainPage.isTargetOutsideElement(compareDownTarget, $overlay)) {
            EmailMonitor.leaveEmailMonitor();
        }
    },
    saveEmail: function (emailToSave: unknown, subscriptionModeToSave: unknown) {
        $.ajax({
            url: "/Settings/SaveMonitorEmail",
            data: SunFlowMain.addAntiForgeryToken({
                'chainCustomerId': EmailMonitor.loadedChainCustomerId
                , 'customerId': EmailMonitor.loadedCustomerId
                , 'email': emailToSave
                , 'subscriptionMode': subscriptionModeToSave
            }),
            type: "POST",
            dataType: "text",
            async: false,
            success: function (result) {
                if (result === 'True') {
                    EmailMonitor.requestViewChange(EmailMonitor.loadedCustomerId, EmailMonitor.loadedChainCustomerId, true);
                }
                else if (result === 'False') {
                    alert("An error has occured in EmailMonitor.saveEmail()");
                    window.location.reload();
                }
                else {
                    alert("An error has occured in EmailMonitor.saveEmail()");
                    window.location.reload();
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert("An error has occured!!!");

            }
        });
    },
    removeEmail: function (idToRemove: unknown) {
        $.ajax({
            url: "/Settings/RemoveMonitorEmail",
            data: SunFlowMain.addAntiForgeryToken({ 'customerId': EmailMonitor.loadedCustomerId, 'emailId': idToRemove }),
            type: "POST",
            dataType: "text",
            async: false,
            success: function (result) {
                if (result === 'True') {
                    EmailMonitor.requestViewChange(EmailMonitor.loadedCustomerId, EmailMonitor.loadedChainCustomerId, true);
                }
                else if (result === 'False') {
                    alert("An error has occured in EmailMonitor.removeEmail()");
                    window.location.reload();
                }
                else {
                    alert("An error has occured in EmailMonitor.removeEmail()");
                    window.location.reload();
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert("An error has occured!!!");

            }
        });
    }
};

export default EmailMonitor;