import { playPlaylistPreview } from "@states/projector/projector-util"
import * as moment from "moment"
import DevicesRepairCentre from "./devices-repair-centre"
import MainPage from "./main-page"
import RunningCommands from "./running-commands"
import SunFlowMain from "./sunflow-main"

var CommandCreator = {
    commandType: '',
    currentView: '',
    supressEvents: false,
    isViewingCommandData: false,
    shouldReloadCommandViewAfterDialog: false,
    params: <(string|number)[]>[],
    initializeAndBindEvents: function () {
        CommandCreator.clear();
        $('#repair-command-type-selector').on('change', function (e) {
            // we should class this logic on selectin gan option...
            if (CommandCreator.commandType === '') {
                var newCommandType = $('#repair-command-type-selector option:selected').val() as string;
                if (newCommandType !== 'null') {
                    CommandCreator.initializeCmd(newCommandType);
                    $('#repair-command-creator-container').switchClass('repair-command-creator-short', 'repair-command-creator-long', 300, 'easeInOutQuad', function () {
                        $('repair-main-command-table-layout').removeClass('hidden');
                    });
                    $('#repair-command-log-container').switchClass('repair-command-log-long', 'repair-command-log-short', 300, 'easeInOutQuad');
                    $('#repair-command-type-selector').prop('disabled', true);
                    $('#repair-main-exit-command').removeClass('hidden');
                    $('#repair-main-command-table-layout').removeClass('hidden');
                }
            }
        });

        $('#repair-main-exit-command').on('click', function (e) {
            CommandCreator.resetCommandBuilder();
        });
        CommandCreator.bindRowEvents();
    },
    bindRowEvents: function () {
        $('.command-cell-download-data.clicky').on('click', function (e) {
            var $elem = $(this);
            var commandId = $elem.attr('data-command-id');
            var target = $elem.attr('data-command-target');
            var fileName = $elem.attr('data-command-filename');
            CommandCreator.downloadCommandData(commandId!, target!, fileName!);
        });
        $('.command-cell-play-playlist.clicky').on('click', function (_) {
            const playlistGuid = $(this).attr('data-command-guid')!
            playPlaylistPreview(playlistGuid)
        });
        $('.command-cell-view-data.clicky').on('click', function (e) {
            var $elem = $(this);
            var commandId = $elem.attr('data-command-id');
            var target = $elem.attr('data-command-target');
            var commandType = $elem.attr('data-command-type');
            CommandCreator.viewCommandData(commandId!, target!, commandType!);
        });
    },
    downloadCommandData: function (commandId: string, target: string, fileName: string) {
        window.open('/Main/GetCommandData?commandId=' + commandId + '&target=' + target + '&fileName=' + fileName);
    },
    viewCommandData: function (commandId: string, target: string, commandType: string) {
        if (!CommandCreator.isViewingCommandData) {
            CommandCreator.isViewingCommandData = true;

            $.ajax({
                url: '/Main/ViewCommandData/',
                type: "POST",
                data: JSON.stringify({ 'commandId': commandId, 'target': target, 'commandType': commandType }),
                dataType: "html",
                contentType: "application/json; charset=utf-8",
                success: function (result) {
                    var $result = $(result);
                    MainPage.checkIfLoggedInAfterAjax($result);
                    $.featherlight($result,
                        {
                            'variant': 'sunflow-light-featherlight',
                            beforeClose: function (/*e*/) {
                                CommandCreator.isViewingCommandData = false;
                                if (CommandCreator.shouldReloadCommandViewAfterDialog) {
                                    CommandCreator.shouldReloadCommandViewAfterDialog = false;
                                    CommandCreator.reloadCommandLog();
                                }
                            }
                        });
                },
                error: function () {
                    alert("An error has occured!!!");
                }
            });
        }
    },
    resetCommandBuilder: function () {
        if (CommandCreator.commandType !== '') {
            $('#repair-main-command-table-layout').addClass('hidden');
            $('#repair-command-creator-container').switchClass('repair-command-creator-long', 'repair-command-creator-short', 300, 'easeInOutQuad');
            $('#repair-command-log-container').switchClass('repair-command-log-short', 'repair-command-log-long', 300, 'easeInOutQuad');
            var $selector = $('#repair-command-type-selector') as JQuery<HTMLSelectElement>;
            $selector.prop('disabled', false);
            $selector[0].selectedIndex = 0;
            $('#repair-main-exit-command').addClass('hidden');
            CommandCreator.clear();
            CommandCreator.initializeCmd('');
        }
    },
    clear: function () {
        CommandCreator.commandType = '';
        CommandCreator.currentView = '';
        CommandCreator.params = [];
        CommandCreator.supressEvents = false;
        CommandCreator.isViewingCommandData = false;
    },
    unbindEvents: function () {
        $('#repair-command-type-selector').unbind('change');
        $('#repair-main-exit-command').unbind('click');
        CommandCreator.unbindRowEvents();
        CommandCreator.unbindCommandEvents();
    },
    unbindRowEvents: function () {
        $('.command-cell-download-data.clicky').unbind('click');
        $('.command-cell-play-playlist.clicky').unbind('click');
        $('.command-cell-view-data.clicky').unbind('click');
    },
    isDirector: function () {
        // if we have only one selectedDevice and its id is 8 characters long assume it is a director.
        if (DevicesRepairCentre.selectedDevices!.length == 1 && DevicesRepairCentre.selectedDevices![0].length == SunFlowMain.DIRECTORIDLENGTH) {
            return true;
        }
        else {
            return false;
        }
    },
    initializeCmd: function (newCommandType: string) {
        if (CommandCreator.commandType != newCommandType) {
            CommandCreator.commandType = newCommandType;
            CommandCreator.unbindCommandEvents();

            if (newCommandType !== '') {
                var dataToInclude;
                var viewToLoad: string;
                if (newCommandType === 'ShellCmd' || newCommandType === 'Coredump' || newCommandType === 'LogLevel' || newCommandType === 'DisplayName' || newCommandType === 'ReverseSSH') {
                    dataToInclude = '';
                    viewToLoad = newCommandType;
                }
                else if (newCommandType === 'IsRenderingMovie' || newCommandType === 'RestoreDefaultSettings' || newCommandType === 'GetSettings') {
                    viewToLoad = 'ConfirmCommand';
                    CommandCreator.params = [];
                    CommandCreator.params.push(newCommandType);
                    dataToInclude = JSON.stringify({ 'command': JSON.stringify(CommandCreator.prepareCommand()) });
                }
                else if (newCommandType === 'GetLogs') {
                    viewToLoad = newCommandType;
                    dataToInclude = JSON.stringify({ 'isDirector': CommandCreator.isDirector() });
                }
                else if (newCommandType === 'UpdateDevice') {
                    viewToLoad = 'ConfirmCommand';
                    CommandCreator.params = [];
                    CommandCreator.params.push('Upgrade');
                    CommandCreator.params.push('All');
                    dataToInclude = JSON.stringify({ 'command': JSON.stringify(CommandCreator.prepareCommand()) });
                }
                else if (newCommandType === 'FactoryReset') {
                    viewToLoad = 'ConfirmCommand';
                    CommandCreator.params = [];
                    CommandCreator.params.push(newCommandType);
                    dataToInclude = JSON.stringify({ 'command': JSON.stringify(CommandCreator.prepareCommand()) });
                }
                else if (newCommandType === 'GetProjectorPlaylist') {
                    viewToLoad = newCommandType;
                }
                else if (newCommandType === 'GetProjectorImage' || newCommandType === 'Restart' || newCommandType === 'GetVersionInfo' || newCommandType === 'NetworkInfo') {
                    viewToLoad = 'ConfirmCommand';
                    dataToInclude = JSON.stringify({ 'command': JSON.stringify(CommandCreator.prepareCommand()) });
                }
                else {
                    alert('Super unexpected state');
                    window.location.reload();
                }

                CommandCreator.loadView(viewToLoad!, dataToInclude);
            }
        }
    },
    loadView: function (view: string, data: any) {
        CommandCreator.currentView = view;
        var $target = $('#repair-main-command-table-layout');
        $target.empty();
        $.ajax({
            url: '/CommandBuilder/' + view,
            type: "POST",
            data: data,
            dataType: "html",
            contentType: "application/json; charset=utf-8",
            success: function (result) {
                $target.html(result);
                MainPage.checkIfLoggedInAfterAjax($target);
                CommandCreator.bindCommandEvents();
                if (view === 'ShellCmd') {
                    $('#shell-cmd').focus();
                }
            },
            error: function () {
                alert("An error has occured!!!");
            }
        });
    },
    sendCommand: function () {
        $.ajax({
            url: '/Main/SendCommand',
            type: "POST",
            dataType: "text",
            data: JSON.stringify({ 'customerId': DevicesRepairCentre.loadedCustomerId, 'command': JSON.stringify(CommandCreator.prepareCommand()) }),
            contentType: "application/json; charset=utf-8",
            success: function (result) {
                if (result === 'True') {
                    var $buttons = $('#command-builder-confirm, #command-builder-cancel, #repair-main-exit-command');
                    $buttons.prop('disabled', true);
                    CommandCreator.reloadCommandLog().done(function () { //MAAS: Changed .complete() to .done() to fix compile error
                        $buttons.prop('disabled', false);
                        CommandCreator.resetCommandBuilder();
                        RunningCommands.updateRunningCommandsView();
                    });
                }
                else if (result === "False") {
                    alert('failed to send command!');
                }
                else {
                    window.location.reload();
                }
            },
            error: function () {
                alert("An error has occured!!!");
            }
        });
    },

    // The general log window
    reloadCommandLog: function () {
        var $target = $('#repair-command-log-container');
        CommandCreator.unbindRowEvents();
        $target.fadeOut(200, function () {
            $(this).empty();
        });
        return $.ajax({
            url: '/Main/CommandLog',
            type: "POST",
            data: JSON.stringify({ 'customerId': DevicesRepairCentre.loadedCustomerId, 'selectedDevices': DevicesRepairCentre.selectedDevices }),
            dataType: "html",
            contentType: "application/json; charset=utf-8",
            success: function (result: string) {
                $target.promise().done(function () {
                    $target.html(result);
                    $target.fadeIn(200);

                    CommandCreator.bindRowEvents();
                    MainPage.checkIfLoggedInAfterAjax($target);
                });
            },
            error: function () {
                alert("An error has occured!!!");
            }
        }) as JQuery.jqXHR<string>;
    },
    validateAndSetParams: function () {
        CommandCreator.params = [];
        if (CommandCreator.currentView === 'ShellCmd') {
            var $cmd = $('#shell-cmd');
            var isOk = false;
            // need to calculate args value from cmd
            if ($cmd.length) {
                var cmdVal = $cmd.val() as string;
                var index = cmdVal.indexOf(' ');
                var argsVal: string;
                if (index != -1) {
                    argsVal = cmdVal.substr(index + 1);
                    cmdVal = cmdVal.substr(0, index);
                }
                else
                    argsVal = '';
                if (cmdVal != '' && argsVal != null) {
                    isOk = true;
                }
                else {
                    alert('Please fill in Command and Arguments Textbox');
                }
            }
            else {
                alert('Super unexpected state');
                window.location.reload();
            }
            if (isOk) {
                CommandCreator.params.push(cmdVal!);
                CommandCreator.params.push(argsVal!);
                CommandCreator.params.push('0'); // no Delay.
                return true;
            }
            else
                return false;
        }
        else if (CommandCreator.currentView === 'ReverseSSH') {
            var $revPort = $('#reversessh-port');
            if ($revPort.length) {
                var parsedValue = parseInt($revPort.val() as string, 10);
                if (isNaN(parsedValue)) {
                    return false;
                }
                else {
                    CommandCreator.params.push('ssh');
                    var argString = '-o UserKnownHostsFile=/dev/null -o StrictHostKeyChecking=no -p 12322 -N -R ' + parsedValue + ':localhost:22 flowdirector@flow-reverse.shoppa.com';
                    CommandCreator.params.push(argString);
                    CommandCreator.params.push('0'); // no Delay.
                    return true;
                }
            }
        }
        else if (CommandCreator.currentView === 'LogLevel') {
            var $selLevel = $('.log-level-radio:checked');
            if ($selLevel.length) {
                var selValue = $($selLevel[0]).val();
                if (selValue === 'Get') {
                    CommandCreator.params.push(selValue);
                }
                else {
                    CommandCreator.params.push('Set');
                    CommandCreator.params.push($('#log-level-level').val() as string);
                }
                return true;
            }
            else {
                alert('No Log Level selected!');
                return false;
            }
        }
        else if (CommandCreator.currentView === 'GetProjectorPlaylist') {
            const ele = document.getElementById("getplaylist-datetime") as HTMLInputElement;
            const value = ele && ele.value;
            const date = value && new Date(value);

            if (!date)
                return false;

            CommandCreator.params.push(date.toISOString());
            return true;
        }
        else if (CommandCreator.currentView === 'GetLogs') {
            if ($('.getlogs-filename').length > 0) {
                CommandCreator.params.push(($('.getlogs-filename:checked') as JQuery<HTMLInputElement>).map(function () { return this.value; }).get().join(','));
                return true;
            }
            else {
                alert('Need to select at least one file.');
                return false;
            }
        }
        else if (CommandCreator.currentView === 'Coredump') {
            var $selCmdType = $('.coredump-radios:checked');
            if ($selCmdType.length) {
                CommandCreator.params.push($selCmdType.val() as string);

                var $fileNames = $('#coredump-filenames');
                if ($fileNames.length) {
                    var content = $fileNames.val() as string;
                    if (content.indexOf(',') != -1) {
                        //var fileNames = content.split(',');
                        $.each(content.split(','), function (key, value) {
                            CommandCreator.params.push(value);
                        });
                    }
                    else {
                        CommandCreator.params.push(content);
                    }
                }
                return true;
            }
            else {
                alert('No Command Type selected!');
                return false;
            }
        }
        else if (CommandCreator.currentView === 'DisplayName') {
            var $selTime = $('#displayname-time');
            if ($selTime.length) {
                var parsedValue = parseInt($selTime.val() as string, 10);
                if (isNaN(parsedValue)) {
                    return false;
                }
                else {
                    CommandCreator.params.push(parsedValue);
                    return true;
                }
            }
        }

        return undefined;
    },
    prepareCommand: function () {
        return {
            'CommandType': CommandCreator.commandType,
            'Params': CommandCreator.params,
            'RecipientDevices': DevicesRepairCentre.selectedDevices,
        };
    },
    bindCommandEvents: function () {
        if (CommandCreator.currentView == 'ConfirmCommand') {
            $('#command-builder-confirm').on('click', function (e) {
                CommandCreator.sendCommand();
            });
            $('#command-builder-cancel').on('click', function (e) {
                CommandCreator.resetCommandBuilder();
            });
        }
        else {
            $('#command-builder-confirm').on('click', function (e) {
                if (CommandCreator.validateAndSetParams()) {
                    CommandCreator.sendCommand();
                }
            });
            $('#command-builder-cancel').on('click', function (e) {
                CommandCreator.resetCommandBuilder();
            });
            if (CommandCreator.currentView == 'ShellCmd') {
                $('#shell-cmd').on('keypress', function (e) {
                    if (e.which == 13) {
                        if (CommandCreator.validateAndSetParams()) {
                            CommandCreator.sendCommand();
                        }
                    }
                });
            }
            else if (CommandCreator.currentView == 'GetProjectorPlaylist') {
                const setDatetimeToNow = () => {
                    const date = new Date();
                    date.setSeconds(0, 0);

                    const element = (document.getElementById("getplaylist-datetime") as HTMLInputElement);
                    element.value = moment(date).format("YYYY-MM-DD[T]HH:mm");
                }

                setDatetimeToNow();

                $('#getplaylist-now').on('click', function (e) {
                    setDatetimeToNow();
                })
            }
            else if (CommandCreator.currentView == 'GetLogs') {
                ($('#getlogs-checkall') as JQuery<HTMLInputElement>).on('change', function (e) {
                    if (!CommandCreator.supressEvents) {
                        CommandCreator.supressEvents = true;
                        $('.getlogs-filename').prop('checked', this.checked)
                        CommandCreator.supressEvents = false;
                    }
                });
                $('.getlogs-filename').on('change', function (e) {
                    if (!CommandCreator.supressEvents) {
                        CommandCreator.supressEvents = true;
                        $('#getlogs-checkall').prop('checked', $('.getlogs-filename').length == $('.getlogs-filename:checked').length)
                        CommandCreator.supressEvents = false;
                    }
                });
            }
            else if (CommandCreator.currentView == 'LogLevel') {
                $('#log-level-level').on('change', function (e) {
                    $('#log-level-set').prop('checked', true);
                });
                $('#log-level-level').on('click', function (e) {
                    $('#log-level-set').prop('checked', true);
                });
            }
        }
    },
    // I need to be called before we set a new currentView
    unbindCommandEvents: function () {
        if (CommandCreator.currentView == 'ConfirmCommand') {
            $('#command-builder-confirm').unbind('click');
            $('#command-builder-cancel').unbind('click');
        }
        else {
            $('#command-builder-confirm').unbind('click');
            $('#command-builder-cancel').unbind('click');
            if (CommandCreator.currentView == 'ShellCmd') {
                $('#shell-cmd').unbind('keypress');
            }
            else if (CommandCreator.currentView == 'GetLogs') {
                $('#getlogs-checkall').unbind('change');
                $('.getlogs-filename').unbind('change');
            }
            else if (CommandCreator.currentView == 'LogLevel') {
                $('#log-level-level').unbind('change');
                $('#log-level-level').unbind('click');
            }
        }
    }
};

export default CommandCreator;