import CustomerTree from "@features/customer-tree/customer-tree"
import { DeviceButton } from "@features/device-details/components/buttons/device-button"
import ProjectorScreenshotButton from "@features/device-details/components/buttons/projector-screenshot-button"
import deviceDetails from "@features/device-details/device-details-legacy"
import MainPage from "@features/main-page"
import SunFlowMain from "@features/sunflow-main"
import { Dictionary } from "@reduxjs/toolkit"
import FlowPlanner from "@src/lib/flowplanner"
import { TargetType } from "@src/lib/targetable"
import { SCREENSHOT_MAX_AGE_IN_SECONDS } from "@states/projector/projector-util"
import store from "@states/store"
import * as React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import { renderInfoMessageBox } from "../info-message-box/info-message-box"

const StoreOverview =
{
    SCREENDUMP_ALL: 1,
    SCREENDUMP_SINGLE: 2,

    initialize: function () {
        $(document)
            .on('click', '#overview-store-container .overview-store-screen-container', onScreenClicked)

        function onScreenClicked(e: JQuery.ClickEvent<any, any, HTMLElement, any>) {
            let channelContainer = e.currentTarget.closest('.overview-store-channel-container') as HTMLElement; //MAAS: Verify this works in IE!
            let channelName = channelContainer.dataset['channel'];
            let screenName = e.currentTarget.dataset['name'];
            let projectorMac = e.currentTarget.dataset['mac'];
            StoreOverview.loadScreenDetails(channelName!, screenName!, projectorMac!);
        }
    },
    requestViewChange: function (storeId: number) {
        MainPage.onViewChanging();

        var $target = $("#main-page-view");

        MainPage.fadeInOverlay();
        return $.ajax({
            url: "/Overview/StoreOverview?storeId=" + storeId,
            type: "GET",
            data: SunFlowMain.addAntiForgeryToken({ 'storeId': storeId }),
            dataType: "html",
            success: function (result: string) {
                $target.html(result);
                MainPage.checkIfLoggedInAfterAjax($target);
                StoreOverview.bindEvents();

                const target = { id: storeId, type: TargetType.Customer }
                const root = document.getElementById("store-info-box-root")

                renderInfoMessageBox(target, store, root)
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert("An error has occured in StoreOverview.requestViewChange(). " + textStatus + " " + errorThrown);
            },
            complete: function () {
                MainPage.fadeOutOverlay();
            }
        });
    },
    changePlayListDetailsWidth: function () {
        var playListDetails = document.querySelectorAll<HTMLElement>(".play-list-details");
        for (var i = 0; i < playListDetails.length; i++) {
            var imgWidth = playListDetails[i].getElementsByClassName("img-screen")[0].clientWidth;
            if (imgWidth > 0) {
                playListDetails[i].style.width = imgWidth + "px";
            }
        }
    },

    loadScreenDetails: function (channelName: string, screenName: string, projectorMac: string) {

        var $imgWaiting = $('#screen-details-waiting');
        var $previewElem = $('#screen-status-container-' + projectorMac);
        var customerId = CustomerTree.getSelectedCustomerId();

        if (!$imgWaiting.length) {

            $imgWaiting = $("<img id='screen-details-waiting' src='/Content/images/waiting.gif' class='screen-details-waiting' />");

            $previewElem.append($imgWaiting);

            $.ajax({
                url: "/Overview/GetScreenDetails",
                type: "GET",
                data: { channelName, screenName, customerId, projectorMac },
                dataType: "html",
                contentType: "application/json; charset=utf-8",
                success: function (result, textStatus, xhr) {
                    if (textStatus !== "success") {
                        window.location.reload();
                    }

                    $imgWaiting.remove();
                    $.featherlight($(result), { 'variant': 'sunflow-dark-featherlight' });

                    const root = document.getElementById("projector-overview-buttons-root")
                    renderProjectorScreenDetailsButtons(projectorMac, customerId, root)
                },
                error: function () {
                    $imgWaiting.remove();
                    alert("There is currently no material on this projector.");
                }
            });
        }
    },

    getProjectorScreenshotImage: function ($imgElement: JQuery, url: string) {

        setTimeout(function () {
            $imgElement.attr('data-screenshot-old', 'true');
        }, SCREENSHOT_MAX_AGE_IN_SECONDS * 1000);

        $imgElement.attr('data-screenshot-old', 'false');
        $imgElement.attr('src', url);
    },

    openSingleScreenshotImage(displayMode: number) {
        if (displayMode === StoreOverview.SCREENDUMP_SINGLE) {
            $.featherlight($('#screenshot-dump img'), { 'variant': 'sunflow-light-featherlight' });
        }
    },

    isScreenshotAvailableAndRecent: function ($imgElement: JQuery<HTMLImageElement>) {
        return ($imgElement.length > 0 && $imgElement[0].naturalWidth > 0) && $imgElement.attr('data-screenshot-old') !== 'true';
    },

    bindEvents: function () {
        $('#collect-screendumps-button').on('click', function (e) {
            const screenImages = document.getElementById("overview-store-channel-list-container")!.querySelectorAll(".screen-image")
            const customerId = CustomerTree.getSelectedCustomerId()
            const players: Dictionary<string> = {}

            for (let i = 0; i < screenImages.length; i++) {
                const projectorId = screenImages[i].id
                const projectorName = screenImages[i].getAttribute('data-screen-name') as string
                deviceDetails.getScreenshotForProjector(projectorId, customerId, deviceDetails.SCREENSHOT_GALLARY, 768)
                players[projectorId] = projectorName
            }
            deviceDetails.loadScreenAblumPopup(players)
        });
    },

    unbindEvents: function () {
        $('#collect-screendumps-button').unbind('click');
    }
};

export default StoreOverview;

const renderProjectorScreenDetailsButtons = (projectorMac: string, customerId: number, root: Element | null) => {
    if (!root) return void 0

    return render(
        <Provider store={store}>
            <DeviceButton
                onClick={() => FlowPlanner.openPlayer(customerId, projectorMac)}>
                Show schedule
            </DeviceButton>
            <ProjectorScreenshotButton projectorMac={projectorMac} customerId={customerId}/>
        </Provider>,
        root
    )
}