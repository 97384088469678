import MainPage from "./main-page";
import CustomerTree from "@features/customer-tree/customer-tree";
import FlowPlanner from "@src/lib/flowplanner";
import { selectHasRole, selectCurrentUserRoles } from "../states/user/user-selectors";
import store from "../states/store";

var ChainOverview = {
    initialize: function () {
        $(document)
            .on('click', '#chain-overview-send-email-button', ChainOverview.sendEmailToSupport)
            .on('click', '#overview-chain-container .empty-player-summary', onEmptyPlayerClicked)
            .on('click', '#overview-chain-container .channel-summary', onChannelSummaryClicked);

        function onEmptyPlayerClicked(e: JQuery.ClickEvent<any, any, HTMLElement, any>) {
            let customerId = +e.currentTarget.dataset['customerid']!;
            CustomerTree.selectCustomerById(customerId);
        }

        function onChannelSummaryClicked(e: JQuery.ClickEvent<any, any, HTMLElement, any>) {
            const roles = selectCurrentUserRoles(store.getState().user);

            if (selectHasRole(roles, 'FlowPlanner')) {
            let channelId = +e.currentTarget.dataset['channel']!;
            ChainOverview.goToFlowPlanner(channelId);
        }
        }
    },
    requestViewChange: function (customerId: number) {
        MainPage.onViewChanging();

        var $target = $("#main-page-view");
        MainPage.fadeInOverlay();

        return $.ajax({
            url: "/Overview/ChainOverview",
            type: "GET",
            data: { customerId: customerId, customerName: CustomerTree.getSelectedCustomerName() },
            dataType: "html",
            success: function (result) {
                $target.html(result);
                MainPage.checkIfLoggedInAfterAjax($target);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                alert("An error has occured in ChainOverview.requestViewChange(). " + textStatus + " " + errorThrown);
            },
            complete: function () {
                MainPage.fadeOutOverlay();
            }
        });
    },

    goToFlowPlanner: function (channelId: number) {
        const customerId = store.getState().customerTree.selectedCustomerId;
        FlowPlanner.openChannel(customerId, channelId);
    },

    sendEmailToSupport: function () {

        var emailBody = $.trim($("#email-body").val() as string);
        if (emailBody === "") {
            alert("Please enter email content");
            return undefined;
        }
        $("#chain-overview-send-email-button").prop('disabled', true);
        return $.ajax({
            url: "/Overview/SendEmailToSupport",
            type: "POST",
            data: { emailBody: emailBody },
            dataType: "text",
            success: function (result) {
                alert("Email sent to support team successfully. They will contact you soon.");
                $("#chain-overview-send-email-button").prop('disabled', false);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $("#chain-overview-send-email-button").prop('disabled', false);
                alert("An error has occured when sending email. " + errorThrown);
            }
        });
    },
    clear: function () {

    }
};

export default ChainOverview;