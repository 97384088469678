import { RootState } from "@states/state";
import { createSelector } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import { createMemoizedMessage, selectIsReminderClosed } from "@features/info-message-box/info-message-box-selectors";
import FA, { FaStyle } from "../../components/fa";
import { selectIsReminderDue } from "@states/info-message/info-message-selectors";
import { selectCurrentUserRoles } from "../../states/user";
import { selectHasRole } from "../../states/user/user-selectors";
import { Target } from "../../lib/targetable";

interface StateProps {
    icon: Icon,
    spanClass: string,
    remindersVisible: boolean
}

interface OwnProps {
    target: Target
}

type Props = StateProps & OwnProps;

interface Icon {
    name: string;
    style: FaStyle;
    spanExtraClass?: string;
}

const emptyIcon: Icon          = { name: "file-alt",   style: 'r', spanExtraClass: "disabled" }
const messageIcon: Icon        = { name: "file-alt",   style: 'r' }
const dueReminderIcon: Icon    = { name: "bell",       style: 's', spanExtraClass: "color-alert" }
const openReminderIcon: Icon   = { name: "bell",       style: 's' }

const createMapStateToProps = () => {
    const memoizedMessage = createMemoizedMessage();

    const memoizedHasText     = createSelector([memoizedMessage], message => (message.current?.text.length ?? 0) > 0);
    const memoizedHasReminder = createSelector([memoizedMessage], message => (message.current?.reminder ?? null) !== null);
    const memoizedIsClosed    = createSelector([memoizedMessage], selectIsReminderClosed);
    const memoizedIsDue       = createSelector([memoizedMessage], selectIsReminderDue);

    const memoizedIcon = createSelector(
        [memoizedHasText, memoizedHasReminder, memoizedIsClosed, memoizedIsDue],
        (hasText, hasReminder, isReminderClosed, isReminderDue) => {
            const messageOrEmptyIcon = () => hasText ? messageIcon : emptyIcon;

            if (hasReminder) {
                if (isReminderClosed) return messageOrEmptyIcon();
                if (isReminderDue) return dueReminderIcon;
                return openReminderIcon;
            }

            return messageOrEmptyIcon();
        });

    const memoizedSpanClass = createSelector([memoizedIcon],
        icon => "info-message-status-icon" + (icon.spanExtraClass ? (" " + icon.spanExtraClass) : ""));

    const memoizedReminderVisible = createSelector([selectCurrentUserRoles],
        (roles) => selectHasRole(roles, 'LoginAsAnyCustomer'));

    return function mapStateToProps(state: RootState, props: OwnProps): StateProps {
        return ({
            icon: memoizedIcon(state, props),
            spanClass: memoizedSpanClass(state, props),
            remindersVisible: memoizedReminderVisible(state.user)
        })
    }
}

const InfoMessageStatusIcon = (props: Props) => (
    <>
        {props.remindersVisible &&
            <span className={props.spanClass}>
                <FA icon={props.icon.name} style={props.icon.style} fixedWidth={true} />
            </span >
        }
    </>
);

export default connect(createMapStateToProps)(InfoMessageStatusIcon)