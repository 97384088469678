import { AlarmList } from "@features/alarm-list/alarm-list"
import { Alarm } from "@features/alarm-list/components/alarm"
import { DeviceInfoColumn } from "@features/device-details/components/device-info/device-info-column"
import { DeviceInfoRow } from "@features/device-details/components/device-info/device-info-row"
import { InfoMessageBox } from "@features/info-message-box/info-message-box"
import FA from "@src/components/fa"
import { formatDetailedDate, formatFriendlyDate } from "@src/lib/date-util"
import { Device } from "@src/lib/device/device-types"
import { deviceToTarget } from "@src/lib/device/device-util"
import { LightBulb } from "@src/pages/channels/components/lightbulb"
import { shouldShine } from "@states/brighttime/brighttime-controller"
import { selectNearestBrightTime } from "@states/brighttime/brighttime-selectors"
import { BrightTime } from "@states/brighttime/brighttime-types"
import { selectLoadedDirector } from "@states/director/director-selectors"
import { isDirector } from "@states/director/director-util"
import { useAppSelector } from "@states/hooks"
import { Orientation } from "@states/projector"
import { selectLoadedProjector } from "@states/projector/projector-selectors"
import { formatChannelNames, isProjector } from "@states/projector/projector-util"
import * as React from "react"
import { ReactChild, useMemo } from "react"

interface Props {
    device: 'director' | {projectorMac: string},
    customerId: number
}

export const SingleDeviceInfo = (props: Props) => {
    const device: Device | null = useAppSelector(state => {
        return props.device === 'director'
            ? selectLoadedDirector(state, props.customerId)
            : selectLoadedProjector(state, props.device.projectorMac)
    })

    const projector = useMemo(() => isProjector(device) ? device : null, [device])

    const nearestBrightTime = useAppSelector<BrightTime | null>(state => selectNearestBrightTime(state, projector))
    const header = useMemo(() => createHeader(device), [device])
    const lastSeenIcon = useMemo(() => createLastSeenIcon(device, nearestBrightTime), [device, nearestBrightTime])
    const idContent = useMemo(() => createIdContent(device), [device])
    const deviceTarget = useMemo(() => deviceToTarget(device), [device])

    return <>
        <h2>{header}</h2>

        {
            props.device !== 'director' &&
                <DeviceInfoRow>
                    <DeviceInfoColumn label="Channel">
                        {formatChannelNames(projector)}
                    </DeviceInfoColumn>
                    <DeviceInfoColumn label="Orientation">
                        {projector === null ? "" : Orientation[projector.orientation]}
                    </DeviceInfoColumn>
                </DeviceInfoRow>
        }

        <DeviceInfoRow>
            <DeviceInfoColumn label="Last Seen" title={formatDetailedDate(device?.lastSeen)}>
                <p>{lastSeenIcon}{formatFriendlyDate(device?.lastSeen)}</p>
            </DeviceInfoColumn>
        </DeviceInfoRow>

        <DeviceInfoRow>
            <DeviceInfoColumn label="ID">{idContent}</DeviceInfoColumn>
            <DeviceInfoColumn label="Version">{device?.installedVersion}</DeviceInfoColumn>
        </DeviceInfoRow>

        <DeviceInfoRow>
            <DeviceInfoColumn label="IP">{device?.ip}</DeviceInfoColumn>
            <DeviceInfoColumn label={props.device === 'director' ? "MAC" : "Serial No"}>
                {device?.serialNumber}
            </DeviceInfoColumn>
        </DeviceInfoRow>

        <DeviceInfoRow>
            <DeviceInfoColumn label="Status">
                {
                    deviceTarget && <>
                        {
                            device?.broken?.current &&
                                <Alarm
                                    color={'alert'}
                                    header="Marked as broken"
                                    subheader={{text: device.broken.current.reason}}/>
                        }
                        <AlarmList target={deviceTarget} customerId={props.customerId}/>
                    </>
                }
            </DeviceInfoColumn>
        </DeviceInfoRow>

        {deviceTarget && <InfoMessageBox target={deviceTarget}/>}
    </>
}

const createHeader = (device: Device | null) => {
    if (isDirector(device))
        return "Director: Main"

    return `Projector: ${isProjector(device) ? device.name : ""}`
}

const createLastSeenIcon = (device: Device | null, nearestBrightTime: BrightTime | null) => {
    if (device === null)
        return null

    const shouldBeOnline =
        nearestBrightTime === null ||
        shouldShine(nearestBrightTime.definitions, new Date());

    if (shouldBeOnline && device.hasOfflineAlarm)
        return <FA icon="plug" style='s' fixedWidth={true} />

    if (nearestBrightTime !== null)
        return <LightBulb id={nearestBrightTime.id} isActive={shouldBeOnline} />

    return null
}

const createIdContent = (device: Device | null): ReactChild | string | null => {
    if (isDirector(device))
        return device.id

    if (isProjector(device))
        return <p title={device.numericalId.toString()}><FA icon="hashtag"/>{device.index}</p>

    return null
}