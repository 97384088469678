import * as React from "react"
import { useState } from "react";


type Props = {
    className?: string,
    value: string,
    validationCallback: (value: string) => boolean,
    validationFailedClassName?: string,
    onChange: (value: string) => void
}

export const ValidatingTextbox = (props: Props) => {
    const [value, setValue] = useState(props.value)
    React.useEffect(() => setValue(props.value), [props.value])


    const onChange = (e: React.ChangeEvent) => {
        const input = e.target as HTMLInputElement
        setValue(input.value)
    }

    const onBlur = (e: React.ChangeEvent) => {
        if (isValid && props.value !== value)
            props.onChange(value)
    }

    const isValid = props.validationCallback(value)
    const className = isValid ? props.className : `${props.className} ${props.validationFailedClassName}`

    return (
        <input type="text" className={className} value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
    )
}
