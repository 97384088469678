import * as React from "react"
import FA from "../../../components/fa"

export const LightBulb = (props: { id: number, isActive: boolean }) => {
    if (!props.id)
        return (<FA icon="" fixedWidth={true} />)

    if (props.isActive) {
        return (<FA icon="lightbulb" fixedWidth={true} style="s" className="lightbulb__icon--active" />)
    }
    else {
        return (<FA icon="lightbulb" fixedWidth={true} style="r" className="lightbulb__icon--inactive" />)
    }
}
