import * as moment from "moment";
import { isValidDate } from "./date";

enum DateTimeStringBrand { _ = "" }
export type DateTimeString = string & DateTimeStringBrand

export const isValidDateTime = (dateString: string) => new Date(dateString).toJSON() === dateString
const isDateTimeString = (dateString: string): dateString is DateTimeString => isValidDateTime(dateString)

export function toDateTime(date: Date | moment.Moment | string): DateTimeString {
    if (typeof date === "string" && isValidDate(date)) {
        const dateString = moment(date + "T00:00:00Z").toJSON()
        if (isDateTimeString(dateString))
            return dateString
    }

    if (date) {
        const dateString = moment(date).toJSON()
        if (isDateTimeString(dateString))
            return dateString
    }

    throw new Error(`Failed to convert '${date}' to DateTimeString!`);
}
