import { createSlice } from "@reduxjs/toolkit";
import { saveAll } from "./shared-save-button-thunks";

const slice = createSlice({
    name: 'shared-save-button',
    initialState: { saving: false },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(saveAll.pending, (state) => {
                state.saving = true;
            })

            .addCase(saveAll.fulfilled, (state) => {
                state.saving = false;
            })
    }
});

export const actions = slice.actions;
export const reducer = slice.reducer;