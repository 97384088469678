import { ContainerStatus } from "@src/lib/types/container-status"

export interface ServerCustomer {
    customerId: number,
    name: string,
    parentId?: number | null,
    directorId?: string | null,
    children: number[],
    infoMessages: number[]
}

export interface Customer extends ServerCustomer {
    chainId: number,
    status: ContainerStatus

    containsDirectors?: boolean // Only loaded for chains by the checkChainsHaveDirectors() thunk
    counters?: { alarms: number, acks: number } // Only loaded for chains by the fetchCountersForCustomers() thunk
}

export enum CustomerFilterEnum {
    All = 0,
    WithScreens = 1,
    WithoutScreens = 2
}

export enum ChannelFilterEnum {
    AllCustomers = 0,
    SelectedCustomer = 1
}