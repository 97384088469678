import MainPage from "@features/main-page"
import SunFlowMain from "@features/sunflow-main"
import { LabeledValue } from "@src/lib/interfaces"
import "@src/lib/jquery-catautocomplete"
import { ContainerStatus } from "@src/lib/types/container-status"
import { selectCurrentChain } from "@states/customer-chain/customer-chain-selectors"
import { selectCurrentCustomer, selectCustomersInCurrentChain } from "@states/customer/customer-selectors"
import store from "@states/store"
import "jquery"
import { setChainWithSideEffects, setCustomerIdWithSideEffects } from "."
import { actions as customerTreeActions } from "./customer-tree-slice"

let lastSelectedPage = store.getState().customerTree.selectedPageTab
let lastCustomer = selectCurrentCustomer(store.getState())
let lastChain = selectCurrentChain(store.getState());

store.subscribe(() => {
    const state = store.getState();

    const customer = selectCurrentCustomer(state);
    const selectedPage = state.customerTree.selectedPageTab
    let triggerRequestViewChange = false;

    const selectedPageHasChanged = lastSelectedPage !== selectedPage;
    lastSelectedPage = selectedPage

    if (customer !== lastCustomer) {
        //console.log('Redux detected customer change', lastCustomer, customer)
        if (customer.customerId !== -1) {
            const chainOverviewEnabled = CustomerTree.getSelectedHasChildren();
            if (!chainOverviewEnabled && state.customerTree.selectedPageTab === 'ChainOverview') {
                MainPage.selectTab('StoreOverview');
            }
            else {
                //console.log('customer-tree customer change trigger view change')
                triggerRequestViewChange = true;
            }
        }
        lastCustomer = customer;
    }

    const currentChain = selectCurrentChain(state);

    if (lastChain !== currentChain) {
        if (currentChain.status === ContainerStatus.Loaded) {
            CustomerTree.refreshSearchBar();
        }
        lastChain = currentChain;
    }

    if (selectedPageHasChanged) {
        //console.log('customer-tree selectedPage trigger view change', selectedPage)
        triggerRequestViewChange = true;
    }

    if (triggerRequestViewChange) {
        MainPage.requestViewChange(customer.customerId, !!customer.directorId);
    }
})

var CustomerTree = {
    changedIsRunning: false,

    getSelectedChainId: () => store.getState().customerTree.selectedChainId,
    getSelectedCustomerId: () => store.getState().customerTree.selectedCustomerId,
    getHasDirector: () => !!selectCurrentCustomer(store.getState()).directorId,

    selectCustomerById: function (customerId: number) {
        store.dispatch(setCustomerIdWithSideEffects(customerId));
    },


    initialize: function () {
        SunFlowMain.readyCallback("customerTree");
    },
    refreshSearchBar: () => {
        $('#tree-search-bar').catAutocomplete({
            source: createSearchSource(),
            select: function (event, ui) {
                const customerToSelect = selectCustomersInCurrentChain(store.getState()).find(c => c.name === ui.item.value); //TODO: Consider storing the customerId in the search source instead
                if (customerToSelect) {
                    store.dispatch(customerTreeActions.setScrollToCustomerWhenSelected(true));
                    store.dispatch(setCustomerIdWithSideEffects(customerToSelect.customerId));
                }
                else {
                    console.warn('Searchbar failed to find id for customer', ui.item.value)
                }
            }
        });
    },

    loadNewCustomerChain: function (newCustomerChainId: number, newCustomerId: number) {
        store.dispatch(customerTreeActions.setScrollToCustomerWhenSelected(true));
        store.dispatch(setChainWithSideEffects(newCustomerChainId))
        store.dispatch(setCustomerIdWithSideEffects(newCustomerId));
    },

    getSelectedCustomerName: () => selectCurrentCustomer(store.getState()).name,
    getSelectedHasChildren: () => selectCurrentCustomer(store.getState()).children.length > 0,

    clear: function () {
        $('#tree-search-bar').catAutocomplete("destroy");
    }
}; // customerTree

export default CustomerTree;

function createSearchSource() {
    const state = store.getState();

    const storesInChain = selectCustomersInCurrentChain(state)
    const preparedNames = storesInChain.map(c => (<LabeledValue>{ label: c.name, category: "Customer Name", value: c.name }));
    const preparedIds = storesInChain.map(c => (<LabeledValue>{ label: c.customerId.toString(), category: "Customer ID", value: c.name }));

    const projectorListToBeConcatenated = selectCurrentChain(state)!.searchInfo

    return [
        ...preparedNames,
        ...preparedIds,
        ...projectorListToBeConcatenated
    ]
}