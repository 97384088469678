import * as React from "react";
import FA from "../../../components/fa";

type OwnProps = {
    expanded: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const expand = {
    text: "View inactive",
    icon: "angle-down"
}

const unexpand = {
    text: "Hide inactive",
    icon: "angle-up"
}

export const ViewAll = (props: OwnProps) => {
    const icon = props.expanded ? unexpand : expand;

    return <div className={"alarm__view-all"}>
        <div className="inner" role="button" onClick={props.onClick}>
            <FA icon={icon.icon} style='s' fixedWidth={true} />
            <p>{icon.text}</p>
        </div>
    </div>
}