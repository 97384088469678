import { useCallback, useRef, useState } from "react";

//Implemented custom hook with useRef() according to: https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
export function useScrollIntoView<T extends HTMLElement>(shouldScroll: boolean, options?: ScrollIntoViewOptions) {
    const ref = useRef<T | null>(null)
    const [hasScrolled, setHasScrolled] = useState(false);

    const setRef = useCallback((node: T) => {
        if (ref.current) {
            //Cleanup here
            setHasScrolled(false)
        }

        if (node) {
            //Test/do scrollIntoView
            if (shouldScroll && !hasScrolled) {
                node.scrollIntoView(options || { behavior: "smooth", block: "center" })
                setHasScrolled(true)
            }
        }

        ref.current = node
    }, [])

    return [setRef]
}
