import * as React from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Target } from "../../lib/targetable";
import { RootState } from "../../states/state";
import { makeMemoizedAlarmCount, makeMemoizedAckedAlarmsCount } from "./device-status-icon-selectors";

interface OwnProps {
    target: Target,
    customerId: number,
}

export const AlarmIcon = (props: OwnProps) => {

    const memoizedAlarms = useMemo(
        () => makeMemoizedAlarmCount(props.customerId, props.target),
        [props.customerId, props.target]
    )

    const memoizedAckedAlarms = useMemo(
        () => makeMemoizedAckedAlarmsCount(props.customerId, props.target),
        [props.customerId, props.target]
    )

    const alarms = useSelector<RootState, number>((state) => memoizedAlarms(state))
    const ackedAlarms = useSelector<RootState, number>(memoizedAckedAlarms)


    if (alarms > 0) {
        return (
            <span className={"label label-small label-alert"}>{alarms}</span>
        )
    }
    else if (ackedAlarms > 0) {
        return (
            <span className={"label label-small"}>{ackedAlarms}</span>
        )
    } else {
        return (
            <span className={"label label-small disabled"}>0</span>
        )
    }
}