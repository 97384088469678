import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { setUnderlying, track } from "mummet-core"
import { DateTimeString, toDateTime } from "../../lib/date-string"
import { dictionaryToArray, filterDictionary } from "../../lib/dictionary-util"
import { isAcknowledgeForAlarm, selectAcknowledge, selectIsAcknowledgeDirty } from "./alarm-selectors"
import {
    closeAcknowledge,
    createAcknowledge,
    fetchAcknowledges,
    fetchAlarmOverview,
    fetchAlarms,
    isAcknowledgeUpdateFailure,
    updateAcknowledges
} from "./alarm-thunks"
import { Acknowledge, AlarmState, MAX_ACKNOWLEDGE_LENGTH_IN_DAYS } from "./alarm-types"

const INITIAL_STATE: AlarmState = {
    alarms: {},
    acknowledges: {},
    overview: {},
    nextAcknowledgeDraftId: -1
}

const slice = createSlice({
    name: 'alarms',
    initialState: INITIAL_STATE,
    reducers: {
        setAcknowledgeComment: (state, action: PayloadAction<{ comment: string, id: number }>) => {
            const { comment, id } = action.payload

            const acknowledge = state.acknowledges[id]

            if (!acknowledge?.current)
                return

            state.acknowledges[id] = {
                ...acknowledge,
                current: {
                    ...acknowledge.current,
                    reason: comment
                }
            }
        },
        setAcknowledgeUntil: (state, action: PayloadAction<{ date: DateTimeString, id: number }>) => {
            let { date, id } = action.payload

            const acknowledge = selectAcknowledge(state.acknowledges, id)

            if (!acknowledge?.current)
                return

            let until = new Date(date)

            const untilMin = new Date()
            untilMin.setMinutes(untilMin.getMinutes() + 1)

            const untilMax = new Date()
            untilMax.setDate(untilMax.getDate() + MAX_ACKNOWLEDGE_LENGTH_IN_DAYS)

            if (until < untilMin)
                until = untilMin

            if (until > untilMax)
                until = untilMax

            state.acknowledges[id] = {
                ...acknowledge,
                current: {
                    ...acknowledge.current,
                    acknowledgedUntil: toDateTime(until)
                }
            }
        },
        setAcknowledgeClearWhenResolved: (state, action: PayloadAction<{clearWhenResolved: boolean, id: number}>) => {
            const {clearWhenResolved, id} = action.payload

            const acknowledge = selectAcknowledge(state.acknowledges, id)

            if (!acknowledge?.current)
                return

            state.acknowledges[id] = {
                ...acknowledge,
                current: {
                    ...acknowledge.current,
                    clearWhenResolved
                }
            }
        },
        setAcknowledge: (state, action: PayloadAction<Acknowledge>) => {
            state.acknowledges[action.payload.id] = track(action.payload)
        },
        setAcknowledgeUnderlying: (state, action: PayloadAction<Acknowledge>) => {
            const newUnderlying = action.payload

            if (newUnderlying.id in state.acknowledges) {
                state.acknowledges[newUnderlying.id].underlying = newUnderlying
            } else {
                state.acknowledges[newUnderlying.id] = {
                    current: null,
                    loaded: null,
                    underlying: newUnderlying
                }
            }
        },
        removeAcknowledge: (state, action: PayloadAction<number>) => {
            const id = action.payload
            if (id in state.acknowledges)
                delete state.acknowledges[action.payload]
        },
        updateOverviewFromLocalAcknowledges: (state, action: PayloadAction<number>) => {
            const customerId = action.payload
            updateOverviewFromLocalAcknowledges(state, customerId)
        },
        incrementDraftId: (state) => {
            state.nextAcknowledgeDraftId--
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAlarms.fulfilled, (state, action) => {
                const args = action.meta.arg
                const alarms = action.payload

                if (args.clear === 'all')
                    clearAlarms({ state })
                else
                    clearAlarms({ state, customerIds: [args.customerId] })

                if (!(args.customerId in state.overview))
                    state.overview[args.customerId] = {}

                alarms.forEach(alarm => {
                    state.alarms[alarm.id] = alarm
                    state.overview[args.customerId][alarm.id] = state.overview[args.customerId]?.[alarm.id] ?? null
                })

                updateOverviewFromLocalAlarms(state, args.customerId)
            })
            .addCase(fetchAcknowledges.fulfilled, (state, action) => {
                const args = action.meta.arg
                const acks = action.payload

                if (args.clear === 'all')
                    clearAcknowledges({ state, keepDirty: true })
                else
                    clearAcknowledges({ state, customerIds: [args.customerId], keepDirty: true })

                state.acknowledges = setUnderlying(state.acknowledges, acks, 'id')

                updateOverviewFromLocalAcknowledges(state, args.customerId)
            })
            .addCase(fetchAlarmOverview.fulfilled, (state, action) => {
                state.overview = action.payload
            })
            .addCase(createAcknowledge.fulfilled, (state, action) => {
                const createdAcknowledge: Acknowledge = action.payload.result
                const draftId: number = action.payload.draftId

                delete state.acknowledges[draftId]
                state.acknowledges[createdAcknowledge.id] = track(createdAcknowledge)

                updateOverviewFromLocalAcknowledges(state, createdAcknowledge.customerId)
            })
            .addCase(createAcknowledge.rejected, (state, action) => {
                const draft = action.payload?.draft

                if (draft === undefined) {
                    console.error("Unexpected error when creating acknowledge", action)
                    return
                }

                delete state.acknowledges[draft.id]
                updateOverviewFromLocalAcknowledges(state, draft.customerId)
            })
            .addCase(updateAcknowledges.fulfilled, (state, action) => {
                const failedUpdates = action.payload.filter(isAcknowledgeUpdateFailure)
                const customerIdsWithFailures = new Set<number>(failedUpdates.map(u => u.previousUnderlying.customerId))

                // Revert individual acknowledge updates that were rejected
                // to their initial underlying state.
                for (const update of failedUpdates) {
                    console.warn("Server rejected acknowledge update.", update.error, update.previousUnderlying)
                    const id = update.previousUnderlying.id

                    if (!(id in state.acknowledges))
                        continue

                    if (id in state.acknowledges) {
                        state.acknowledges[id] = {
                            ...state.acknowledges[id],
                            underlying: update.previousUnderlying
                        }
                    }
                }

                customerIdsWithFailures.forEach(customerId => updateOverviewFromLocalAcknowledges(state, customerId))
            })
            .addCase(updateAcknowledges.rejected, (state, action) => {
                const previousUnderlyings = action.payload?.previousUnderlyings

                if (previousUnderlyings === undefined) {
                    console.error("Unexpected error when updating acknowledges", action)
                    return
                }

                // Revert all updated acknowledges' underlying state
                const customerIds = new Set<number>()
                previousUnderlyings.forEach(previousUnderlying => {
                    const id = previousUnderlying.id

                    if (!(id in state.acknowledges))
                        return

                    state.acknowledges[id] = {
                        ...state.acknowledges[id],
                        underlying: previousUnderlying
                    }
                    customerIds.add(previousUnderlying.customerId)
                })
                customerIds.forEach(customerId => updateOverviewFromLocalAcknowledges(state, customerId))
            })
            .addCase(closeAcknowledge.rejected, (state, action) => {
                const acknowledge = action.payload
                const id = acknowledge?.current?.id

                if (id === undefined) {
                    console.error("Unexpected error when closing acknowledge", action)
                    return
                }

                state.acknowledges[id] = acknowledge!
                updateOverviewFromLocalAcknowledges(state, acknowledge!.current!.customerId)
            })
    }
})

const updateOverviewFromLocalAlarms = (state: AlarmState, customerId: number) => {
    // Remove alarms that no longer exist from overview
    Object.keys(state.overview[customerId] ?? {})
        .map(Number)
        .forEach(alarmId => {
            if (!(alarmId in state.alarms)) {
                delete state.overview[customerId][alarmId]
            }
        })

    const alarms = dictionaryToArray(filterDictionary(state.alarms, alarm => alarm.customerId === customerId))
    const acks = dictionaryToArray(filterDictionary(state.acknowledges, ack => ack.current?.customerId === customerId))

    // Add acknowledge references to alarms
    alarms.forEach(alarm => {
        const acknowledge = acks.find(ack => isAcknowledgeForAlarm(alarm, ack))

        if (acknowledge ?? false) {
            state.overview[customerId][alarm.id] = acknowledge!.current!.id
        }
    })
}

const updateOverviewFromLocalAcknowledges = (state: AlarmState, customerId: number) => {
    const alarms = dictionaryToArray(filterDictionary(state.alarms, alarm => alarm.customerId === customerId))
    const acks = dictionaryToArray(filterDictionary(state.acknowledges, ack => ack.current?.customerId === customerId))

    // Add acknowledge references to alarms
    acks.forEach(acknowledge => {
        const alarm = alarms.find(a => isAcknowledgeForAlarm(a, acknowledge))

        if (alarm ?? false) {
            state.overview[customerId][alarm!.id] = acknowledge.current!.id
        }
    })

    // Remove acknowledge references from alarms
    alarms.forEach(alarm => {
        if (!acks.some(acknowledge => isAcknowledgeForAlarm(alarm, acknowledge))) {
            state.overview[customerId][alarm.id] = null
        }   
    })
}

const clearAlarms = (args: { state: AlarmState, customerIds?: number[] }) => {
    if (!args.customerIds) {
        args.state.alarms = {}
        return
    }

    args.state.alarms = filterDictionary(args.state.alarms, alarm => !args.customerIds!.includes(alarm.customerId))
}

const clearAcknowledges = (args: { state: AlarmState, customerIds?: number[], keepDirty: boolean }) => {
    if (!args.customerIds && !args.keepDirty) {
        args.state.acknowledges = {}
        return
    }

    args.state.acknowledges = filterDictionary(args.state.acknowledges,
        ack => {
            if (!ack.current) return false

            const safeCustomerId = args.customerIds && !args.customerIds.includes(ack.current.customerId)
            const safeDirty = () => args.keepDirty && selectIsAcknowledgeDirty(ack)

            return safeCustomerId || safeDirty()
        })
}

export const actions = slice.actions
export const reducer = slice.reducer