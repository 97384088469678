import xhr from "@src/lib/xhr"

export const fetchProjectorPlaylistRequest = async (
    customerId: number,
    projectorMac: string,
    retryIfAborted: boolean
): Promise<ProjectorPlaylistRequest> => {
    try {
        const {data} = await xhr.get(`/customer/${customerId}/projector/${projectorMac}/playlist?retry-if-aborted=${retryIfAborted}`)
        return mapServerProjectorPlaylistRequest(data)
    } catch (e) {
        console.error("Failed to fetch projector playlist: ", e)
        return {status: PlaylistStatus.Aborted}
    }
}

export const mapServerProjectorPlaylistRequest = (model: any): ProjectorPlaylistRequest => {
    if (!model || Object.keys(model).length === 0) {
        console.warn("Unexpected data in projector playlist request model", model)
        return { status: PlaylistStatus.Aborted }
    }

    return model.status === PlaylistStatus.Success
        ? {status: PlaylistStatus.Success, guid: model.guid}
        : {status: model.status}
}

export enum PlaylistStatus {
    Aborted = -1,
    Success = 0,
    Pending = 1
}

export type ProjectorPlaylistRequest =
    {status: PlaylistStatus.Aborted | PlaylistStatus.Pending} |
    {status: PlaylistStatus.Success, guid: string}