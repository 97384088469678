import ProjectorPlaylistButton from "@features/device-details/components/buttons/projector-playlist-button"
import ProjectorScreenshotButton from "@features/device-details/components/buttons/projector-screenshot-button"
import {
    CommandsButton,
    DeleteButton,
    FactoryResetButton,
    FlowPlannerButton,
    LogsButton,
    MarkBrokenButton,
    RestartButton
} from "@features/device-details/components/buttons/shared-device-buttons"
import { Projector } from "@states/projector"
import * as React from "react"

interface Props {
    projectors: Projector[],
    customerId: number,

    displayFlowPlannerButton: boolean,
    displayRepairCenterButtons: boolean
}

export const ProjectorButtons = (props: Props) => {
    const isSingle = props.projectors.length === 1
    const singleProjector = isSingle ? props.projectors[0] : null

    return <>
        <ProjectorScreenshotButton
            enabled={isSingle}
            projectorMac={singleProjector?.serialNumber ?? ""}
            customerId={props.customerId}
        />

        <ProjectorPlaylistButton
            enabled={isSingle}
            projectorMac={singleProjector?.serialNumber ?? ""}
            customerId={props.customerId}
        />

        {
            props.displayFlowPlannerButton &&
                <FlowPlannerButton
                    enabled={isSingle}
                    customerId={props.customerId}
                    playerId={singleProjector?.serialNumber ?? ""}
                />
        }

        {
            props.displayRepairCenterButtons && <>
                <CommandsButton/>
                <RestartButton/>
                <FactoryResetButton enabled={false}/>

                <LogsButton/>
                <MarkBrokenButton customerId={props.customerId} devices={props.projectors}/>
                <DeleteButton enabled={false}/>
            </>
        }
    </>
}