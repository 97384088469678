import "jquery";
import "jqueryui";
import "es6-promise/auto"; //Polyfill Promise for IE
import "featherlight";
import "./lib/jquery-catautocomplete";
import SunFlowMain from "@features/sunflow-main";

import "@modules/featherlight/src/featherlight.css";
import "@content/LIB/jquery-ui.css";
import "./scss/site.scss";

import store from "@states/store";
import * as moment from "moment";

window.onload = function () {
    moment.locale("en-gb");
    SunFlowMain.initialize();
    store != null; // initialize store... eww
};
