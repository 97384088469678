import { DeviceEvent } from "./device-event-types"
import { createSlice, PayloadAction, Dictionary } from "@reduxjs/toolkit"

const INITIAL_STATE: Dictionary<DeviceEvent> = {}

const slice = createSlice({
    name: 'device-events',
    initialState: INITIAL_STATE,
    reducers: {
        loaded: (state, action: PayloadAction<DeviceEvent[]>) => {
            for (const event of action.payload)
                state[event.id] = event;
        }
    }
})

export const actions = slice.actions;
export const reducer = slice.reducer;
