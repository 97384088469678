import { createSelector, Dictionary } from "@reduxjs/toolkit"
import { ContainerStatus } from "@src/lib/types/container-status"
import { Customer } from "@states/customer/customer-types"
import { RootState } from "@states/state"

export const selectCustomers = (state: RootState) => state.customer
export const selectCustomerList = (customers: Dictionary<Customer>) => Object.keys(customers).map(id => customers[id]!)
export const memoizedCustomerList = createSelector([selectCustomers], selectCustomerList)

export const selectChains = (customers: Dictionary<Customer>) =>
    selectCustomerList(customers)
        .filter(c => c.customerId === c.chainId)
        .sort((a, b) => a.name.localeCompare(b.name))

export const aggregatedStatus = (chains: Customer[]) => chains
    .map(c => c.status)
    .reduce(
        (prev, next) => prev < next ? prev : next,
        ContainerStatus.Loaded
    )

export const aggregateAlarmsCount = (chains: Customer[]) => chains
    .reduce(
        (sum, c) => sum + (c?.counters?.alarms ?? 0),
        0
    )

export const memoizedAggregateAlarmsCount = createSelector([memoizedCustomerList], aggregateAlarmsCount)
