import * as React from "react"
import { useEffect, useState } from "react";
import FA, { FaStyle } from "../../../components/fa";
import { DateString, DateTimeString } from "../../../lib/date-string";

interface StateProps {
    className: string;
    toggleIcon: Icon;
    reminderDate: DateString | null;
    customerId: number;
}

export interface DispatchProps {
    onToggleClick: (e: React.MouseEvent<HTMLButtonElement>, customerId: number) => void,
    onReminderDateChange: (e: React.ChangeEvent<HTMLInputElement>, customerId: number) => void
}

export interface Icon {
    name: string;
    style: FaStyle;
    extraClass?: string;
}

type Props = StateProps & DispatchProps;

export const ReminderStripInternal = (props: Props) => {

    const [dateInputType, setDateInputType] = useState<'date' | 'text'>('date');

    /* HACK: Ensures clearing the reminder's date works.
     * When trying to clear the reminder's date by pressing backspace when selecting a field (day, month, or year),
     * the input element's value gets set to undefined. In Chromium, setting value to undefined does not clear
     * all of the text in the date picker. Only the field you selected gets cleared while the other two remain.
     * Setting the type to 'text' and then back to 'date' fixes this, as documented in:
     * https://stackoverflow.com/a/63063637/2829397 */
    useEffect(() => {
        if (props.reminderDate === null) {
            setDateInputType('text');
            setTimeout(() => setDateInputType('date'), 1);
        }
    }, [props.reminderDate]);

    const toggleClick = (e: React.MouseEvent<HTMLButtonElement>) => { props.onToggleClick(e, props.customerId); }
    const reminderDateChange = (e: React.ChangeEvent<HTMLInputElement>) => { props.onReminderDateChange(e, props.customerId) };
    const iconClassName = "imr-toggle" + (props.toggleIcon.extraClass ? " " + props.toggleIcon.extraClass : "")

    let reminderInputValue: DateTimeString | undefined

    if (props.reminderDate)
        reminderInputValue = props.reminderDate

    return (
        <div className={props.className}>
            <FA className={iconClassName}
                icon={props.toggleIcon.name}
                style={props.toggleIcon.style}
                fixedWidth={true}
                onClick={toggleClick} />
            <input
                className="imr-date input"
                name="Reminder Date"
                type={dateInputType}
                value={reminderInputValue}
                onChange={reminderDateChange} />
        </div>
    )
}