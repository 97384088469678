import * as React from "react"
import FA from "../fa"

export interface MenuItem {
    id: number | string,
    name: string,
    icon?: string,
    disabled?: boolean
}


type ItemProps = {
    item: MenuItem
    onClick?: Function
    selected: boolean
}

const Item = (props: ItemProps) => {
    const onClick = () => props.onClick && (props.item.disabled !== true) && props.onClick(props.item)
    let className: string | undefined;

    if (props.item.disabled === true)
        className = "menu--disabled"
    else if (props.selected)
        className = "menu--selected"

    return (
        <li className={className} onClick={onClick}>
            {props.item.icon && <FA icon={props.item.icon} />}
            {props.item.name}
        </li>
    )
}

type Props = {
    items: MenuItem[],
    selected: MenuItem,
    onClick?: (e: MenuItem) => void
}

export const Menu = (props: Props) => (
    <ul className="menu">
        {props.items.map(item => <Item key={item.id} item={item} selected={item === props.selected} onClick={props.onClick} />)}
    </ul>
)