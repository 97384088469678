import { RootState } from "@states/state";
import { AppDispatch } from "@states/store"
import { DispatchProps, Icon, ReminderStripInternal } from "./reminder-strip-internal"
import { connect } from "react-redux"
import { actions } from "@states/info-message"
import { selectHasReminder, createMemoizedMessage, selectIsReminderClosed } from "../info-message-box-selectors"
import { createSelector } from "@reduxjs/toolkit"
import { toDate } from "../../../lib/date-string";
import { selectIsReminderDirty, selectIsReminderDue } from "@states/info-message/info-message-selectors"
import { selectCustomerId } from "../../customer-tree/customer-tree-selectors";
import { Target } from "../../../lib/targetable";

interface OwnProps {
    target: Target
}

const noReminderIcon: Icon     = { name: 'bell',       style: 'r' }
const dueReminderIcon: Icon    = { name: 'bell',       style: 's', extraClass: 'color-alert' }
const openReminderIcon: Icon   = { name: 'bell',       style: 's' }
const closedReminderIcon: Icon = { name: 'bell-slash', style: 'r' }

const makeMapStateToProps = () => {
    const memoizedMessage = createMemoizedMessage();

    const memoizedHasReminder = createSelector([memoizedMessage], selectHasReminder);
    const memoizedIsReminderDirty = createSelector([memoizedMessage], selectIsReminderDirty);
    const memoizedIsReminderClosed = createSelector([memoizedMessage], selectIsReminderClosed);
    const memoizedIsReminderDueAndOpen = createSelector([memoizedMessage], message => selectIsReminderDue(message) && !selectIsReminderClosed(message));

    const memoizedToggleIcon = createSelector(
        [memoizedHasReminder, memoizedIsReminderDueAndOpen, memoizedIsReminderClosed],
        (hasReminder, isDueAndOpen, isClosed) => {
            return !hasReminder
                ? noReminderIcon
                : isDueAndOpen
                    ? dueReminderIcon
                    : isClosed
                        ? closedReminderIcon
                        : openReminderIcon;
        })

    const memoizedClassName = createSelector([memoizedIsReminderDirty, memoizedIsReminderDueAndOpen],
        (dirty, dueAndOpen) => "im-reminder" + (dirty ? " tracked-dirty" : (dueAndOpen ? " imr-due" : "")));

    const memoizedReminderDate = createSelector(
        [memoizedHasReminder, memoizedMessage],
        (hasReminder, message) => hasReminder ? message.current!.reminder!.date : null
    );

    return function mapStateToProps(state: RootState, props: OwnProps) {
        return ({
            className: memoizedClassName(state, props),
            toggleIcon: memoizedToggleIcon(state, props),
            reminderDate: memoizedReminderDate(state, props),
            customerId: selectCustomerId(state)
        });
    }
}

const mapReminderDispatchToProps = (dispatch: AppDispatch, props: OwnProps): DispatchProps => ({
    onToggleClick: (_, customerId: number) => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        dispatch(actions.toggleReminderStatus({ target: props.target, fallbackDate: toDate(tomorrow), customerId}));
    },
    onReminderDateChange: (e, customerId: number) => {
        const date = e.target.valueAsDate && toDate(e.target.valueAsDate);
        dispatch(actions.setReminder({ date, target: props.target, customerId }))
    } 
})

export default connect(makeMapStateToProps, mapReminderDispatchToProps)(ReminderStripInternal)