import { createAsyncThunk } from "@reduxjs/toolkit"
import { delay } from "@src/lib/async-util"
import { fetchProjectorPlaylistRequest, PlaylistStatus, ProjectorPlaylistRequest } from "@src/lib/projector-playlist"
import { actions as messageActions } from "@states/message/message-slice"
import { selectLoadedProjector } from "@states/projector/projector-selectors"
import {
    PLAYLIST_FETCH_INTERVAL,
    PLAYLIST_FETCH_TIMEOUT,
    projectorFromServerModel
} from "@states/projector/projector-util"
import xhr from "../../lib/xhr"
import { ThunkArgs } from "../state-types"
import { actions as projectorActions } from "./projector-slice"
import { Projector } from "./projector-types"

const showPlaylistAbortedError = (projector: string) =>
    messageActions.showError({
        header: "Something went wrong.",
        content: `Could not load playlist preview for the projector: ${projector}`
    })

const showMarkBrokenFailedError = (projector: string) =>
    messageActions.showError({
        header: "Something went wrong.",
        content: `Could not update broken status for the projector: ${projector}`
    })

export const fetchProjectorPlaylist = createAsyncThunk<ProjectorPlaylistRequest, {customerId: number, projectorSerialNo: string}, ThunkArgs>
    ('projector/fetchProjectorPlaylist',
        async (input, thunk) => {
            const {customerId, projectorSerialNo} = input

            let isCancelled = false
            setTimeout(() => { isCancelled = true }, PLAYLIST_FETCH_TIMEOUT)

            let retryIfAborted = true // on the first request, allow retrying if previous fetch was aborted

            do {
                const playlist = await fetchProjectorPlaylistRequest(customerId, projectorSerialNo, retryIfAborted)
                retryIfAborted = false

                switch (playlist.status) {
                    case PlaylistStatus.Success:
                        return playlist

                    case PlaylistStatus.Aborted:
                        thunk.dispatch(showPlaylistAbortedError(projectorSerialNo))
                        return playlist

                    case PlaylistStatus.Pending:
                        await delay(PLAYLIST_FETCH_INTERVAL, {cancelCheck: () => isCancelled})
                }
            } while (!isCancelled)

            console.warn("Server didn't return a playlist in time.")
            thunk.dispatch(showPlaylistAbortedError(projectorSerialNo))
            return {status: PlaylistStatus.Aborted}
    })

export const fetchHasOfflineAlarm = createAsyncThunk<void, {projectorMac: string}, ThunkArgs>
    ('projector/fetchHasOfflineAlarm',
        async (input, thunkApi) => {
            const state = thunkApi.getState()
            const customerId = state.customerTree.selectedCustomerId
            const projector = selectLoadedProjector(state, input.projectorMac)
            if (projector !== null) {
                try {
                    const { data: hasOfflineAlarm } = await xhr.get<boolean>(`/Projector/GetHasOfflineAlarm?customerId=${customerId}&projectorId=${projector.numericalId}`)
                    thunkApi.dispatch(projectorActions.addOrReplace({ ...projector, hasOfflineAlarm }))
                } catch (e) {
                    console.error("Get projector hasOfflineAlarm failed.: ", e)
                }
            }
        })

export const fetchProjector = createAsyncThunk<Projector, { projectorMac: string }, ThunkArgs & { rejectValue: { projectorMac: string } }>
('projector/get',
    async (input, thunkApi) => {
        const projectorMac = input.projectorMac

        const existingProjector = selectLoadedProjector(thunkApi.getState(), projectorMac)
        const existingScreenshot = existingProjector?.screenshot ?? null
        const existingPlaylist = existingProjector?.playlist ?? null

        try {
            const { data: model } = await xhr.get(`/projector/${projectorMac}/details`)

            const projector = projectorFromServerModel(model)

            if (projector === null) {
                console.error("Server returned an invalid projector", projectorMac, projector)
                return thunkApi.rejectWithValue({ projectorMac: projectorMac })
            }

            projector.screenshot = existingScreenshot
            projector.playlist = existingPlaylist
            return projector
        } catch (e) {
            console.error("Failed to fetch projector", projectorMac, e)
            return thunkApi.rejectWithValue({ projectorMac: projectorMac })
        }
    })

export const setProjectorBroken = createAsyncThunk<
    void,
    {projector: Projector} & ({broken: true, reason: string} | {broken: false}),
    ThunkArgs
>('projector/setBroken',
    async (input, thunkApi) => {
        const {projector} = input

        try {
            if (input.broken) {
                await xhr.put(`/projector/${projector.serialNumber}/broken?reason=${input.reason}`)
            } else {
                await xhr.delete(`/projector/${projector.serialNumber}/broken`)
            }

            return
        } catch (e) {
            console.error("Failed to update projector broken status", input, e)
            thunkApi.dispatch(showMarkBrokenFailedError(projector.name))
            return thunkApi.rejectWithValue(e)
        }
    })