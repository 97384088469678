import * as React from 'react';
import { AppDispatch } from '@states/store';
import { connect } from 'react-redux';
import { InfoMessage} from '@states/info-message';
import MainPage from '@features/main-page';
import { RootState } from '@states/state';
import { memoizedRemindersAsList, selectMessagesAsList } from '@states/info-message/info-message-selectors';
import InfoMessageListCard from './info-message-list-card';
import { selectChainId } from '@features/customer-tree/customer-tree-selectors';

interface StateProps {

    infoMessages: InfoMessage[],
    chainName: string

}

interface DispatchProps {
    navigateTo: (imId: number) => void
}

interface OwnProps {
    closeInfoMessageList: () => void
}

type Props = StateProps & DispatchProps & OwnProps;

const makeMapStateToProps = () => {
    const selectChainName = (state: RootState) => {
        var customer = state.customer[selectChainId(state)]
        if (customer == undefined) {
            return "loading..."
        }
        else {
            return customer.name;
        }
    }

    return function mapStateToProps(state: RootState, props: OwnProps): StateProps {
        return ({
            infoMessages: memoizedRemindersAsList(state),
            chainName: selectChainName(state)
        });
    }
}

const mapDispatchToProps = (dispatch: AppDispatch, props: OwnProps): DispatchProps => ({
    navigateTo: (imId: number) => MainPage.navigateToInfoMessageTarget(imId)
});

const InfoMessageList = (props: Props) => (
    <div className="info-message-list-fanout">
        <div className="info-message-title">
            Notifications
        </div>
        <div className="info-message-filter-options">
            By Date
        </div>
        <div className="info-message-card-list">
            {props.infoMessages.slice(0, 12).map(im => {
                return <InfoMessageListCard closeInfoMessageList={props.closeInfoMessageList} infoMessage={im} key={im.id} />
            })}
        </div>
    </div>
);

export default connect(makeMapStateToProps, mapDispatchToProps)(InfoMessageList)