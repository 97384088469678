import { selectChannels, selectChannelsWithName, selectParents } from "@states/channel/channel-selectors"
import { Channel } from "@states/channel/channel-types"
import { Projector } from "@states/projector"
import { findModified } from "mummet-core"
import { Dictionary, Tracked } from "mummet-core/dist/types"
import { RootState } from "../state"
import { BrightTime } from "./brighttime-types"

export const selectBrightTimes = (state: RootState) => state.brightTime

export const selectDirtyBrightTimes = (brightTimes: Dictionary<Tracked<BrightTime>>) =>
    findModified(brightTimes)
        .filter(bt => bt.current?.name !== bt.underlying?.name
            || bt.current?.description !== bt.underlying?.description
        )

export const selectShineDefinitions = (brightTimes: Dictionary<Tracked<BrightTime>>, id: number) => {
    if (!id || !(id in brightTimes))
        return []

    return brightTimes[id].current!.definitions
}

export function selectNearestBrightTime(state: RootState, projector: Projector | null) {
    if (projector === null || !projector.channels || projector.channels.length === 0)
        return null

    const parentChannels = getChannelParents(projector.channels[0], selectChannels(state))
    return getNearestBrightTimeFromChannels(parentChannels, selectBrightTimes(state))
}

function getChannelParents(nearestChannel: string, channels: Dictionary<Tracked<Channel>>) {
    const channelsWithName = selectChannelsWithName(channels, nearestChannel)

    if (!channelsWithName || channelsWithName.length === 0) {
        return []
    }

    const parentChannel = channelsWithName[0]

    if (!parentChannel?.id) {
        return []
    }
    return selectParents(channels, parentChannel.id)
}

function getNearestBrightTimeFromChannels(channels: Channel[], brightTimes: Dictionary<Tracked<BrightTime>>){
    for (const channel of channels) {
        if (channel.brightTimeId !== undefined && brightTimes[channel.brightTimeId]?.current) {
            return brightTimes[channel.brightTimeId].current
        }
    }
    return null
}