import { createAsyncThunk, Dictionary } from "@reduxjs/toolkit"
import xhr, { isXhrError } from "@src/lib/xhr"
import { actions as chainActions } from "@states/customer-chain/customer-chain-slice"
import { CustomerChain, CustomerIndexResponse, ServerChainInfo } from "@states/customer-chain/customer-chain-types"
import { ThunkArgs } from "@states/state-types"
import { AppDispatch } from "@states/store"

export const loadChains = createAsyncThunk<ServerChainInfo[], void, ThunkArgs
    >('/customer/chains',
    async () => {
        const { data: items } = await xhr.get<ServerChainInfo[]>('/Customer/Chains')
        return items
    })

export const checkChainsHaveDirectors = createAsyncThunk<Dictionary<Boolean>, number[], ThunkArgs>(
    '/chain/check-have-directors',
    async (chainIds) => {
        if (chainIds.length === 0)
            return {}

        const { data } = await xhr.post<Dictionary<Boolean>>('/Director/Chains/', chainIds)
        return data
    }
)

export async function loadChainContainerAsync(chain: CustomerChain, dispatch: AppDispatch) {
    dispatch(chainActions.loading({ id: chain.id }))

    try {
        const { data } = await xhr.get<CustomerIndexResponse>(`/Customer/Index?id=${chain.id}`)

        dispatch(chainActions.loaded({
            id: chain.id,
            brightTimes: data.brightTimes,
            channels: data.channels.sort((a, b) => a.name.localeCompare(b.name)),
            customers: data.customers,
            searchInfo: data.searchInfo
        }))
    }
    catch (error) {
        let errorMessage = "Unknown response"

        if (isXhrError(error) && error.response)
            errorMessage = error.response.statusText

        console.warn('Server error', error)
        dispatch(chainActions.error({ id: chain.id, statusText: errorMessage || 'Error' }))
    }
}