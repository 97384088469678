import MainPage from "./main-page";

var Integrator = {
    loadedCustomerId: -1,
    hasDirector: null as boolean | null,
    requestViewChange: function (newCustomerId: number, newHasDirector: boolean) {
        MainPage.onViewChanging();
        Integrator.loadedCustomerId = newCustomerId;
        Integrator.hasDirector = newHasDirector;

        var $target = $("#main-page-view");
        $target.empty();

        MainPage.fadeInOverlay();
        return $.ajax({
            url: "/Main/IntegratorView",
            type: "POST",
            data: JSON.stringify({ 'customerId': newCustomerId }),
            dataType: "html",
            contentType: "application/json; charset=utf-8",
            success: function (result) {
                $target.html(result);
                MainPage.checkIfLoggedInAfterAjax($target);
                Integrator.bindEvents();
            },
            error: function () {
                alert("An error has occured in Integrator.requestViewChange()");
            },
            complete: function () {
                MainPage.fadeOutOverlay();
            }
        });

    },
    bindEvents: function () {

    },

    unbindEvents: function () {

    },
    clear: function () {
        Integrator.loadedCustomerId = -1;
        //Integrator.loadedHasDirector = null; //MAAS: Expected to be hasDirector?
    }
};

export default Integrator;