import { Director } from "@states/director/director-types"
import { track } from "mummet-core"

export function isDirector(director: any): director is Director {
    return director?.type === "director"
}

/**
 * Maps a DirectorDetailsViewModel from the server to a {@link Director}.
 */
export const directorFromServerModel = (model: any, customerId: number): Director | null => {
    if (model === null || model === undefined)
        return null

    if (Object.keys(model).length === 0)
        return null

    return {
        type: "director",

        id: model.id,
        numericalId: model.numericalId,
        customerId,

        ip: "Unknown",
        serialNumber: model.mac,
        replacementMac: model.replacementMac,

        lastSeen: model.lastSeen,
        installedVersion: model.installedVersion,
        hasOfflineAlarm: model.hasOfflineAlarm,

        broken: track(model.isBroken ? {reason: model.brokenReason} : null)
    }
}