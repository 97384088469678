import xhr from "@src/lib/xhr"
import { CustomerChain } from "@states/customer-chain/customer-chain-types"
import { actions as deviceEventActions } from "@states/device-event/device-event-slice"
import { DeviceEvent } from "@states/device-event/device-event-types"
import { AppDispatch } from "@states/store"

export async function loadChainActionsAsync(chain: CustomerChain, dispatch: AppDispatch) {
    try {
        const { data: deviceEvents } = await xhr.get<DeviceEvent[]>(`/Customer/DeviceEvents?id=${chain.id}`)
        dispatch(deviceEventActions.loaded(deviceEvents))
    }
    catch (error) {
        console.warn('Server error', error)
    }
}