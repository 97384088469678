import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PlaylistStatus } from "@src/lib/projector-playlist"
import { actions as chainActions } from "@states/customer-chain/customer-chain-slice"
import { fetchProjector, fetchProjectorPlaylist, setProjectorBroken } from "@states/projector/projector-thunks"
import { projectorPlaylistFromGuid } from "@states/projector/projector-util"
import { Projector, ProjectorsState, TimeStampedUrl } from "./projector-types"

const INITIAL_STATE: ProjectorsState = {
    loaded: {},
    fetching: []
}

const slice = createSlice({
    name: 'projectors',
    initialState: INITIAL_STATE,
    reducers: {
        addOrReplace: (state, action: PayloadAction<Projector>) => {
            const projector = action.payload;
            addOrReplaceProjector(state, projector)
        },

        setScreenshot: (state, action: PayloadAction<{ projectorMac: string, screenshot: TimeStampedUrl | null | 'loading' }>) => {
            const projectorMac = action.payload.projectorMac
            const projector = state.loaded[projectorMac]

            if (!projector)
                return

            projector.screenshot = action.payload.screenshot
        }
    },
    extraReducers: builder => { builder
        .addCase(fetchProjector.pending, (state, action) => {
            const projectorMac = action.meta.arg.projectorMac
            state.fetching = [...state.fetching, projectorMac]
        })
        .addCase(fetchProjector.fulfilled, (state, action: PayloadAction<Projector>) => {
            const projector: Projector = action.payload

            addOrReplaceProjector(state, projector)
            removeFetchingStatus(state, projector.serialNumber)
        })
        .addCase(fetchProjector.rejected, (state, action) => {
            const projectorMac = action.meta.arg.projectorMac

            removeProjector(state, projectorMac)
            removeFetchingStatus(state, projectorMac)
        })
        .addCase(fetchProjectorPlaylist.pending, (state, action) => {
            const projector = state.loaded[action.meta.arg.projectorSerialNo]

            if (!projector)
                return

            projector.playlist = 'loading'
        })
        .addCase(fetchProjectorPlaylist.fulfilled, (state, action) => {
            const projector = state.loaded[action.meta.arg.projectorSerialNo]

            if (!projector)
                return

            projector.playlist = action.payload.status === PlaylistStatus.Success
                ? projectorPlaylistFromGuid(action.payload.guid)
                : null
        })
        .addCase(fetchProjectorPlaylist.rejected, (state, action) => {
            const projector = state.loaded[action.meta.arg.projectorSerialNo]

            if (projector)
                projector.playlist = null
        })

        .addCase(setProjectorBroken.pending, (state, action) => {
            const arg = action.meta.arg

            const projector = state.loaded[arg.projector.serialNumber]
            if (!projector) return

            if (arg.broken)
                projector.broken.current = {reason: arg.reason}
            else
                projector.broken.current = null
        })
        .addCase(setProjectorBroken.fulfilled, (state, action) => {
            const arg = action.meta.arg

            const projector = state.loaded[arg.projector.serialNumber]
            if (!projector) return

            if (arg.broken)
                projector.broken.underlying = {reason: arg.reason}
            else
                projector.broken.underlying = null
        })
        .addCase(setProjectorBroken.rejected, (state, action) => {
            const arg = action.meta.arg

            const projector = state.loaded[arg.projector.serialNumber]
            if (!projector) return

            projector.broken.current = projector.broken.underlying
        })

        .addCase(chainActions.clearChainInfo, (state) => {
            state.loaded = {}
        })
    }
})

function addOrReplaceProjector(state: ProjectorsState, projector: Projector) {
    state.loaded[projector.serialNumber] = projector;
}

function removeProjector(state: ProjectorsState, projectorMac: string) {
    delete state.loaded[projectorMac]
}

function removeFetchingStatus(state: ProjectorsState, projectorMac: string) {
    const index = state.fetching.indexOf(projectorMac)
    state.fetching = state.fetching.filter((_, i) => i !== index)
}

export const actions = slice.actions;
export const reducer = slice.reducer;