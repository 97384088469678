import DirectorOverview from "@features/director-overview/director-overview"
import MainPage from "@features/main-page"
import { alertGenericErrorAndReloadPage } from "@src/error-handling"

const directorDetails =
{
    deleteDirector: function (customerId: number) {
        const $target = $("#inner-content");
        MainPage.fadeInOverlay();
        MainPage.fadeInSidebar();
        $.ajax({
            url: "/Main/DeleteDirector",
            type: "POST",
            dataType: "text",
            data: { 'customerId': customerId },
            success: function (result) {
                if (result === 'True') {
                    MainPage.checkIfLoggedInAfterAjax($target);
                    DirectorOverview.requestViewChange(customerId, null, true);
                }
                else {
                    alertGenericErrorAndReloadPage()
                }
            },
            error: function () {
                alertGenericErrorAndReloadPage()
            },
            complete: function () {
                MainPage.fadeOutOverlay();
                MainPage.fadeOutSidebar();
            }
        })
    }
}

export default directorDetails;