import * as React from "react";
import FA from "../../../components/fa";
import { Alarm } from "./alarm";

const NoAlarms = () => {
    return <Alarm
        header="All is fine"
        color='success'
        headerRight={<FA icon="check" style="s" />}>
    </Alarm>
}

export default NoAlarms;