import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleSave as handleSaveMessages } from "@states/info-message/info-message-thunks";
import { handleSave as handleSaveChannels } from "@states/channel/channel-thunks";
import { handleSave as handleSaveBrighttimes } from "@states/brighttime/brighttime-thunks";
import { handleSaveAcknowledges } from "@states/alarm/alarm-thunks";
import { selectDirtyMessages, selectMessages } from "@states/info-message/info-message-selectors";
import { RootState } from "@states/state";
import { AppDispatch } from "@states/store";

interface ThunkArgs { dispatch: AppDispatch, state: RootState };

const MIN_SAVE_TIME = 250;

export const saveAll = createAsyncThunk<void, void, ThunkArgs>("sharedSaveButton/saveAll",
    async (_, thunkApi) => {
        const state = thunkApi.getState();
        const messages = selectDirtyMessages(selectMessages(state));

        const tasks = [] as Promise<any>[];

        // Force the "visual save state" to take at least a small amount of time.
        // This prevents the save icon flashing too quickly.
        tasks.push(new Promise(resolve => setTimeout(resolve, MIN_SAVE_TIME)));

        messages.forEach(msg => {
            tasks.push(thunkApi.dispatch(handleSaveMessages(msg.underlying!.target)));
        })

        tasks.push(thunkApi.dispatch(handleSaveChannels()));

        tasks.push(thunkApi.dispatch(handleSaveBrighttimes()));

        tasks.push(thunkApi.dispatch(handleSaveAcknowledges()));

        await Promise.all(tasks);
    });
