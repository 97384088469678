import CustomerTree from "@features/customer-tree/customer-tree"
import { selectCustomerId } from "@features/customer-tree/customer-tree-selectors"
import { actions as customerTreeActions } from "@features/customer-tree/customer-tree-slice"
import deviceDetails from "@features/device-details/device-details-legacy"
import DirectorOverview from "@features/director-overview/director-overview"
import { KeyedCallback } from "@src/lib/interfaces"
import { selectChainForCustomer } from "@states/customer/customer-selectors"
import { initializeDeviceFetching } from "@states/device-overview/device-overview"
import { selectMessageOrNullFromId, selectMessages } from "@states/info-message/info-message-selectors"
import store from "../states/store"
import Broken from "./broken"
import ChainOverview from "./chain-overview"
import { SelectedTabValue } from "./customer-tree/customer-tree-types"
import Integrator from "./integrator"
import Setup from "./setup"
import StoreOverview from "./store-overview"
import SunFlowMain from "./sunflow-main"

const IDLE_TIMEOUT = 60000

const MainPage = {
    clickingOnProjector: <boolean><unknown>undefined, //MAAS: Required by DirectorOverview, but should probably not be on this object at all
    clickingOnDirector: <boolean><unknown>undefined, //MAAS: Required by DirectorOverview, but should probably not be on this object at all
    fadeOverlayShowing: false,
    resizeCallback: <KeyedCallback[]>[],
    outsideClickCallback: <KeyedCallback[]>[],
    userIsIdleCallback: <KeyedCallback[]>[],
    lastClickedElement: <HTMLElement><unknown>null,
    idleTime: 0,
    initialized: false,

    getSelectedTab: () => store.getState().customerTree.selectedPageTab,
    selectTab: (name: SelectedTabValue) => store.dispatch(customerTreeActions.selectPageTab(name)),
    unbindEvents: function () {
        $(window).unbind('resize')
        $(document).unbind('mousedown')
        $(document).unbind('mouseup')
        $(document).unbind('mousemove')
        $(document).unbind('keypress')
    },
    bindEvents: function () {
        $(window).resize(function () {
            if (MainPage.resizeCallback.length > 0) {
                $.each(MainPage.resizeCallback, function (i, item) {
                    item.delegateMethod()
                })
            }
        })

        $(document)
            .on('mousedown', function (e) {
                if (MainPage.clickedOnScrollbar(e.clientX)) {
                    MainPage.lastClickedElement = <HTMLElement><unknown>null

                }
                else {
                    MainPage.lastClickedElement = <HTMLElement><unknown>e.target
                }
            })
            .on('mouseup', function (e) {
                if (!MainPage.clickedOnScrollbar(e.clientX)) {
                    if (MainPage.outsideClickCallback.length > 0) {
                        $.each(MainPage.outsideClickCallback, function (i, item) {
                            item.delegateMethod(<HTMLElement><unknown>e.target, MainPage.lastClickedElement)
                        })
                    }
                }
                MainPage.lastClickedElement = <HTMLElement><unknown>null
            })
            .on('mousemove', function (e) {
                MainPage.resetIdleTime()
            })
            .on('keypress', function (e) {
                MainPage.resetIdleTime()
            })
    },
    requestViewChange: function (newCustomerId: number, hasDirector: boolean) {
        if (MainPage.getSelectedTab() === 'Overview') {
            DirectorOverview.requestViewChange(newCustomerId, <string><unknown>null, false)
            Setup.clear()
            Broken.clear()
            Integrator.clear()
            ChainOverview.clear()
        }
        else if (MainPage.getSelectedTab() === 'Setup') {
            Setup.requestViewChange(newCustomerId, hasDirector)
            Broken.clear()
            Integrator.clear()
            ChainOverview.clear()
        }
        else if (MainPage.getSelectedTab() === 'Broken') {
            Broken.requestViewChange(newCustomerId, <string[]><unknown>null, hasDirector, false)
            Setup.clear()
            Integrator.clear()
            ChainOverview.clear()
        }
        else if (MainPage.getSelectedTab() === 'Integrator') {
            Integrator.requestViewChange(newCustomerId, hasDirector)
            Setup.clear()
            Broken.clear()
            ChainOverview.clear()
        }
        else if (MainPage.getSelectedTab() === 'StoreOverview') {
            StoreOverview.requestViewChange(newCustomerId)
            Setup.clear()
            Broken.clear()
            Integrator.clear()
            ChainOverview.clear()
        }
        else if (MainPage.getSelectedTab() === 'ChainOverview') {
            ChainOverview.requestViewChange(newCustomerId)
            Setup.clear()
            Broken.clear()
            Integrator.clear()
        }
        else {
            console.warn("main-page.requestViewChange() to unknown tab. Clearing all.", MainPage.getSelectedTab())
            $("#main-page-view").empty()

            Setup.clear()
            Broken.clear()
            Integrator.clear()
            ChainOverview.clear()
        }
    },
    onViewChanging: function () {
        // if current visible view has events
        // unbind them.
        const customerId = selectCustomerId(store.getState())
        if (customerId !== -1) {
            DirectorOverview.unbindEvents()
        }
        if (Setup.loadedCustomerId != -1) {
            Setup.unbindEvents()
        }
        if (Broken.loadedCustomerId != -1) {
            Broken.unbindEvents()
        }
        if (Integrator.loadedCustomerId != -1) {
            Integrator.unbindEvents()
        }
    },
    customerChainIdChanged: function (newCustomerChainId: number, newCustomerId: number) {
        CustomerTree.loadNewCustomerChain(newCustomerChainId, newCustomerId)
    },
    navigateToInfoMessageTarget: function (imId: number) {
        const im = selectMessageOrNullFromId(selectMessages(store.getState()), imId)
        if (im != null) {
            MainPage.selectTab("Overview")
            const chainNode = selectChainForCustomer(store.getState(), im.current!.customerId)
            MainPage.customerChainIdChanged(chainNode!.customerId, im.current!.customerId)
        }
    },
    checkIfLoggedInAfterAjax: function ($target: JQuery) {
        if ($('#login-form', $target).length)
            window.location.reload()
    },
    fadeInSidebar: function () {
        $('#side-bar').toggleClass('loading', true)
    },
    fadeOutSidebar: function () {
        $('#side-bar').toggleClass('loading', false)
    },
    fadeInOverlay: function () {
        $('#main-page').toggleClass('loading', true)
    },
    fadeOutOverlay: function () {
        $('#main-page').toggleClass('loading', false)
    },
    registerResizeCallback: function (key: any, delegate: Function) {
        let hasKey = false
        $.each(MainPage.resizeCallback, function (i, item) {
            if (item.key === key) {
                hasKey = true
                return false // break out of each.
            }
            return true
        })
        if (!hasKey) {
            MainPage.resizeCallback.push({ 'key': key, 'delegateMethod': delegate })
        }
    },
    unregisterResizeCallback: function (keyToRemove:any) {
        MainPage.resizeCallback = $.grep(MainPage.resizeCallback, function (item) {
            return item.key !== keyToRemove
        })
    },
    registerOutsideClickCallback: function (key: string, delegate: (compareUpTarget: HTMLElement, compareDownTarget: HTMLElement) => void) {
        let hasKey = false
        $.each(MainPage.outsideClickCallback, function (i, item) {
            if (item.key === key) {
                hasKey = true
                return false // break out of each.
            }
            return true
        })
        if (!hasKey) {
            MainPage.outsideClickCallback.push({ 'key': key, 'delegateMethod': delegate })
        }
    },
    unregisterOutsideClickCallback: function (keyToRemove: any) {
        MainPage.outsideClickCallback = $.grep(MainPage.outsideClickCallback, function (item) {
            return item.key !== keyToRemove
        })
    },
    isTargetOutsideElement: function (compareTarget:any, $element: JQuery) {
        if (compareTarget == null) {
            return false
            // default to false if compareTarget is false.
            // we do this because we do not wish to run any outside click logic
            // if a click came from outside the document.
        }
        // check if target is element or descendent of element.
        let $jqTarget = $(compareTarget)
        if (!$element.is(compareTarget)
            && $element.has(compareTarget).length === 0
            && $jqTarget.closest('.ui-widget').length === 0
            && $jqTarget.closest('.featherlight').length === 0) {
            return true
        }
        else {
            return false
        }
    },
    clickedOnScrollbar: function (mouseX: number) {
        return $(window).outerWidth() as number <= mouseX
    },
    registerUserIsIdleCallback: function (key: any, delegate: Function) {
        let hasKey = false
        $.each(MainPage.userIsIdleCallback, function (i, item) {
            if (item.key === key) {
                hasKey = true
                return false // break out of each.
            }
            return true
        })
        if (!hasKey) {
            MainPage.userIsIdleCallback.push({ 'key': key, 'delegateMethod': delegate })
        }
    },
    unregisterUserIsIdleCallback: function (keyToRemove:any) {
        MainPage.userIsIdleCallback = $.grep(MainPage.userIsIdleCallback, function (item) {
            return item.key !== keyToRemove
        })
    },
    incrementIdleTime: function () {
        if (MainPage.idleTime > 0) {
            MainPage.idleTime = 0

            for (let item of MainPage.userIsIdleCallback) {
                item.delegateMethod(false)
            }
        }
        else {
            MainPage.idleTime++
        }
    },
    resetIdleTime: function () {
        MainPage.idleTime = 0
    },
    initialize: function () {
        if (!MainPage.initialized) {
            MainPage.initialized = true
            MainPage.bindEvents()

            ChainOverview.initialize()
            StoreOverview.initialize()
            deviceDetails.initialize()

            initializeDeviceFetching()

            MainPage.selectTab(CustomerTree.getSelectedHasChildren() ? 'ChainOverview' : 'StoreOverview')

            setInterval(MainPage.incrementIdleTime, IDLE_TIMEOUT)
            SunFlowMain.readyCallback("mainPage")
        }
    }
}

export default MainPage