import { alertError, alertGenericError } from "@src/error-handling"
import SunFlowMain from "./sunflow-main";
import MainPage from "./main-page";
import DevicesRepairCentre from "./devices-repair-centre";
import RunningCommands from "./running-commands";

var Logs = {
    searchWord: null as string | null,
    startDate: null as string | null,
    stopDate: null as string | null,
    errorAnchorLookup: <number[]>[],
    topMostDeviceLogPart: null as number | null,
    shouldReloadLogViewAfterDialog: false,
    showingDeviceLog: false,
    initializeAndBindEvents: function () {
        if (DevicesRepairCentre.workMode == 'live') {
            RunningCommands.updateRunningCommandsView();
        }
        Logs.bindEvents();
    },
    doSearch: function () {
        var urlToUse;
        if (DevicesRepairCentre.workMode === 'live') {
            urlToUse = '/Main/DeviceRepairLogs';
        }
        else {
            urlToUse = '/Main/DeviceRepairLogHistory';
        }
        var $target = $('#repair-main-view');

        $('#repair-container').toggleClass('loading', true);
        return $.ajax({
            url: urlToUse,
            type: "POST",
            data: JSON.stringify({
                'customerId': DevicesRepairCentre.loadedCustomerId,
                'selectedDevices': DevicesRepairCentre.selectedDevices,
                'searchWord': Logs.searchWord,
                'startDate': Logs.startDate,
                'stopDate': Logs.stopDate
            }),
            dataType: "html",
            contentType: "application/json; charset=utf-8",
            success: function (result) {
                Logs.unbindEvents();
                $target.html(result);
                Logs.bindEvents();
                MainPage.checkIfLoggedInAfterAjax($target);
            },
            error: function () {
                alertError("Search failed.");
            },
            complete: function () {
                $('#repair-container').toggleClass('loading', false);
            }
        });
    },
    downloadLogFile: function (customerId: number, deviceId: string, date: string, fileName: string) {
        window.open('/Main/DownloadDeviceLogFile?customerId=' + customerId + '&deviceId=' + deviceId + '&date=' + date + '&fileName=' + fileName);
    },
    viewLogFile: function (customerId: number, deviceId: string, date: string, fileName: string, searchWord: string) {

        if (!Logs.showingDeviceLog) {
            Logs.showingDeviceLog = true;
            var $target = $('#repair-device-log-feather');
            $target.empty();

            $.ajax({
                url: '/Main/DeviceLog',
                type: "POST",
                data: JSON.stringify({
                    'customerId': DevicesRepairCentre.loadedCustomerId,
                    'searchText': searchWord,
                    'deviceId': deviceId,
                    'date': date,
                    'fileName': fileName,
                    'workMode': DevicesRepairCentre.workMode
                }),
                dataType: "html",
                contentType: "application/json; charset=utf-8",
                success: function (result) {
                    $.featherlight(result,
                        {
                            'variant': 'sunflow-light-featherlight',
                            afterOpen: function (/*e*/) {
                                MainPage.registerResizeCallback('device-log-overlay', Logs.deviceLogsPopulateAnchorLookup);
                                Logs.bindDeviceLogEvents();
                                Logs.deviceLogsPopulateAnchorLookup();
                            },
                            beforeClose: function (/*e*/) {
                                MainPage.unregisterResizeCallback('device-log-overlay');
                                Logs.unbindDeviceLogEvents();
                                Logs.showingDeviceLog = false;
                                if (Logs.shouldReloadLogViewAfterDialog) {
                                    Logs.shouldReloadLogViewAfterDialog = false;
                                    Logs.doSearch();
                                }
                                Logs.deviceLogsClear();
                            }
                        });
                    MainPage.checkIfLoggedInAfterAjax($target);
                },
                error: function () {
                    alertGenericError()
                }
            });
        }
    },
    deviceLogsPopulateAnchorLookup: function () {
        Logs.errorAnchorLookup = [];

        var $errorAnchors = $('.error-anchor');
        if ($errorAnchors.length) {
            var $scrollDiv = $('.device-log-inner-content');
            var topOffset = $scrollDiv.offset()!.top - $scrollDiv.scrollTop()!;
            $errorAnchors.each(function (i) {
                var $curElem = $(this);
                $curElem.attr('data-error-anchor-index', i);
                Logs.errorAnchorLookup[i] = $curElem.offset()!.top - topOffset;
            });
            Logs.topMostDeviceLogPart = 0;
            Logs.errorAnchorLookup[Logs.errorAnchorLookup.length] = $('.device-log-rows').offset()!.top - topOffset;
        }
        else
            Logs.topMostDeviceLogPart = -1;
        Logs.onDeviceLogScroll();
    },
    deviceLogsClear: function () {
        Logs.errorAnchorLookup = [];
        Logs.topMostDeviceLogPart = null;
    },
    bindDeviceLogEvents: function () {
        $('.device-log-error-acknowledge').on('click', function (e) {
            if (!$('.device-logs-error-rows').is(':animated')) {
                var $logErrorRows = $(this).closest('.device-logs-error-rows');
                var errorId = $logErrorRows.attr('data-error-id');

                $('#device-acknowledge-log-text').text($logErrorRows.find('.device-log-error-row').text());
                $('#device-acknowledge-log-dialog').dialog({
                    resizable: false,
                    draggable: false,
                    appendTo: '.device-log-content',
                    height: 'auto',
                    width: 500,
                    modal: true,
                    buttons: {
                        'Ok': function () {
                            $(this).dialog('destroy');
                            $.ajax({

                                url: '/Main/AcknowledgeErrorLog/',
                                type: "POST",
                                data: { 'customerId': DevicesRepairCentre.loadedCustomerId, 'errorId': errorId },
                                dataType: "text",
                                success: function (result) {
                                    if (result === 'True') {
                                        $logErrorRows.slideUp(500, function () {
                                            $(this).remove();
                                            Logs.deviceLogsPopulateAnchorLookup();
                                        });
                                        Logs.shouldReloadLogViewAfterDialog = true;
                                    }
                                    else {
                                        alertGenericError()
                                    }

                                },
                                error: function () {
                                    alertGenericError()
                                }
                            });
                        },
                        Cancel: function () {
                            $(this).dialog('destroy');
                        }
                    }
                });
            }
        });
        $('.device-log-inner-content').on('scroll', function () {
            Logs.onDeviceLogScroll();
        });
        $('#device-log-goto-next-error').on('click', function () {
            Logs.gotoErrorRow(Logs.topMostDeviceLogPart! + 1);
        });
        $('#device-log-goto-previous-error').on('click', function () {
            Logs.gotoErrorRow(Logs.topMostDeviceLogPart! - 1);
        });

    },
    onDeviceLogScroll: function () {
        if (Logs.errorAnchorLookup.length == 0) {
            Logs.setTopMostRow(0);
        }
        else {
            var scrollTop = $('.device-log-inner-content').scrollTop();
            var foundMatch = false;
            for (var i = 0; i < Logs.errorAnchorLookup.length; i++) {
                var elemTop = Logs.errorAnchorLookup[i];
                if (scrollTop! <= elemTop) {
                    foundMatch = true;
                    if (i != Logs.topMostDeviceLogPart) {
                        Logs.setTopMostRow(i);
                    }
                    break;
                }
            }
            if (!foundMatch && Logs.topMostDeviceLogPart != Logs.errorAnchorLookup.length - 1) {
                Logs.setTopMostRow(Logs.errorAnchorLookup.length - 1);
            }
            //Logs.checkErrorIndexTimer = null;
        }
    },
    gotoErrorRow: function (errorIndex: number) {
        // validate index then scroll. also update gui...
        if (errorIndex >= 0 && errorIndex < Logs.errorAnchorLookup.length) {
            // find div to scroll
            var $scrollToDiv;
            if (errorIndex + 1 == Logs.errorAnchorLookup.length) {
                $scrollToDiv = $('.device-log-rows');
            }
            else {
                $scrollToDiv = $('.device-log-inner-content').find("[data-error-anchor-index='" + errorIndex + "']");
            }
            SunFlowMain.scrollToSelectedItem($('.device-log-inner-content'), $scrollToDiv)
            // no need to update UI. the scroll event will take care of that.
        }
    },
    setTopMostRow: function (errorIndex: number) {
        Logs.topMostDeviceLogPart = errorIndex;
        var textToSet;
        if (Logs.errorAnchorLookup.length == 0 || errorIndex + 1 == Logs.errorAnchorLookup.length) {
            textToSet = 'Full Log';
        }
        else {
            textToSet = 'Error ' + (errorIndex + 1);
        }
        $('.device-log-error-name').text(textToSet);
    },
    highLightErrorLogs: function (devices: string[]) {
        Logs.searchWord = null;
        Logs.startDate = null;
        Logs.stopDate = null;
        Logs.doSearch().done(function () {
            var hasScrolled = false;
            for (var i = 0; i < devices.length; i++) {
                var $divsToHighlight = $('.repair-log-item[data-device-id="' + devices[i] + '"] > .repair-log-file-list > .repair-log-date-list > .repair-log-row[data-has-error="True"]');
                if (!hasScrolled && $divsToHighlight.length) {
                    hasScrolled = true;
                    SunFlowMain.scrollToSelectedItem($('#repair-log-logs'), $divsToHighlight); //MAAS: Changed from $divsToHighlight[0] to $divsToHighlight to fix compiler error
                }
                $divsToHighlight.delay(300).effect('highlight', 800);
            }
        });
    },
    unbindDeviceLogEvents: function () {
        $('.device-log-error-acknowledge').unbind('click');
        $('.device-log-inner-content').unbind('scroll');
        $('#device-log-goto-next-error').unbind('click');
        $('#device-log-goto-previous-error').unbind('click');
    },
    viewLogFileHelp: function (logFileName: string) {
        $.ajax({
            url: '/Main/ViewLogFileHelp/',
            type: "POST",
            data: JSON.stringify({ 'logFileName': logFileName }),
            dataType: "html",
            contentType: "application/json; charset=utf-8",
            success: function (result) {
                var $result = $(result);
                MainPage.checkIfLoggedInAfterAjax($result);
                $.featherlight($result,
                    {
                        'variant': 'sunflow-light-featherlight',
                        beforeClose: function (/*e*/) {
                            if (Logs.shouldReloadLogViewAfterDialog) {
                                Logs.shouldReloadLogViewAfterDialog = false;
                                Logs.doSearch();
                            }
                        }
                    });
            },
            error: function () {
                alertGenericError()
            }
        });
    },
    bindEvents: function () {
        $('#repair-log-search-form').on('submit', function (e) {
            e.preventDefault();
            Logs.searchWord = $('#repair-log-search-text').val() as string;
            Logs.startDate = $('#repair-log-start-date-text').val() as string;
            Logs.stopDate = $('#repair-log-stop-date-text').val() as string;
            Logs.doSearch();
        });
        $('.repair-log-row-download').on('click', function (e) {
            var $curElem = $(this)
            var $logRow = $curElem.closest('.repair-log-row');
            var hasFile = $logRow.attr('data-has-file');
            if (hasFile === 'True') {
                var date = $logRow.attr('data-date');
                var selectedDevice = $curElem.closest('.repair-log-item').attr('data-device-id');
                var fileName = $curElem.closest('.repair-log-date-list').attr('data-file-name');

                Logs.downloadLogFile(DevicesRepairCentre.loadedCustomerId, selectedDevice!, date!, fileName!);
            }
            else {
                alertError("File is not yet available.")
            }
        });
        $('.repair-log-row-view').on('click', function (e) {
            var $curElem = $(this)
            var selectedDevice = $curElem.closest('.repair-log-item').attr('data-device-id');
            var date = $curElem.closest('.repair-log-row').attr('data-date');
            var fileName = $curElem.closest('.repair-log-date-list').attr('data-file-name');
            Logs.viewLogFile(DevicesRepairCentre.loadedCustomerId, selectedDevice!, date!, fileName!, Logs.searchWord!);
        });
        $("#repair-log-start-date-text").datepicker({ dateFormat: 'yy-mm-dd' });
        $("#repair-log-stop-date-text").datepicker({ dateFormat: 'yy-mm-dd' });
        $('.repair-log-file-help').on('click', function (e) {
            Logs.viewLogFileHelp($(this).attr('data-file-name')!);
        });
    },
    unbindEvents: function () {
        $('#repair-log-search-button').unbind('submit');
        $('.repair-log-row-download').unbind('click');
        $('.repair-log-row-view').unbind('click');

        $('#repair-log-start-date-text').datepicker('hide');
        $("#repair-log-start-date-text").datepicker('destroy');
        $("#repair-log-start-date-text").removeClass("hasDatepicker").removeAttr('id');
        $('#repair-log-stop-date-text').datepicker('hide');
        $("#repair-log-stop-date-text").datepicker('destroy');
        $("#repair-log-stop-date-text").removeClass("hasDatepicker").removeAttr('id');
        $('.repair-log-file-help').unbind('click');
    },
    clear: function () {
        Logs.searchWord = null;
        Logs.startDate = null;
        Logs.stopDate = null;
        Logs.shouldReloadLogViewAfterDialog = false;
        Logs.showingDeviceLog = false;
    }
};

export default Logs;