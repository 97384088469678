import { actions as customerTreeActions } from "@features/customer-tree/customer-tree-slice"
import { createSlice, Dictionary, PayloadAction } from "@reduxjs/toolkit"
import { ContainerStatus } from "@src/lib/types/container-status"
import { CustomerChain, CustomerIndexResponse } from "@states/customer-chain/customer-chain-types"

const createRequestedChain = (id: number): CustomerChain => ({
    id,
    customers: [],
    channels: [],
    brightTimes: {},
    searchInfo: [],
    status: ContainerStatus.Requested,
    statusText: ""
})

const INITIAL_STATE: Dictionary<CustomerChain> = {}

interface CustomerLoadedPayload extends CustomerIndexResponse {
    id: number
}

const slice = createSlice({
    name: 'customer-chains',
    initialState: INITIAL_STATE,
    reducers: {
        clearChainInfo: (state, action: PayloadAction<number>) => {},
        requested: (state, action: PayloadAction<{ id: number }>) => {
            const { id } = action.payload
            if (id in state) {
                const chain = state[id]
                chain!.status = ContainerStatus.Requested
                chain!.statusText = ""
            }
            else {
                state[id] = createRequestedChain(id)
            }
        },
        loading: (state, action: PayloadAction<{ id: number }>) => {
            const { id } = action.payload
            if (id in state) {
                const chain = state[id]!
                chain.status = ContainerStatus.Loading
                chain.statusText = ""
            }
        },
        loaded: (state, action: PayloadAction<CustomerLoadedPayload>) => {
            const { id, customers, channels, brightTimes: serverBrightTimes, searchInfo } = action.payload
            if (id in state) {

                const brightTimes: Dictionary<string> = {}
                for (const bt of serverBrightTimes)
                    brightTimes[bt.id] = bt.name

                const chain = state[id]!
                chain.customers = customers.filter(c => !c.parentId).map(c => c.customerId)
                chain.channels = channels.filter(ch => ch.parentGuid === null).map(ch => ch.id)
                chain.brightTimes = brightTimes
                chain.searchInfo = searchInfo
                chain.status = ContainerStatus.Loaded
                chain.statusText = ""
            }
        },
        error: (state, action: PayloadAction<{ id: number, statusText: string }>) => {
            const { id, statusText } = action.payload
            if (id in state) {
                const chain = state[id]!
                chain.status = ContainerStatus.Error
                chain.statusText = statusText
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(customerTreeActions.setChain, (state, { payload: id }) => {
            if (!(id in state)) {
                state[id] = createRequestedChain(id)
            }
        })
    }
})

export const actions = slice.actions
export const reducer = slice.reducer
