import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { playPlaylistPreview } from "@states/projector/projector-util"
import store from "@states/store"
import Cookies from "universal-cookie/cjs"

export const SHOW_PLAYLIST_PREVIEW_TUTORIAL_COOKIE = "SHOW_PLAYLIST_PREVIEW_TUTORIAL"

const slice = createSlice({
    name: 'playlist-preview-tutorial',
    initialState: {show: false, guid: "", username: ""},
    reducers: {
        show: (state, action: PayloadAction<{username: string, guid: string}>) => {
            const {username, guid} = action.payload

            state.show = true
            state.guid = guid
            state.username = username
        },

        hide: (state, action: PayloadAction<{hidePermanently: boolean}>) => {
            const {hidePermanently} = action.payload

            setShowTutorialCookie(state.username, !hidePermanently)
            state.show = false
        }
    }
})

export const showPlaylistPreviewTutorialOrPlay = (username: string, guid: string) => {
    const showTutorial = getShowTutorialCookie(username)

    if (showTutorial) {
        store.dispatch(actions.show({username, guid}))
    } else {
        playPlaylistPreview(guid)
    }
}

const setShowTutorialCookie = (username: string, show: boolean) => {
    const expires = new Date()
    expires.setFullYear(expires.getFullYear() + 1)

    const cookies = new Cookies()
    cookies.set(getShowTutorialCookieName(username), show, {expires})
}

const getShowTutorialCookie = (username: string): boolean => {
    const cookies = new Cookies()
    const value: "false" | undefined = cookies.get(getShowTutorialCookieName(username))
    return value !== "false"
}

const getShowTutorialCookieName = (username: string) => `${username}@${SHOW_PLAYLIST_PREVIEW_TUTORIAL_COOKIE}`

export const reducer = slice.reducer
export const actions = slice.actions