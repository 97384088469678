import { DateTimeString } from "@src/lib/date-string"
import * as moment from "moment"
import { isPresent } from "ts-is-present"

export const formatFriendlyDate = (date: DateTimeString | null | undefined): string => {
    if (!isPresent(date))
        return ""

    const momentDate = moment(date)

    return momentDate.isValid()
        ? momentDate.calendar({ sameElse: () => "YYYY-MM-DD HH:mm" })
        : ""
}

export const formatDetailedDate = (date: DateTimeString | null | undefined): string => {
    if (!isPresent(date))
        return ""

    const momentDate = moment(date)

    return momentDate.isValid()
        ? momentDate.format("YYYY-MM-DD HH:mm:ss")
        : ""
}