import * as React from "react";
import { connect } from "react-redux";
import { Customer } from "@states/customer/customer-types";
import { InfoMessage } from "@states/info-message"
import { selectFormattedDate } from "@states/info-message/info-message-selectors";
import { RootState } from "@states/state";
import { AppDispatch } from "@states/store";
import InfoMessageStatusIcon from "@features/info-message-status-icon/info-message-status-icon";
import MainPage from "@features/main-page";
import FA, { FaStyle } from "../../../components/fa";
import { selectChainForCustomer } from "../../../states/customer/customer-selectors";
import { Target, TargetType } from "../../../lib/targetable";

interface OwnProps {
    infoMessage: InfoMessage,
    closeInfoMessageList: () => void
}
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<ReturnType<typeof makeMapStateToProps>>
type Props = StateProps & DispatchProps & OwnProps;

const screenIcon: Icon = { name: "tv", style: "s" }
const engineIcon: Icon = { name: "hdd", style: "s" }
const storeIcon: Icon = { name: "store-alt", style: "s" }

interface Icon {
    name: string;
    style: FaStyle;
}

const makeMapStateToProps = () => {

    const selectCustomer = (state: RootState, customerId: number) => state.customer[customerId];
    const selectTypeIcon = (target: Target) => {
        if (target.type === TargetType.RenderEngine) {
            return engineIcon;
        }
        else if (target.type === TargetType.Screen) {
            return screenIcon;
        }

        return storeIcon;
    }

    const selectNameFromCustomer = (customer: Customer) => customer ? customer.name : "loading";

    return function mapStateToProps(state: RootState, props: OwnProps) {
        return ({
            customerName: selectNameFromCustomer(selectCustomer(state, props.infoMessage.customerId)!),
            chainName: selectNameFromCustomer(selectChainForCustomer(state, props.infoMessage.customerId)!),
            targetTypeIcon: selectTypeIcon(props.infoMessage.target),
            formattedDate: selectFormattedDate(props.infoMessage.reminder!.date),
            isHighlighted: props.infoMessage.customerId === state.customerTree.selectedCustomerId
        });
    }
}

const mapDispatchToProps = (dispatch: AppDispatch, props: OwnProps) => ({
    navigateTo: () => {
        props.closeInfoMessageList();
        MainPage.navigateToInfoMessageTarget(props.infoMessage.id!)
    }
});

const InfoMessageListCard = (props: Props) => {

    return(
        <div className={props.isHighlighted ? "info-message-card info-message-card-highlighted" : "info-message-card" } onClick={props.navigateTo}>
            <InfoMessageStatusIcon target={props.infoMessage.target} />
            <div className="column-1">
                <div className="column-text">{props.customerName}</div>
                <span><FA icon={props.targetTypeIcon.name} style={props.targetTypeIcon.style} fixedWidth={true} /></span>
            </div>
            <div className="column-2">
                <div className="column-text">{props.formattedDate}</div>
                <div className="column-text"><FA icon="building" fixedWidth={true} style="r" />{props.chainName}</div>
            </div>
        </div>)
}
export default connect(makeMapStateToProps, mapDispatchToProps)(InfoMessageListCard)