const sleep = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

type DelayParams = {
    cancelCheckInterval?: number,
    cancelCheck: () => boolean
}

export const delay = async (ms: number, params?: DelayParams) => {
    if (!params)
        return await sleep(ms)

    const cancelCheckInterval = params.cancelCheckInterval ?? 1000

    return new Promise<void>(async (resolve, reject) => {

        while (ms > 0) {
            if (params.cancelCheck()) {
                reject()
                return
            }

            await sleep(cancelCheckInterval)
            ms -= cancelCheckInterval
        }

        resolve()
    })
}